import { PURPLE, GREEN, BLUE_THEME, GREEN_THEME, PURPLE_THEME } from '@/utils/constant'
import {
  logo,
  logoGreen,
  logoPurple,
  bgImageBlue,
  bgImageGreen,
  bgImagePurple,
} from '@/utils/images'
import { GREEN_ICONS, PURPLE_ICONS, BLUE_ICONS } from '@/utils/themeIcons'
import { getThemeColor } from '@/utils/localData'

const useTheme = () => {
  const themeColor = getThemeColor()

  switch (themeColor) {
    case GREEN:
      return {
        ...GREEN_ICONS,
        logo: logoGreen,
        color: GREEN_THEME['color'],
        bgImageAuth: bgImageGreen,
      }
    case PURPLE:
      return {
        ...PURPLE_ICONS,
        logo: logoPurple,
        color: PURPLE_THEME['color'],
        bgImageAuth: bgImagePurple,
      }
    default:
      return {
        ...BLUE_ICONS,
        logo: logo,
        color: BLUE_THEME['color'],
        bgImageAuth: bgImageBlue,
      }
  }
}

export default useTheme
