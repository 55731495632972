import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Sources from './Sources'
import Answer from './Answer'
import styles from './search.module.css'
import { useSelector, useDispatch } from 'react-redux'
import { chatSelector, getAnswer } from '@/redux/modules/chat'
import { searchAlt2Icon, userSquareIcon } from '@/utils/images'
import { ReactSVG } from 'react-svg'

function Question({ data, detectHeight }) {
  const dispatch = useDispatch()
  const { isFetchingSources, isFetchingAnswer, currentQuestion } = useSelector(chatSelector)

  useEffect(() => {
    if (data && data?.question_id === currentQuestion?.question_id) {
      if (!currentQuestion.finished) {
        dispatch(getAnswer({ question_id: data.question_id, idx: currentQuestion.index }))
      } else if (!currentQuestion?.answer && !data.answer) {
        dispatch(getAnswer({ question_id: data.question_id, idx: currentQuestion.index }))
      }
    }
  }, [currentQuestion])

  if (!data) return null
  return (
    <Box key={`message-${data.questionId}`} className={styles.boxQuestion}>
      <Box className={styles.boxResponsed}>
        <Box className={`${`${styles.header} search-icon border-bottom-theme-2`}`}>
          <ReactSVG src={searchAlt2Icon} />
          <p className={`${styles.content} ${'color-theme'}`}>{data.question}</p>
        </Box>
        <Box className={`${`${styles.boxCreatedBy} search-icon`}`}>
          <ReactSVG src={userSquareIcon} />
          <p className={`${styles.content}`}>
            {data?.created_last_name || ''}
            {data?.created_first_name || ''}
          </p>
        </Box>
        <Sources
          loading={isFetchingSources && !data.links}
          data={data.links}
          animation={currentQuestion?.question_id === data?.question_id}
        />
        <Answer
          loading={isFetchingSources && !data.links}
          loadingMini={isFetchingAnswer && currentQuestion?.question_id === data?.question_id}
          data={data}
          detectHeight={detectHeight}
        />
      </Box>
    </Box>
  )
}

export default Question
