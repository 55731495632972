import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Greeting from './Greeting'
import { CreateQuestion } from '@/components'
import styles from './home.module.css'
import { chatSelector, clearState } from '@/redux/modules/chat'
import { useSelector, useDispatch } from 'react-redux'
import { pushError } from '@/utils/function'

function index() {
  const dispatch = useDispatch()

  const { isError, errors } = useSelector(chatSelector)

  useEffect(() => {
    if (isError) {
      pushError(errors)
      dispatch(clearState())
    }
  }, [isError])

  return (
    <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
      <Box className={`${styles.container} w-100`}>
        <Greeting />
        <Box sx={{ margin: '0px 2.25rem' }}>
          <CreateQuestion />
        </Box>
      </Box>
    </Box>
  )
}

export default index
