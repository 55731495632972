import React, { useState, useEffect, useRef } from 'react'
import { FormLayout, InputWithLabel } from '@/components'
import Box from '@mui/material/Box'
import { useSelector, useDispatch } from 'react-redux'
import { updatePassword, userSelector, clearState } from '@/redux/modules/user'
import toast from 'react-hot-toast'
import styles from './changePassword.module.css'
import { scrollTop } from '@/utils/helper'

function index() {
  const dispatch = useDispatch()
  const { isProcessing, isSuccessfully, errors, successMessage } = useSelector(userSelector)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmed, setNewPasswordConfirmed] = useState('')
  const [error, setError] = useState(null)

  const passwordRef = useRef(null)
  const newPasswordRef = useRef(null)
  const newPasswordConfirmedRef = useRef(null)

  const elementsRef = useRef({
    passwordRef,
    newPasswordRef,
    newPasswordConfirmedRef,
  })

  const validateEmpty = () => {
    let isHaveEmpty = false
    if (!password) {
      elementsRef.current.passwordRef.current.focus()
      setError((prevstate) => ({ ...prevstate, password: 'パスワードは必須項目です。' }))
      isHaveEmpty = true
    }
    if (!newPassword) {
      elementsRef.current.newPasswordRef.current.focus()
      setError((prevstate) => ({ ...prevstate, newPassword: '新しいパスワードは必須項目です。' }))
      isHaveEmpty = true
    }
    if (!newPasswordConfirmed) {
      elementsRef.current.newPasswordConfirmedRef.current.focus()
      setError((prevstate) => ({
        ...prevstate,
        newPasswordConfirmed: 'パスワード（確認用）は必須項目です。',
      }))
      isHaveEmpty = true
    }
    return isHaveEmpty
  }

  const onUpdatePassword = () => {
    if (validateEmpty()) return
    dispatch(
      updatePassword({
        password: password,
        newPassword: newPassword,
        newPasswordConfirmed: newPasswordConfirmed,
      })
    )
  }

  const resetForm = () => {
    setPassword('')
    setNewPassword('')
    setNewPasswordConfirmed('')
  }

  useEffect(() => {
    if (isSuccessfully && !isProcessing) {
      toast.success(successMessage)
      dispatch(clearState())
      resetForm()
    }
  }, [isSuccessfully, isProcessing])

  useEffect(() => {
    if (errors) {
      if (errors.message) toast.error(errors.message)
      else setError(errors)
    }
  }, [errors])

  useEffect(() => {
    scrollTop()
    return () => {
      dispatch(clearState())
    }
  }, [])

  return (
    <FormLayout
      title='パスワード変更'
      onClick={onUpdatePassword}
      disableButton={isProcessing}
      isProcessing={isProcessing}
    >
      <Box className={styles.form}>
        <Box className={styles.row}>
          <InputWithLabel
            type='password'
            inputRef={elementsRef.current.passwordRef}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label='現在のパスワード'
            placeholder='現在のパスワードを入力'
            error={error && !!error['password']}
            errorText={(error && error['password']) || ''}
            styleContainer={{ flex: 2 }}
          />
        </Box>
        <Box className={styles.row}>
          <InputWithLabel
            type='password'
            inputRef={elementsRef.current.newPasswordRef}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            label='新しいパスワード'
            placeholder='パスワードを入力'
            error={error && !!error['newPassword']}
            errorText={(error && error['newPassword']) || ''}
            strengBar
            styleContainer={{ flex: 2 }}
          />
        </Box>
        <Box className={styles.row}>
          <InputWithLabel
            type='password'
            inputRef={elementsRef.current.newPasswordConfirmedRef}
            value={newPasswordConfirmed}
            onChange={(e) => setNewPasswordConfirmed(e.target.value)}
            label='新しいパスワード（確認用）'
            placeholder='パスワードを入力'
            error={error && !!error['newPasswordConfirmed']}
            errorText={(error && error['newPasswordConfirmed']) || ''}
            styleContainer={{ flex: 2 }}
          />
        </Box>
      </Box>
    </FormLayout>
  )
}

export default index
