import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Histories from './Histories'
import styles from './styles.module.css'
import { scrollTop } from '@/utils/helper'

function index() {
  useEffect(() => {
    scrollTop()
  }, [])

  return (
    <Box className={`${styles.container} w-100`}>
      <Histories />
    </Box>
  )
}

export default index
