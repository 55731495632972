import React, { useState, useEffect } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import styles from './radioGroup.module.css'

function index({ options = [], className = [], ...props }) {
  const [value, setValue] = useState()

  useEffect(() => {
    setValue(props.value)
  }, [props.value, props.name])

  return (
    <RadioGroup
      row
      aria-labelledby='demo-controlled-radio-buttons-group'
      name={props.name}
      value={value === undefined ? 0 : value}
      className={`${styles.radioButtons} ${className.join(' ')}`}
      onChange={props.onChange}
      {...props}
    >
      {options.map((option) => {
        return <Option key={`radio-option-${option.id}`} {...option} disabled={props.disabled} />
      })}
    </RadioGroup>
  )
}

const Option = ({ value = '', icon, checkedIcon, label = '', ...props }) => {
  return (
    <FormControlLabel
      classes={{ label: 'test-label' }}
      value={value}
      control={
        <Radio
          icon={<img src={icon} alt='Checked Radio Icon' width='15px' height='15px' />}
          checkedIcon={<img src={checkedIcon} alt='Radio Icon' width='15px' height='15px' />}
        />
      }
      label={label}
      {...props}
    />
  )
}

export default index
