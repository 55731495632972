import React, { useState, useRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import { emailIcon } from '@/utils/images'
import authApi from '@/api/auth'
import FormHelperText from '@mui/material/FormHelperText'
import { transformErrors } from '@/utils/errorHandling'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'
import { bgImageAll } from '@/utils/images'
import useTheme from '@/hooks/useTheme'
import styles from './styles.module.css'
import toast from 'react-hot-toast'
import { scrollTop } from '@/utils/helper'

function InputEmail() {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [labelSuccess, setLabelSuccess] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const emailRef = useRef(null)

  const onSubmit = async () => {
    setIsProcessing(true)
    if (!email) {
      emailRef.current.focus()
      setError({ email: 'メールアドレスは必須項目です。' })
      setIsProcessing(false)
      return
    }
    authApi
      .forgotPassword({ email: email })
      .then((res) => {
        setLabelSuccess(res.message)
        setIsProcessing(false)
      })
      .catch((e) => {
        const errs = transformErrors(e.errors)
        errs.forEach((er) => toast.error(er.message))
        setIsProcessing(false)
      })
  }

  const keyPress = (e) => {
    if (e.keyCode == 13) onSubmit()
  }

  useEffect(() => {
    scrollTop()
  }, [])

  if (labelSuccess) return <h3 className={styles.lblSuccess}>{labelSuccess}</h3>
  return (
    <Box className={`${styles.loginContainer} `} style={{ overflowX: 'hidden' }}>
      <Box className={styles.boxImageLeft}>
        <img className={styles.imageBg} src={useTheme().bgImageAuth} />
        <img className={styles.imageBox} src={bgImageAll} />
      </Box>
      <Box className={styles.formLogin} sx={{ width: '450px' }}>
        <h3 className={`${styles.title} color-theme`} style={{ fontSize: '1.75rem' }}>
          メールアドレスを入力してください
        </h3>
        <Box className={styles.inputGroup}>
          <Input
            inputRef={emailRef}
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='メールアドレス'
            startAdornment={
              <InputAdornment position='start' sx={{ marginLeft: '15px' }}>
                <img src={emailIcon} />
              </InputAdornment>
            }
            inputProps={{ className: `${styles.input}` }}
            error={error && !!error['email']}
            onKeyDown={keyPress}
            disabled={isProcessing}
          />
          {error && (
            <FormHelperText error sx={{ height: '20px' }}>
              {error.email || ''}
            </FormHelperText>
          )}
        </Box>
        <Button
          onClick={onSubmit}
          className={`${styles.btnSubmit} background-theme background-theme-hover`}
          variant='contained'
          disabled={isProcessing}
        >
          {isProcessing ? (
            <CircularProgress
              variant='indeterminate'
              disableShrink
              sx={{
                color: '#fff',
                animationDuration: '550ms',
                position: 'absolute',
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
              }}
              size={30}
              thickness={4}
            />
          ) : (
            '次に進む'
          )}
        </Button>
      </Box>
    </Box>
  )
}

export default InputEmail
