import axios from 'axios'
import queryString from 'query-string'
import {
  getUserToken,
  setUserToken,
  getRefreshToken,
  setRefreshToken,
  getFirebaseToken,
} from '@/utils/localData'
import useLogout from '@/hooks/useLogout'
import { TOKEN_EXPIRED, UNAUTHORIZED, FORBIDDEN } from '@/utils/constant'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
})

axiosClient.interceptors.request.use((config) => {
  const token = getUserToken()
  const firebaseToken = getFirebaseToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  if (firebaseToken) {
    config.headers['Firebase-Token'] = firebaseToken
  }
  return config
})

axiosClient.interceptors.response.use(
  (response) => {
    if (response?.data?.msgCode === TOKEN_EXPIRED || response?.data?.msgResp === UNAUTHORIZED) {
      useLogout()
      return
    }
    return response.data
  },
  async (error) => {
    const originalRequest = error.config
    if (error?.response?.data?.msgResp === UNAUTHORIZED) {
      useLogout()
      return
    }
    if (error?.response?.status === 403) {
      if (error?.response?.data?.errors?.message === FORBIDDEN) useLogout()
      else window.location.assign('/top')
      return
    }
    if (error?.response?.status === 401) {
      const resp = await onRefreshToken()
      const { accessToken, refreshToken } = resp
      setUserToken(accessToken)
      setRefreshToken(refreshToken)
      axiosClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
      return axiosClient(originalRequest)
    }
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      return Promise.reject(error)
    }
    return Promise.reject(error?.response?.data)
  }
)

export default axiosClient

const onRefreshToken = async () => {
  const refreshToken = getRefreshToken()
  if (!refreshToken) {
    useLogout()
    return
  }
  try {
    const resp = await axiosClient.post('/auth/refresh-token', { refreshToken: refreshToken })
    return resp
  } catch (e) {
    useLogout()
    return
  }
}
