import React, { useEffect, useState, useRef } from 'react'
import Box from '@mui/material/Box'
import { SearchBar, SharingDialog, ConfirmDialog, Loading } from '@/components'
import styles from './search.module.css'
import { ACTIONS, PERMISSION_VIEW } from '@/utils/constant'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  chatQuestion,
  chatSelector,
  clearState,
  getTopic,
  clearTopic,
  deleteTopic,
  clearCurrentQuestion,
} from '@/redux/modules/chat'
import { getMembersToShare, getSharedMembers } from '@/redux/modules/share'
import Question from './Question'
import toast from 'react-hot-toast'
import { onDownloadTopic } from '@/utils/function'
import { transformErrors } from '@/utils/errorHandling'
import { getUser } from '@/utils/localData'
import { TOPIC_NOT_EXIST, QUESTION_EMPTY } from '@/utils/message'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { scrollTop } from '@/utils/helper'

function index() {
  const currentUser = getUser()
  const navigate = useNavigate()
  const scrollRef = useRef()
  const messagesEndRef = useRef(null)
  const { topicId } = useParams()
  const location = useLocation()
  const { state, pathname } = location
  const dispatch = useDispatch()
  const { topic, isCreatingQuestion, isError, errors, alertMessage, isFetching } =
    useSelector(chatSelector)
  const [chatData, setChatData] = useState()
  const [questionContent, setQuestionContent] = useState('')
  const [topicNotFound, setTopicNotFound] = useState(false)
  const [isAddNewQuestion, setIsAddNewQuestion] = useState(false)

  const onCreateQuestion = () => {
    setIsAddNewQuestion(true)
    if (!questionContent) {
      toast.error(QUESTION_EMPTY)
      return
    }
    dispatch(chatQuestion({ question: questionContent, topic_id: topicId }))
  }

  const keyPress = (e) => {
    if (e.keyCode == 13) onCreateQuestion()
  }

  const detectHeight = () => {
    if (isAddNewQuestion) scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const onAddNewQuestion = () => {
    const newQuestionTemp = {
      question_id: null,
      question: questionContent ? questionContent : state?.questionTitle,
      created_last_name: currentUser.lastName,
      created_first_name: currentUser.firstName,
    }
    if (chatData) {
      setChatData({ ...chatData, messages: [...chatData.messages, newQuestionTemp] })
    } else {
      setChatData({ messages: [newQuestionTemp] })
    }
    setQuestionContent('')
  }

  useEffect(() => {
    if (topic) {
      setChatData(topic)
    }
  }, [topic])

  useEffect(() => {
    if (isCreatingQuestion) {
      onAddNewQuestion()
    }
  }, [isCreatingQuestion])

  useEffect(() => {
    let isApiSubscribed = true
    const controller = new AbortController()
    const signal = controller.signal

    const fetchTopic = () => {
      if (isApiSubscribed && topicId) {
        dispatch(getTopic({ topicId: topicId, signal: signal }))
        isApiSubscribed = false
      }
    }

    scrollTop()
    fetchTopic()
    return () => {
      dispatch(clearCurrentQuestion())
      controller.abort()
      isApiSubscribed = false
    }
  }, [])

  useEffect(() => {
    if (isError) {
      const errs = transformErrors(errors)
      errs.forEach((er) => {
        if (er.message === TOPIC_NOT_EXIST) {
          setTopicNotFound(true)
        }
        if (er.fieldName !== 'status') toast.error(er.message)
      })
      dispatch(clearState())
    }

    if (alertMessage) {
      toast.success(alertMessage)
      dispatch(clearState())
      if (pathname.includes('top')) {
        navigate('/top')
      } else {
        navigate('/library')
      }
    }

    setChatData({
      ...chatData,
      messages: chatData?.messages.filter((mess) => mess.question_id !== null) || [],
    })
  }, [isError, alertMessage])

  useEffect(() => {
    onAddNewQuestion()
    return () => {
      dispatch(clearTopic())
    }
  }, [])

  if (!chatData || isFetching) return <Loading />
  if (topicNotFound) return null
  return (
    <Box className={styles.searchPage}>
      <Button
        onClick={() => navigate(-1)}
        variant='outlined'
        startIcon={<ArrowBackIcon />}
        className={`${styles.backButton} border-bottom-theme color-theme`}
      >
        <span>戻る</span>
      </Button>
      {topic.topic_id && <Actions data={chatData} />}
      <Box ref={scrollRef} className={styles.answerWrapper}>
        {chatData.messages.map((question) => {
          return (
            <Question
              key={`message-${question.question_id}`}
              data={question}
              detectHeight={detectHeight}
            />
          )
        })}
        <div ref={messagesEndRef} />
        <Box className={styles.boxSearchBar}>
          <SearchBar
            value={questionContent}
            onChange={(e) => setQuestionContent(e.target.value)}
            onKeyDown={keyPress}
            placeholder='追加で何を尋ねますか？'
            containerClasses={[styles.containerSearch]}
            buttonClasses={[styles.buttonSearch]}
            inputContainerClasse={[styles.inputContainer]}
            onClickButton={onCreateQuestion}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default index

const Actions = ({ data }) => {
  const dispatch = useDispatch()
  const { isProcessing } = useSelector(chatSelector)

  const { share_permission, topic_id, title } = data
  const [showSharingDialog, setShowSharingDialog] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const onToggleSharingDialog = (e) => {
    setShowSharingDialog(!showSharingDialog)
  }

  const handleCloseSharingDialog = (e) => {
    e.stopPropagation()
    setShowSharingDialog(false)
  }

  const handleDelete = () => {
    dispatch(deleteTopic({ topicId: topic_id, key: '' }))
  }

  const onDownload = () => {
    onDownloadTopic(data)
  }

  useEffect(() => {
    if (showSharingDialog) {
      dispatch(getMembersToShare({ topicId: topic_id }))
      dispatch(getSharedMembers({ topicId: topic_id }))
    }
  }, [showSharingDialog])

  return (
    <Box className='box-actions'>
      <ul className='bg-theme-hover-opacity'>
        {share_permission != PERMISSION_VIEW && (
          <li onClick={onToggleSharingDialog} className={`action`}>
            <Box className={`box-action`}>
              <img src={ACTIONS[0].icon} />
              <p>共有</p>
            </Box>
          </li>
        )}

        <li onClick={onDownload} className={`action`}>
          <Box className={`box-action`}>
            <img src={ACTIONS[1].icon} />
            <p>出力</p>
          </Box>
        </li>
        {share_permission != PERMISSION_VIEW && (
          <li onClick={() => setOpenDialog(true)} className={`action`}>
            <Box className={`box-action`}>
              <img src={ACTIONS[2].icon} />
              <p>削除</p>
            </Box>
          </li>
        )}
      </ul>
      <SharingDialog
        show={showSharingDialog}
        handleClose={handleCloseSharingDialog}
        topicId={topic_id}
        title={title}
      />
      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        text='このトピックを削除してもよろしいでしょうか？'
        onAgree={handleDelete}
        title='確認'
        isProcessing={isProcessing}
      />
    </Box>
  )
}
