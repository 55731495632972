import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import userApi from '@/api/user'

export const getUsers = createAsyncThunk('members/get', async (_, thunkAPI) => {
  try {
    let users = await userApi.getMembers()
    if (!users.errors) {
      return users
    }
  } catch (e) {
    if (e?.errors?.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors.errors })
    }
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const changeActiveMember = createAsyncThunk(
  'members/change-active',
  async (data, thunkAPI) => {
    try {
      let member = await userApi.changeActive(data)
      if (!member.errors) {
        return { member: data, message: member.message }
      }
    } catch (e) {
      if (e?.errors?.errors) {
        return thunkAPI.rejectWithValue({ errors: e.errors.errors })
      }
      return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
    }
  }
)

export const inviteMember = createAsyncThunk('member/invite', async (data, thunkAPI) => {
  try {
    let invited = await userApi.inviteUser(data)
    if (!invited.errors) {
      return invited
    }
  } catch (e) {
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const deleteMember = createAsyncThunk('member/delete', async (data, thunkAPI) => {
  try {
    let user = await userApi.deleteUser(data)
    if (!user.errors) {
      return { ...user, ...data }
    }
  } catch (e) {
    if (e?.errors?.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors.errors })
    }
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const membersSlice = createSlice({
  name: 'members',
  initialState: {
    data: [],
    isSuccessfully: false,
    successMessage: '',
    isError: false,
    errors: {},
    isFetching: false,
    saveSuccess: false,
    isProcessing: false,
    member: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false
      state.isSuccessfully = false
      state.errors = {}
      state.successMessage = ''
      state.isFetching = false
      state.saveSuccess = false
      state.isProcessing = false
      state.member = null
      return state
    },
  },
  extraReducers: {
    [getUsers.fulfilled]: (state, { payload }) => {
      state.data = payload.users
      state.isSuccessfully = true
      return state
    },
    [getUsers.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
    },
    [getUsers.pending]: (state) => {
      state.isFetching = true
    },
    [changeActiveMember.fulfilled]: (state, { payload }) => {
      const users = [...state.data]
      const index = users.findIndex((u) => u.userId == payload.member.userId)
      users[index] = { ...users[index], isActivate: users[index].isActivate == 0 ? 1 : 0 }
      state.member = users[index]
      state.data = [...users]
      state.saveSuccess = true
      state.successMessage = payload.message
      return state
    },
    [changeActiveMember.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
    },
    [changeActiveMember.pending]: (state) => {
      state.isProcessing = true
    },
    [inviteMember.fulfilled]: (state, { payload }) => {
      state.data = [payload.userInfo, ...state.data]
      state.isProcessing = false
      state.isSuccessfully = true
      state.successMessage = payload.message
      return state
    },
    [inviteMember.rejected]: (state, { payload }) => {
      state.isProcessing = false
      state.isError = true
      state.isSuccessfully = false
      state.errors = payload.errors
    },
    [inviteMember.pending]: (state) => {
      state.isProcessing = true
    },
    [deleteMember.fulfilled]: (state, { payload }) => {
      state.data = state.data.filter((u) => u.userId !== payload.userId)
      state.isSuccessfully = true
      state.successMessage = payload.message
      return state
    },
    [deleteMember.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
    },
    [deleteMember.pending]: (state) => {
      return state
    },
  },
})

export const { clearState } = membersSlice.actions

export const membersSelector = (state) => state.members
