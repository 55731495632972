import React from 'react'
import styles from './styles.module.css'

function Label({ label = '', required = false }) {
  return (
    <label className={`${styles.label}`}>
      {label}
      {required && <span style={{ color: '#d32f2f' }}>*</span>}
    </label>
  )
}

export default Label
