import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useSelector, useDispatch } from 'react-redux'
import { getUsers, membersSelector, clearState } from '@/redux/modules/members'
import styles from './members.module.css'
import Skeleton from '@mui/material/Skeleton'

import MemberRow from './MemberRow'

const columns = [
  { id: 'name', label: '', maxWidth: 130 },
  { id: 'role', label: '権限', maxWidth: 120 },
  {
    id: 'lastLogin',
    label: '最終ログイン',
    maxWidth: 150,
  },
  {
    id: 'email',
    label: 'メールアドレス',
    maxWidth: 150,
  },
  {
    id: 'status',
    label: 'ステータス',
    maxWidth: 150,
  },
]

export default function MemberTable() {
  const dispatch = useDispatch()

  const { data, isFetching, isSuccessfully } = useSelector(membersSelector)

  const [members, setMembers] = useState([])

  useEffect(() => {
    if (isSuccessfully) setMembers(data)
  }, [isSuccessfully])

  useEffect(() => {
    let isApiSubscribed = true
    const fetchUsers = () => {
      if (isApiSubscribed) {
        isApiSubscribed = false
        dispatch(getUsers())
      }
    }

    fetchUsers()
    return () => {
      isApiSubscribed = false
      dispatch(clearState())
    }
  }, [])

  return (
    <>
      {!isFetching ? (
        <TableContainer className={styles.tableContainer} sx={{ maxHeight: 400 }}>
          <Table className={styles.memberTable} stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={`header-${column.id}`}
                    align='center'
                    style={{
                      maxWidth: column.maxWidth,
                      ...tableHeaderStyled,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell style={{ ...tableHeaderStyled, maxWidth: 220 }}>
                  アカウント削除
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((member) => {
                return <MemberRow key={`row-${member.userId}`} data={member} />
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box className={styles.tableContainer} sx={{ maxHeight: 400 }}>
          <Box className={styles.skeleton}>
            {Array(5)
              .fill(null)
              .map((_, i) => {
                return <Skeleton key={`ske-${i}`} variant='rounded' height={65} />
              })}
          </Box>
        </Box>
      )}
    </>
  )
}

const tableHeaderStyled = {
  fontWeight: 600,
  borderBottom: 0,
  backgroundColor: '#fafafa',
  textAlign: 'center',
}
