import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import benchmarkApi from '@/api/benchmark'
import { catchError } from '@/utils/errorHandling'
import { PERMISSION_OWNER, PERMISSION_DELETE } from '@/utils/constant'

export const createBenchmark = createAsyncThunk('benchmark/create', async (data, thunkAPI) => {
  try {
    const res = await benchmarkApi.createBenchmark(data)
    if (!res?.errors) {
      return { ...res, ...data, links: [], share_permission: PERMISSION_OWNER }
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const editBenchmark = createAsyncThunk('benchmark/edit', async (data, thunkAPI) => {
  try {
    const res = await benchmarkApi.createBenchmark(data)
    if (!res?.errors) {
      return { ...res, ...data, links: [] }
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const getAllBenchmark = createAsyncThunk('benchmark/gets', async (data, thunkAPI) => {
  try {
    let res = await benchmarkApi.getAllBenchmark(data)
    if (!res?.errors) {
      return res
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const getBenchmark = createAsyncThunk('benchmark/get', async (data, thunkAPI) => {
  try {
    let res = await benchmarkApi.getBenchmark(data)
    if (!res.errors) {
      return res
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const getBenchmarkWithLinks = createAsyncThunk(
  'benchmark/get-link',
  async (data, thunkAPI) => {
    try {
      let res = await benchmarkApi.getBenchmarkWithLinks(data)
      if (!res.errors) {
        return res
      }
    } catch (e) {
      return catchError(e, thunkAPI, data.signal)
    }
  }
)

export const getBenchmarkWithNextLink = createAsyncThunk(
  'benchmark/get-next-link',
  async (data, thunkAPI) => {
    try {
      const res = await benchmarkApi.getBenchmarkNextLink(data)
      if (!res.errors) {
        return res
      }
    } catch (e) {
      if (e?.errors?.errors) {
        return catchError(e, thunkAPI, data.signal)
      }
      return catchError(e, thunkAPI, data.signal)
    }
  }
)

export const deleteBenchmark = createAsyncThunk('benchmark/delete', async (data, thunkAPI) => {
  try {
    let res = await benchmarkApi.deleteBenchmark({ benchmarkId: data.benchmarkId })
    if (!res.errors) {
      return { ...res, benchmarkId: data.benchmarkId, key: data.key }
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const getMembersToShare = createAsyncThunk(
  'benchmark/gets-share',
  async (data, thunkAPI) => {
    try {
      const res = await benchmarkApi.getMembersToShare(data)
      if (!res.errors) {
        return res
      }
    } catch (e) {
      if (e?.errors?.errors) {
        return thunkAPI.rejectWithValue({ errors: e.errors.errors })
      }
      return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
    }
  }
)

export const getSharedMembers = createAsyncThunk(
  'benchmark/gets-shared',
  async (data, thunkAPI) => {
    try {
      const res = await benchmarkApi.getMembersShared(data)
      if (!res.errors) {
        return { topicId: data.topicId, ...res }
      }
    } catch (e) {
      if (e?.errors?.errors) {
        return thunkAPI.rejectWithValue({ errors: e.errors.errors })
      }
      return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
    }
  }
)

export const shareBenchmark = createAsyncThunk('benchmark/share', async (data, thunkAPI) => {
  try {
    const payload = {
      benchmark_id: data.benchmark_id,
      shared_user_id: data.member.user_id,
      share_permission: data.share_permission,
    }
    const res = await benchmarkApi.shareBenchmark(payload)
    if (!res.errors) {
      return { member: { ...data.member, share_permission: data.share_permission }, ...res }
    }
  } catch (e) {
    if (e?.errors?.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors.errors })
    }
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const getSharedBenchmarks = createAsyncThunk(
  'benchmark/get-list-shared',
  async (data, thunkAPI) => {
    try {
      const res = await benchmarkApi.getSharedBenchmarks(data)
      if (!res.errors) {
        return res
      }
    } catch (e) {
      if (e?.errors?.errors) {
        return thunkAPI.rejectWithValue({ errors: e.errors.errors })
      }
      return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
    }
  }
)

export const setPages = createAsyncThunk('benchmark/change-page', async (data) => {
  return data
})

export const refreshBenchmark = createAsyncThunk('benchmark/refresh', async (data, thunkAPI) => {
  try {
    const res = await benchmarkApi.refreshBenchmark(data)
    if (!res?.errors) {
      return { ...res, ...data, links: [] }
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const resumeBenchmark = createAsyncThunk('benchmark/resume', async (data, thunkAPI) => {
  try {
    const res = await benchmarkApi.resumeBenchmark(data)
    if (!res?.errors) {
      return { ...res }
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const stopBenchmark = createAsyncThunk('benchmark/stop', async (data, thunkAPI) => {
  try {
    const res = await benchmarkApi.stopBenchmark(data)
    if (!res?.errors) {
      return { ...res }
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const benchmarkSlice = createSlice({
  name: 'benchmark',
  initialState: {
    list: {}, // {owner: [], shared: []}
    benchmark: {
      finished: true,
      links: [],
    },
    benchmarkToCopy: null,
    membersToShare: [],
    sharedMembers: [],
    isSuccessfully: false,
    successMessage: '',
    isError: false,
    errors: {},
    isFetching: false,
    isFetchingMembers: false,
    isFetchingSharedMembers: false,
    saveSuccess: false,
    isProcessing: false,
    isDeleted: false,
    gettingCopy: false,
    isFetchingWithLink: false,
    pages: [],
    errorStop: false,
    isStop: false,
    isSharing: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false
      state.isSuccessfully = false
      state.errors = {}
      state.successMessage = ''
      state.isFetching = false
      state.saveSuccess = false
      state.isProcessing = false
      state.isFetchingMembers = false
      state.isFetchingSharedMembers = false
      state.isDeleted = false
      state.gettingCopy = false
      state.errorStop = false
      state.isStop = false
      return state
    },
    clearBenchmark: (state) => {
      state.benchmark = { finished: true, links: [] }
    },
    clearBenchmarkCopy: (state) => {
      state.benchmarkToCopy = null
    },
  },
  extraReducers: {
    [getAllBenchmark.fulfilled]: (state, { payload }) => {
      state.list = payload
      state.isFetching = false
      return state
    },
    [getAllBenchmark.rejected]: (state, { payload }) => {
      if (payload) {
        state.isError = true
        state.errors = payload.errors
      }
      state.isFetching = false
    },
    [getAllBenchmark.pending]: (state) => {
      state.isFetching = true
    },
    [getBenchmark.fulfilled]: (state, { payload }) => {
      state.benchmarkToCopy = { ...payload }
      state.gettingCopy = false
      state.isSuccessfully = true
      return state
    },
    [getBenchmark.rejected]: (state, { payload }) => {
      if (payload) {
        state.isError = true
        state.errors = payload.errors
      }
      state.gettingCopy = false
    },
    [getBenchmark.pending]: (state) => {
      state.gettingCopy = true
    },
    [createBenchmark.fulfilled]: (state, { payload }) => {
      state.benchmark = { ...payload }
      state.isProcessing = false
      state.isSuccessfully = true
      return state
    },
    [createBenchmark.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isProcessing = false
    },
    [createBenchmark.pending]: (state) => {
      state.isProcessing = true
    },
    [editBenchmark.fulfilled]: (state, { payload }) => {
      state.benchmark = { ...payload }
      state.isProcessing = false
      state.isSuccessfully = true
      return state
    },
    [editBenchmark.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isProcessing = false
    },
    [editBenchmark.pending]: (state) => {
      state.isProcessing = true
    },
    [getBenchmarkWithNextLink.fulfilled]: (state, { payload }) => {
      const { links } = state.benchmark
      const { isStop } = state
      const { next_benchmark_link, finished } = payload
      if (!isStop) {
        state.benchmark = {
          ...state.benchmark,
          finished: finished,
          links: next_benchmark_link ? [...links, next_benchmark_link] : [...links],
        }
      }
      state.isFetching = false
      return state
    },
    [getBenchmarkWithNextLink.rejected]: (state, { payload }) => {
      if (payload) {
        state.isError = true
        state.errors = payload.errors
      }
      state.isFetching = false
    },
    [getBenchmarkWithNextLink.pending]: (state) => {
      state.isFetching = true
    },
    [getMembersToShare.fulfilled]: (state, { payload }) => {
      const { user_to_share } = payload
      state.membersToShare = [...user_to_share]
      state.isFetchingMembers = false
      return state
    },
    [getMembersToShare.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isFetchingMembers = false
    },
    [getMembersToShare.pending]: (state) => {
      state.isFetchingMembers = true
    },
    [getSharedMembers.fulfilled]: (state, { payload }) => {
      const { user_shared } = payload
      state.sharedMembers = [...user_shared]
      state.isFetchingSharedMembers = false
      return state
    },
    [getSharedMembers.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isFetchingSharedMembers = false
    },
    [getSharedMembers.pending]: (state) => {
      state.isFetchingSharedMembers = true
    },
    [shareBenchmark.fulfilled]: (state, { payload }) => {
      const { message, member } = payload
      let { sharedMembers } = state

      const index = sharedMembers.findIndex((mem) => mem.user_id === member.user_id)

      if (index > -1) {
        if (member.share_permission === PERMISSION_DELETE) sharedMembers.splice(index, 1)
        else sharedMembers[index] = { ...member }
      } else sharedMembers = [member, ...sharedMembers]

      state.sharedMembers = sharedMembers
      state.isSuccessfully = true
      state.isSharing = false
      state.successMessage = message
      return state
    },
    [shareBenchmark.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isSharing = false
    },
    [shareBenchmark.pending]: (state) => {
      state.isSharing = true
    },
    [getBenchmarkWithLinks.fulfilled]: (state, { payload }) => {
      state.benchmark = {
        ...state.benchmark,
        ...payload,
      }
      state.isFetchingWithLink = false
      return state
    },
    [getBenchmarkWithLinks.rejected]: (state, { payload }) => {
      if (payload) {
        state.isError = true
        state.errors = payload.errors
      }

      state.isFetchingWithLink = false
    },
    [getBenchmarkWithLinks.pending]: (state) => {
      state.isFetchingWithLink = true
    },
    [deleteBenchmark.fulfilled]: (state, { payload }) => {
      if (payload.key) {
        const { key, benchmarkId } = payload
        let list = state.list[key].filter((benchmark) => benchmark.benchmark_id !== benchmarkId)
        state.list[key] = list
      }
      state.isProcessing = false
      state.isSuccessfully = true
      state.successMessage = payload.messages
      state.isDeleted = true
      return state
    },
    [deleteBenchmark.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
    },
    [deleteBenchmark.pending]: (state) => {
      state.isProcessing = true
    },
    [getSharedBenchmarks.fulfilled]: (state, { payload }) => {
      state.list = payload
      state.isFetching = false
      return state
    },
    [getSharedBenchmarks.rejected]: (state, { payload }) => {
      if (payload) {
        state.isError = true
        state.errors = payload.errors
      }
      state.isFetching = false
    },
    [getSharedBenchmarks.pending]: (state) => {
      state.isFetching = true
    },
    [setPages.fulfilled]: (state, { payload }) => {
      const index = state.pages.findIndex((page) => page.title === payload.title)
      if (index > -1) {
        state.pages[index] = payload
      } else {
        state.pages.push(payload)
      }
      return state
    },
    [stopBenchmark.fulfilled]: (state, { payload }) => {
      state.isStop = true
      return state
    },
    [stopBenchmark.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.errorStop = true
      state.isStop = false
    },
    [stopBenchmark.pending]: (state) => {
      state.isStop = true
    },
    [refreshBenchmark.fulfilled]: (state, {}) => {
      state.benchmark = {
        ...state.benchmark,
        finished: false,
        links: [],
      }
      state.isProcessing = false
      state.isSuccessfully = true
      return state
    },
    [refreshBenchmark.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
      state.isProcessing = false
    },
    [refreshBenchmark.pending]: (state) => {
      state.isProcessing = true
    },
    [resumeBenchmark.fulfilled]: (state, {}) => {
      state.isStop = false
      return state
    },
    [resumeBenchmark.rejected]: (state, { payload }) => {
      state.isStop = true
    },
    [resumeBenchmark.pending]: (state) => {
      state.isStop = false
    },
  },
})

export const { clearState, clearBenchmark, clearBenchmarkCopy } = benchmarkSlice.actions

export const benchmarkSelector = (state) => state.benchmark
