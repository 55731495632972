import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { terminalIcon } from '@/utils/images'
import styles from './search.module.css'
import { useSelector } from 'react-redux'
import { chatSelector } from '@/redux/modules/chat'
import Typed from 'typed.js'
import useTheme from '@/hooks/useTheme'
import useDynamicRefs from 'use-dynamic-refs'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'

function Answer({ loading = false, data, detectHeight, animation, loadingMini = false }) {
  const contentRef = useRef()
  const [answer, setAnswer] = useState('')
  const [answers, setAnswers] = useState([''])
  const { currentQuestion } = useSelector(chatSelector)
  const [getRef, setRef] = useDynamicRefs()
  const [runIndex, setRunIndex] = useState(0)
  const oldRef = useRef(null)

  const onCompletedTyping = () => {
    setRunIndex(oldRef.current + 1)
  }

  useEffect(() => {
    oldRef.current = -1
    if (!contentRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      detectHeight()
    })

    resizeObserver.observe(contentRef.current)
    return () => {
      resizeObserver.disconnect() // clean up
    }
  }, [])

  useEffect(() => {
    if (data && data?.question_id === currentQuestion?.question_id) {
      if (currentQuestion?.answer) {
        setAnswers((prevState) => [...prevState, currentQuestion.answer])
      }
    } else if (data) {
      const ans = data.answer || ''
      setAnswer(ans)
    }
  }, [currentQuestion])

  useEffect(() => {
    if (oldRef.current !== runIndex) {
      for (let i = 0; i < answers.length; i++) {
        const el = answers[i]
        const elRef = `ani-${i}`
        if (getRef(elRef) && getRef(elRef)?.current && runIndex <= i) {
          oldRef.current = runIndex
          new Typed(getRef(elRef).current, {
            strings: [el],
            typeSpeed: 20,
            cursorChar: '',
            onComplete: onCompletedTyping,
          })
          break
        }
      }
    }
  }, [answers, runIndex])

  return (
    <Box className={styles.boxAnwser}>
      <Title loading={(loadingMini && runIndex === answers.length) || loading} />
      <Box ref={contentRef} className={`${styles.boxContent} ${styles.answerLink}`}>
        {currentQuestion?.question_id === data?.question_id ? (
          answers.map((ans, index) => {
            return <span key={`span${index}`} ref={setRef(`ani-${index}`)} />
          })
        ) : (
          <div dangerouslySetInnerHTML={{ __html: `${answer}` }} />
        )}
      </Box>
    </Box>
  )
}

export default Answer

const Title = React.memo(({ loading }) => {
  return (
    <Box className={styles.boxTitle}>
      {loading ? (
        <CircularProgress
          disableShrink
          size={24}
          thickness={4}
          sx={{
            color: useTheme().color,
            animationDuration: '550ms',
            margin: '0px 15px',
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
        />
      ) : (
        <img src={terminalIcon} />
      )}
      要約
    </Box>
  )
})
