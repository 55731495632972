import React, { useState, useEffect, useRef, useCallback } from 'react'
import { FormLayout, RadioGroupButton, InputWithLabel } from '@/components'
import Box from '@mui/material/Box'
import useTheme from '@/hooks/useTheme'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { notifications, themes } from '@/utils/generateOptions'
import { useSelector, useDispatch } from 'react-redux'
import { verifyEmail, updateUser, userSelector, clearState } from '@/redux/modules/user'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { REGISTERED, RECEIVE } from '@/utils/constant'
import styles from './styles.module.css'
import { scrollTop } from '@/utils/helper'

function index() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const user = useSelector(userSelector)
  const { isProcessing, isSuccessfully, isError, errors, isFetching, status } = user

  const optionsNotification = notifications(useTheme().radioChecked)
  const optionsTheme = themes()

  const [userData, setUserData] = useState({ receiveNotification: RECEIVE, theme: 0 })
  const [error, setError] = useState(null)
  const [alert, setAlert] = useState('')

  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const firstNameKatakanaRef = useRef(null)
  const lastNameKatakanaRef = useRef(null)
  const phoneNumberRef = useRef(null)
  const passwordRef = useRef(null)
  const passwordConfirmedRef = useRef(null)

  const elementsRef = useRef({
    firstNameRef,
    lastNameRef,
    firstNameKatakanaRef,
    lastNameKatakanaRef,
    phoneNumberRef,
    passwordRef,
    passwordConfirmedRef,
  })

  const validateEmpty = () => {
    const {
      firstName,
      lastName,
      firstNameKatakana,
      lastNameKatakana,
      password,
      passwordConfirmed,
    } = userData
    let isHaveEmpty = false
    if (!firstName) {
      elementsRef.current.firstNameRef.current.focus()
      setError((prevstate) => ({ ...prevstate, firstName: '名は必須項目です。' }))
      isHaveEmpty = true
    }
    if (!lastName) {
      elementsRef.current.lastNameRef.current.focus()
      setError((prevstate) => ({ ...prevstate, lastName: '姓は必須項目です。' }))
      isHaveEmpty = true
    }
    if (!firstNameKatakana) {
      elementsRef.current.firstNameKatakanaRef.current.focus()
      setError((prevstate) => ({
        ...prevstate,
        firstNameKatakana: '名（フリガナ）は必須項目です。',
      }))
      isHaveEmpty = true
    }
    if (!lastNameKatakana) {
      elementsRef.current.lastNameKatakanaRef.current.focus()
      setError((prevstate) => ({
        ...prevstate,
        lastNameKatakana: '姓（フリガナ）は必須項目です。',
      }))
      isHaveEmpty = true
    }
    if (!password) {
      elementsRef.current.passwordRef.current.focus()
      setError((prevstate) => ({
        ...prevstate,
        password: 'パスワードは必須項目です。',
      }))
      isHaveEmpty = true
    }
    if (!passwordConfirmed) {
      elementsRef.current.passwordConfirmedRef.current.focus()
      setError((prevstate) => ({
        ...prevstate,
        passwordConfirmed: 'パスワード（確認用）は必須項目です。',
      }))
      isHaveEmpty = true
    }

    return isHaveEmpty
  }

  const onUpdateUser = () => {
    setError(null)
    if (validateEmpty()) return
    const {
      firstName,
      lastName,
      firstNameKatakana,
      lastNameKatakana,
      phoneNumber,
      password,
      passwordConfirmed,
      receiveNotification,
      theme,
    } = userData
    let newData = {
      firstName,
      lastName,
      firstNameKatakana,
      lastNameKatakana,
      phoneNumber: phoneNumber || null,
      password: password,
      passwordConfirmed: passwordConfirmed,
      receiveNotification: receiveNotification == 0 ? 0 : Number(receiveNotification),
      theme: theme ? Number(theme) : 0,
    }
    dispatch(updateUser(newData))
  }

  const getEmailByToken = useCallback(async () => {
    const token = searchParams.get('token')
    if (token) {
      dispatch(verifyEmail({ token: token }))
    } else {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    getEmailByToken().catch((e) => {
      setAlert(e.errors.message)
    })
  }, [getEmailByToken])

  useEffect(() => {
    if (isSuccessfully) {
      setUserData(user)
      dispatch(clearState())
      if (status && Number(status) === REGISTERED) {
        navigate('/top')
      }
    }
    if (isError) {
      if (errors.message) {
        setAlert(errors.message)
      } else {
        setError(errors)
      }
      dispatch(clearState())
    }
  }, [isSuccessfully, isError])

  useEffect(() => {
    scrollTop()
  }, [])

  if (isFetching) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFetching}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    )
  }
  if (alert)
    return (
      <Box sx={{ padding: '150px 0px', textAlign: 'center', color: '#535353' }}>
        <h3>{alert}</h3>
      </Box>
    )
  return (
    <FormLayout
      title='アカウント設定'
      onClick={onUpdateUser}
      disableButton={isProcessing}
      isProcessing={isProcessing}
      height='auto'
      paddingTop='100px'
    >
      <Box className={styles.form}>
        <Box className={`${styles.row} ${styles.inline}`}>
          <InputWithLabel
            inputRef={elementsRef.current.lastNameRef}
            label='姓'
            placeholder='山田'
            value={userData?.lastName || ''}
            onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
            error={error && !!error['lastName']}
            errorText={(error && error['lastName']) || ''}
            styleContainer={{ width: '296px', flex: 'none' }}
            required
          />
          <InputWithLabel
            inputRef={elementsRef.current.firstNameRef}
            label='名'
            placeholder='太郎'
            value={userData?.firstName || ''}
            onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
            error={error && !!error['firstName']}
            errorText={(error && error['firstName']) || ''}
            styleContainer={{ width: '296px', flex: 'none' }}
            required
          />
        </Box>
        <Box className={`${styles.row} ${styles.inline}`}>
          <InputWithLabel
            inputRef={elementsRef.current.lastNameKatakanaRef}
            label='姓（フリガナ）'
            placeholder='ヤマダ'
            value={userData?.lastNameKatakana || ''}
            onChange={(e) => setUserData({ ...userData, lastNameKatakana: e.target.value })}
            error={error && !!error['lastNameKatakana']}
            errorText={(error && error['lastNameKatakana']) || ''}
            styleContainer={{ width: '13.1875rem', flex: 'none' }}
            required
          />
          <InputWithLabel
            inputRef={elementsRef.current.firstNameKatakanaRef}
            label='名（フリガナ）'
            placeholder='タロウ'
            value={userData?.firstNameKatakana || ''}
            onChange={(e) => setUserData({ ...userData, firstNameKatakana: e.target.value })}
            error={error && !!error['firstNameKatakana']}
            errorText={(error && error['firstNameKatakana']) || ''}
            styleContainer={{ width: '13.1875rem', flex: 'none' }}
            required
          />
        </Box>
        <Box className={styles.row}>
          <InputWithLabel
            type='email'
            label='メールアドレス'
            placeholder='メールアドレスを入力'
            value={userData?.email || ''}
            disabled
            styleContainer={{ width: '32.6875rem', flex: 'none' }}
            required
          />
        </Box>
        <Box className={styles.row}>
          <InputWithLabel
            inputRef={elementsRef.current.phoneNumberRef}
            label='電話番号'
            placeholder='電話番号を入力'
            value={userData?.phoneNumber || ''}
            onChange={(e) => setUserData({ ...userData, phoneNumber: e.target.value })}
            error={error && !!error['phoneNumber']}
            errorText={error && error['phoneNumber']}
            styleContainer={{ width: '32.6875rem', flex: 'none' }}
          />
        </Box>
        <Box className={styles.row}>
          <InputWithLabel
            type='password'
            inputRef={elementsRef.current.passwordRef}
            label='パスワード'
            placeholder='パスワードを入力'
            value={userData?.password || ''}
            onChange={(e) => setUserData({ ...userData, password: e.target.value })}
            error={error && !!error['password']}
            errorText={(error && error['password']) || ''}
            styleContainer={{ width: '32.6875rem', flex: 'none' }}
            strengBar
            required
          />
        </Box>
        <Box className={styles.row}>
          <InputWithLabel
            type='password'
            inputRef={elementsRef.current.passwordConfirmedRef}
            label='パスワード（確認用）'
            placeholder='パスワードを入力'
            value={userData?.passwordConfirmed || ''}
            onChange={(e) => setUserData({ ...userData, passwordConfirmed: e.target.value })}
            error={error && !!error['passwordConfirmed']}
            errorText={(error && error['passwordConfirmed']) || ''}
            styleContainer={{ width: '32.6875rem', flex: 'none' }}
            required
          />
        </Box>
        <Box className={`${styles.row} ${styles.notification}`}>
          <label className={`${styles.label}`}>共有があった際通知を受け取る：</label>
          <RadioGroupButton
            value={userData?.receiveNotification}
            options={optionsNotification}
            className={[styles.radios]}
            name='notifications'
            onChange={(e) => setUserData({ ...userData, receiveNotification: e.target.value })}
          />
        </Box>
        <Box className={`${styles.row} ${styles.theme}`}>
          <label className={`${styles.label}`}>テーマを設定：</label>
          <RadioGroupButton
            value={userData?.theme}
            options={optionsTheme}
            className={[styles.radios]}
            onChange={(e) => setUserData({ ...userData, theme: e.target.value })}
            name='theme'
          />
        </Box>
      </Box>
    </FormLayout>
  )
}

export default index
