import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import moment from 'moment'
import { userSquareIcon } from '@/utils/images'
import { ReactSVG } from 'react-svg'
import {
  FORMAT_DATE_CAPITAL,
  PERMISSION_VIEW,
  DEFAULT_ITEMS_PER_PAGE_SHARED,
} from '@/utils/constant'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory'
import { trashIcon } from '@/utils/images'
import { ConfirmDialog, ReadMore } from '@/components'
import styles from './styles.module.css'
import { shareSelector, setPages } from '@/redux/modules/share'
import { useDispatch, useSelector } from 'react-redux'

export default function SharedDataTable({
  data = [],
  showAction = false,
  closeDialog = false,
  isProcessing = false,
  onDelete = () => {},
  title = '',
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pages } = useSelector(shareSelector)

  const [openDialog, setOpenDialog] = useState(false)
  const [dataSelected, setDataSelected] = useState()

  const [first1, setFirst1] = useState(0)
  const [rows1, setRows1] = useState(DEFAULT_ITEMS_PER_PAGE_SHARED)

  const handleDelete = () => {
    onDelete(dataSelected.benchmark_id, 'shared')
  }

  const openDialogConfirm = (val) => {
    setOpenDialog(true)
    setDataSelected(val)
  }

  const handlePageChange = (event) => {
    const { page, rows } = event
    dispatch(setPages({ title: title, currentPage: page + 1, rows: rows }))
  }

  useEffect(() => {
    setOpenDialog(closeDialog)
  }, [closeDialog])

  useEffect(() => {
    if (pages.length) {
      pages.map((page) => {
        if (page.title === title) {
          const first = page.currentPage ? page.rows * (page.currentPage - 1) : 0
          setFirst1(first)
          setRows1(page.rows)
        }
      })
    }
  }, [pages])

  const headerTemplate = (value) => {
    const currenDate = moment().startOf('day')
    const daysDiff = currenDate.diff(moment(value.shared_time, [FORMAT_DATE_CAPITAL]), 'days')
    const groupTitle = daysDiff === 0 ? '今日' : daysDiff === 1 ? '昨日' : value.shared_time

    return (
      <div className='flex align-items-center gap-2'>
        <span className='font-bold'>{groupTitle}</span>
      </div>
    )
  }

  return (
    <>
      <DataTable
        value={data}
        rowGroupMode='subheader'
        groupRowsBy='shared_time'
        sortMode='single'
        sortField='shared_time'
        sortOrder={-1}
        scrollable
        rowGroupHeaderTemplate={headerTemplate}
        paginator={data.length > DEFAULT_ITEMS_PER_PAGE_SHARED}
        selectionMode='single'
        rowClassName={`${styles.topicRow}  row-table background-theme-hover`}
        onSelectionChange={(e) => !showAction && navigate(`/shared/lib/${e.value.topic_id}`)}
        rowsPerPageOptions={[5, 10, 20, 30]}
        onPage={handlePageChange}
        first={first1}
        rows={rows1}
      >
        <Column
          field='title'
          style={{ flex: 2 }}
          body={({ title }) => {
            return <ReadMore maxChar={120}>{title}</ReadMore>
          }}
        ></Column>
        <Column
          field='actions'
          body={(val) => {
            if (!showAction)
              return (
                <Box className={styles.boxSender}>
                  <ReactSVG src={userSquareIcon} />
                  <p
                    className='truncate'
                    style={{ width: 80 }}
                  >{`${val.sharing_last_name}${val.sharing_first_name}`}</p>
                </Box>
              )
            return (
              <Box className={'actions'}>
                <ul className='bg-theme-hover-opacity justify-content-end'>
                  <li
                    onClick={() => navigate(`/shared/ben/${val.benchmark_id}`)}
                    className={styles.action}
                  >
                    <Box className={`${styles.boxAction} ${styles.view}`}>
                      <ChangeHistoryIcon />
                      <p>詳細</p>
                    </Box>
                  </li>
                  <li
                    onClick={() => navigate(`ben?copy=${val.benchmark_id}`)}
                    className={styles.action}
                  >
                    <Box className={`${styles.boxAction} ${styles.copy}`}>
                      <ContentCopyIcon />
                      <p>コピー</p>
                    </Box>
                  </li>
                  {val.share_permission != PERMISSION_VIEW ? (
                    <li onClick={() => openDialogConfirm(val)} className={styles.action}>
                      <Box className={`${styles.boxAction} ${styles.delete}`}>
                        <ReactSVG src={trashIcon} />
                        <p>削除</p>
                      </Box>
                    </li>
                  ) : null}
                </ul>
              </Box>
            )
          }}
        ></Column>
      </DataTable>

      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        text='このベンチマーク調査を削除してもよろしいでしょうか？'
        onAgree={handleDelete}
        title='確認'
        isProcessing={isProcessing}
      />
    </>
  )
}
