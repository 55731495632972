import React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { messageSquareCheckIcon } from '@/utils/images'
import styles from './styles.module.css'

function InputEmail() {
  const navigate = useNavigate()

  return (
    <Box className={styles.responseWrapper}>
      <Box className={`${styles.boxIcon} background-theme`}>
        <img src={messageSquareCheckIcon} />
      </Box>
      <Box className={styles.boxText}>
        <label className='color-theme'>管理者はあなたの依頼を承りました。</label>
        <p>
          メールにてご案内いたしますので、少々お待ちくださいます
          <br />
          ようお願い申し上げます。
        </p>
      </Box>
      <Button
        className={`${styles.btnSubmit} background-theme background-theme-hover`}
        variant='contained'
      >
        TOPページに戻る
      </Button>
    </Box>
  )
}

export default InputEmail
