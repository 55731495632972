import React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import RetrieveLayout from './RetrieveLayout'
import styles from './styles.module.css'

function InputRequest() {
  const navigate = useNavigate()

  const onSubmit = () => {
    navigate('/retrieve/response')
  }
  return (
    <RetrieveLayout>
      <Box className={styles.formLogin} sx={{ width: '645px' }}>
        <h3 className={`${styles.title} color-theme`} style={{ fontSize: '1.75rem' }}>
          管理者にアカウント再発行を依頼してください。
        </h3>
        <Box className={styles.inputGroup}>
          <label className={`${styles.label}`}>依頼内容</label>
          <Input
            type='text'
            placeholder='依頼の内容を入力'
            inputProps={{ className: `${styles.input}` }}
          />
        </Box>
        <Button
          onClick={onSubmit}
          className={`${styles.btnSubmit} background-theme background-theme-hover`}
          variant='contained'
        >
          依頼する
        </Button>
      </Box>
    </RetrieveLayout>
  )
}

export default InputRequest
