import axiosClient from './axiosClient'

const userApi = {
  getUser: () => {
    const url = '/user/info'
    return axiosClient.get(url)
  },
  updateUser: (data) => {
    const url = '/user/change-info'
    return axiosClient.put(url, data)
  },
  updatePassword: (data) => {
    const url = '/user/change-password'
    return axiosClient.put(url, data)
  },
  inviteUser: (data) => {
    const url = '/user/invite'
    return axiosClient.post(url, data)
  },
  verifyEmail: (data) => {
    const url = '/user/verify-email'
    return axiosClient.get(url, { params: data })
  },
  getMembers: () => {
    const url = '/user/admin/all'
    return axiosClient.get(url)
  },
  changeActive: (data) => {
    const url = '/user/change-activate'
    return axiosClient.put(url, data)
  },
  deleteUser: (data) => {
    const url = '/user/delete'
    return axiosClient.put(url, data)
  },
}

export default userApi
