import React, { useState, useEffect } from 'react'
import useWindowDimensions from '@/hooks/useWindowDimensions'

const ReadMore = ({ children, maxChar = 80 }) => {
  const { width } = useWindowDimensions()
  const [charCounter, setcharCouter] = useState(100)
  const text = children

  useEffect(() => {
    if (width <= 380) setcharCouter(20)
    else if (width <= 430) setcharCouter(25)
    else if (width <= 600) setcharCouter(50)
    else setcharCouter(maxChar)
  }, [width])

  return (
    <p className='mb-0'>
      {text.length > charCounter ? (
        <>
          {text.slice(0, charCounter)}
          <span style={{ fontWeight: 'normal' }}>...</span>
        </>
      ) : (
        text
      )}
    </p>
  )
}

export default ReadMore
