import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import BenchmarkTable from './BenchmarkTable'
import styles from './benchmark.module.css'
import { useNavigate } from 'react-router-dom'
import { getAllBenchmark, clearState, benchmarkSelector } from '@/redux/modules/benchmark'
import { useSelector, useDispatch } from 'react-redux'
import { Loading } from '@/components'
import { transformErrors } from '@/utils/errorHandling'
import toast from 'react-hot-toast'
import { scrollTop } from '@/utils/helper'

function index() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { list, isFetching, isError, errors, successMessage } = useSelector(benchmarkSelector)

  useEffect(() => {
    let isApiSubscribed = true
    const controller = new AbortController()
    const signal = controller.signal

    const fetchBenchmark = () => {
      if (isApiSubscribed) {
        dispatch(getAllBenchmark({ signal: signal }))
        isApiSubscribed = false
      }
    }
    scrollTop()
    fetchBenchmark()
    return () => {
      controller.abort()
      isApiSubscribed = false
    }
  }, [])

  useEffect(() => {
    if (isError) {
      const errs = transformErrors(errors)
      errs.forEach((er) => {
        if (er.fieldName !== 'status') toast.error(er.message)
      })
      dispatch(clearState())
    }

    if (successMessage) {
      toast.success(successMessage)
      dispatch(clearState())
    }
  }, [isError, successMessage])

  if (isFetching) return <Loading />
  if (!list) return null
  return (
    <Box className={`mid-container w-100`}>
      <Box className={'header-page-table'}>
        <label className='color-theme title-page-table'>ベンチマーク調査一覧</label>
        <Button
          className={`${styles.btnAddBenchmark} background-theme background-theme-hover`}
          variant='contained'
          onClick={() => navigate('add-new')}
        >
          新規調査
        </Button>
      </Box>
      <div>
        {Object.keys(list)
          .reverse()
          .map((key) => {
            const dataList = list[key].length ? [...list[key]].reverse() : []
            return <BenchmarkTable key={`benchmark-${key}`} data={dataList} title={key} />
          })}
      </div>
    </Box>
  )
}

export default index
