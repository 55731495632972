import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import { finchLogo } from '@/utils/images'
import { Navbar, Footer } from '@/components'
import { scrollTop } from '@/utils/helper'

import './styles.css'

function index() {
  useEffect(() => {
    scrollTop()
  }, [])

  return (
    <Box className={`body-wrapper`}>
      <Navbar />
      {/* Code here */}
      <Box className={`wrapper--terms`}>
        <Box className={`bg--banner`}>
          <h1 className={`title_h1--banner`}>サービス利用規約</h1>
        </Box>
        <Box className={`main--terms`}>
          <Box className={`container container__1230`}>
            {/*  */}
            <Box className={`content--txt`}>
              <Box className={`top-content--txt`}>
                株式会社フィンチジャパン　InFolioサービス利用規約
                <br />
                2024年6月20日
              </Box>
              <h4 className={`title__h4`}>第1条（適用）</h4>
              <Box className={`mb-20`}>
                1.     本規約は、本規約に同意のうえ、当社との間で
                SaaSサービスの利用に関する契約（以下「サービス利用契約」という）を締結した者（以下「契約ユーザー」という）が株式会社フィンチジャパン（以下「当社」という）のソフトウェアサービス（ソフトウェアの機能をネットワーク経由で提供するサービスであって別紙に定めるものをいい、以下総称して「SaaSサービス」という）を利用するにあたり必要な条件を定めることを目的とします。
                <br />
                2.     契約ユーザーは、SaaSサービスの利用にあたり本規約を遵守するものとします。
              </Box>
              <h4 className={`title__h4`}>第2条（変更）</h4>
              <Box className={`mb-20`}>
                1.    
                当社は、本規約を随時変更することがあります。なお、この場合には、契約ユーザーの利用条件その他サービス利用契約の内容は、変更後の新規約を適用するものとします。
                <br />
                2.    
                当社は、前項の変更を行う場合は、1週間前までに、契約ユーザーが登録しているメールアドレス宛に、変更後の新規約の内容を通知するものとします。
              </Box>

              <h4 className={`title__h4`}>第3条（契約の締結等）</h4>
              <Box className={`mb-20`}>
                1.    
                サービス利用契約は、申込者（サービス利用契約の締結を希望する者をいい、以下同じ）が当社所定の書式の申込書を当社に提出し、当社がこれに対し承諾の通知を発信したときに成立するものとします。なお、申込者は、本規約の内容を承諾のうえ、申込を行うものとし、申込者が申込を行った時点で、当社は、申込者が本規約の内容を承諾しているものとみなします。
                <br />
                2.    
                当社は申し込みを検討したうえで、承諾する際は承諾の通知とともに、SaaSサービスの利用開始日（以下「サービス実施開始日」という）を通知します。なお、サービス利用契約は、サービス利用契約の申込（当社所定の付番により特定されるものをいう）ごとに締結されます。
                <br />
                3.    
                当社は、申込者が次の各号のいずれかに該当すると判断した場合には、サービス利用契約を締結しないことがあります。
                <br />
                (ア)申込者が虚偽の事実を申告したとき
                <br />
                (イ)申込者がSaaSサービスの利用にかかる料金の支払を怠るおそれがあるとき
                <br />
                (ウ)SaaSサービスの提供が技術上困難なとき
                <br />
                (エ)申込者が過去に当社との契約に違反したことがあるとき
                <br />
                (オ)第29条に定める保証、表明に反する事実があったとき、または、確約に反する行為があったとき
                <br />
                (カ)当社の業務の遂行に支障があるときその他当社が不適当と判断したとき
                <br />
                4.    
                サービス利用契約は、契約成立日における契約ユーザー、当社間の合意を規定するものであり、サービス利用契約締結前に相互に取り交わした合意事項、各種資料、申し入れ等がサービス利用契約の内容と相違する場合は、サービス利用契約の内容が優先されるものとします。
                <br />
                5.    
                本規約に記載されている内容は、サービス利用契約に関する合意事項の全てであり、契約ユーザーおよび当社はサービス利用契約およびSaaSサービスに関し、互いに本規約で定められている内容以上の義務および責任を負担しないものとします。
                <br />
                6.    
                契約ユーザーは、第1項の申込事項につき変更する事由が生じた場合は、当社所定の申込書に、変更内容を記入後記名押印し、当社に提出するものとします。
              </Box>

              <h4 className={`title__h4`}>第4条（実施期間）</h4>
              <Box className={`mb-20`}>
                1.    
                SaaSサービスの実施期間は1ヶ月とし、実施期間の開始日は、前条に定めるサービス実施開始日とします。ただし、契約ユーザーおよび当社のいずれからも別段の意思表示のないときは、引き続き同一条件をもって、実施期間はさらに1ヶ月自動的に継続延長されるものとし、以後もまた同様とします。解約に関する規定は、第5条に定めるものとします。
              </Box>
              <h4 className={`title__h4`}>第5条（終了）</h4>
              <Box className={`mb-20`}>
                1.    
                契約ユーザーは、当社に解約の申込を行うことにより、サービス利用契約を解約し、SaaSサービスの利用を終了することができます。契約ユーザーがサービス利用契約を解約するときには、解約を希望する月の20日まで、20日が休業日に当たる場合はその直前の営業日までに、当社所定のフォーマットをもって当社に解約の申込を行うものとします。サービス利用契約は、契約ユーザーから当社に解約の申込が到達したタイミングにより、以下のように終了します。
                <br />
                (ア)20日までに解約の申込があった場合：該当月の月末に終了
                <br />
                (イ)21日から月末までに解約の申込があった場合：翌月の月末に終了
                <br />
                2.    
                契約ユーザーまたは当社は、相手方が次の各号のいずれかに一つにでも該当したときは、相手方になんらの通知・催告を要せず直ちにサービス利用契約の全部または一部を解除できるものとします。
                <br />
                (ア)手形または小切手が不渡りとなったとき
                <br />
                (イ)差押え、仮差押えもしくは競売の申し立てがあったとき、または、租税滞納処分を受けたとき
                <br />
                (ウ)破産手続開始、特定調停手続開始、会社更生手続開始もしくは民事再生手続開始、その他これらに類似する倒産手続開始の申し立てがあったとき、または清算に入ったとき
                <br />
                (エ)その他前各号に準ずるようなサービス利用契約を継続し難い重大な事由が発生したとき
                <br />
                (オ)解散またはサービス利用契約の履行に係る事業の全部もしくは重要な一部を第三者に譲渡しようとしたとき
                <br />
                (カ)監督省庁から営業の取消・停止処分等を受けたとき、または転廃業しようとしたときであって、サービス利用契約を履行できないと合理的に見込まれるとき
                <br />
                (キ)第29条に定める保証、表明に反する事実があったとき、または、確約に反する行為があったとき
                <br />
                (ク)そのほか当事者間の信頼関係を破綻し、または破綻する恐れがあると甲が判断したとき
                <br />
                3.    
                契約ユーザーまたは当社は、相手方の責に帰すべき事由によりサービス利用契約に基づく債務が履行されず、相手方へ相当の期間を定めて催告したにもかかわらず、なおその期間内に履行されないときは、サービス利用契約の全部または一部を解除することができます。ただし、当該期間を経過した時における債務の不履行が、サービス利用契約および取引上の社会通念に照らして軽微であるときは、除きます。
                <br />
                4.    
                契約ユーザーまたは当社は、第2項各号のいずれかに該当したとき、または前項に定める催告をしても履行されないときは、当然に期限の利益を失い、相手方に対して負担する一切の金銭債務をただちに弁済するものとします。
              </Box>
              <h4 className={`title__h4`}>第6条（提供）</h4>
              <Box className={`mb-20`}>
                1.     当社は契約ユーザーに対し、サービス利用契約に基づき善良な管理者の注意をもって
                SaaSサービスを提供するものとします。ただし、サービス利用契約に別段の定めがあるときはこの限りでないものとします。
                <br />
                2.    
                SaaSサービスの内容は、個々のSaaSサービスごとに当社が用意するインターネット上のホームページ（以下「サービス公開ページ」という）に掲載される文書（以下「サービス仕様」という）に定めるとおりとします。
              </Box>
              <h4 className={`title__h4`}>第7条（利用）</h4>
              <Box className={`mb-20`}>
                1.    
                SaaSサービスを利用するにあたっては、契約ユーザーは、当社が別に定めるコンピュータ端末、通信回線その他のコンピュータ環境（以下「クライアント環境」という）を用意し、当社のコンピュータ設備（以下「当社サービス環境」という）に接続するものとします。SaaSサービスの提供は、クライアント環境から当社サービス環境にネットワーク経由で接続することにより行われます。
                <br />
                2.     契約ユーザーによる
                SaaSサービスの利用は、特段の定めのない限り、前項の方法により行われるものとし、契約ユーザーは、SaaSサービスの利用のために、当社のデータセンタに立ち入り等することはできないものとします。
              </Box>
              <h4 className={`title__h4`}>第8条（提供時間帯）</h4>
              <Box className={`mb-20`}>
                1.     SaaSサービスの提供時間帯は、サービス仕様に定めるとおりとします。
                <br />
                2.    
                前項の定めにかかわらず、当社は、SaaSサービスの円滑な運営のために、計画的なメンテナンス（以下「計画メンテナンス」という）を実施することがあるものとし、計画メンテナンスの実施のためにSaaSサービスの提供を一時的に中断することがあります。このとき、当社は、サービス仕様に記載の方法で、計画メンテナンスを実施する旨を、当該計画メンテナンスにかかる契約ユーザーに通知するものとします。
                <br />
                3.    
                第1項の定めにかかわらず、当社は、SaaSサービスの維持のためにやむを得ないと判断したときには、緊急のメンテナンス（以下「緊急メンテナンス」という）を実施するためにSaaSサービスの提供を一時的に中断することがあります。このとき、当社は、当該緊急メンテナンスの実施後すみやかに、緊急メンテナンスを実施した旨を、当該緊急メンテナンスにかかる契約ユーザーに報告するものとします。
              </Box>
              <h4 className={`title__h4`}>第9条（協力義務）</h4>
              <Box className={`mb-20`}>
                1.    
                契約ユーザーは、当社がSaaSサービスを提供するにあたり必要とする情報を、当社に提供するものとします。
                <br />
                2.    
                契約ユーザーは、SaaSサービスの利用にあたり、当社との連絡窓口となる者（以下「担当者」という）を定め、その連絡先情報を当社に通知するものとします。また、担当者が変更となった場合は、すみやかに変更後の担当者に関する情報を通知する必要があります。
                <br />
                3.    
                SaaSサービスの利用に関する契約ユーザーと当社との連絡は、すべて担当者を通じて行うものとします。
              </Box>
              <h4 className={`title__h4`}>第10条（問い合わせ）</h4>
              <Box className={`mb-20`}>
                1.    
                当社は、SaaSサービスに関する仕様または操作方法に関する質問を、担当者から受け付けるものとします。質問の受付・回答方法、および、受付時間帯・回答時間帯は、サービス仕様に記載のとおりとします。
                <br />
                2.    
                当社は、SaaSサービスが正常に動作しない場合における原因調査、回避措置に関する質問または相談は、担当者から個別に、あるいは全契約ユーザーからバク報告フォームを通じて受け付けるものとします。質問または相談の対応は、原則問い合わせがあった順に対応するものとします。
                <br />
                3.    
                契約ユーザーが個別に導入したサービスおよびソフトウェアに関する問い合わせ、SaaSサービスと組み合わせて使用しているソフトウェア（当社が
                SaaSサービスの一部として提供しているものを除く）に対する問い合わせ、当社サービス環境の内部構造に関する問い合わせ等、前項に記載された内容以外のサポートに関しては、行いません。
              </Box>
              <h4 className={`title__h4`}>第11条（再委託）</h4>
              <Box className={`mb-20`}>
                1.     当社は、サービス利用契約に基づき提供する
                SaaSサービスに関する作業の全部または一部を、当社の責任においてグループ会社に再委託できるものとします。
                <br />
                2.    
                前項に基づき当社が再委託した場合の、再委託先の選任、監督ならびに再委託先の行った作業の結果については、一切当社が責任を負うものとします。
              </Box>
              <h4 className={`title__h4`}>第12条（著作権等）</h4>
              <Box className={`mb-20`}>
                1.    
                SaaSサービスにおいて当社が提供するソフトウェア・コンテンツ等は、当社または第三者が著作権等を有するものであり、特段の定めのない限り、契約ユーザーは、当該ソフトウェア・コンテンツ等を複製、翻案、公衆送信（送信可能化を含む）、改造、逆コンパイル、逆アセンブル、リバースエンジニアリング等することはできないものとします。
                <br />
                2.    
                SaaSサービスの一部として当社が契約ユーザーに提供する、クライアント環境にて動作させるソフトウェア等において、その使用許諾条件が別途書面等にて提示されている場合には、契約ユーザーは、当該使用許諾条件に従って当該ソフトウェア等を使用するものとします。
                <br />
                3.    
                当社は、SaaSサービスにおいて契約ユーザーが当社サービス環境に登録したコンテンツ等を、当社が
                SaaSサービスを運営する目的に限り、当社サービス環境上において複製・翻案・自動公衆送信（送信可能化を含む）等することができるものとします。
              </Box>
              <h4 className={`title__h4`}>第13条（データの取扱）</h4>
              <Box className={`mb-20`}>
                1.    
                契約ユーザーは、契約ユーザーが当社サービス環境に登録・保存したデータ等のうち、契約ユーザーが重要と判断したデータ等を、自らの責任でダウンロードして保存するものとします。
                <br />
                2.    
                契約ユーザーは、サービス利用契約が終了するときには、当社サービス環境に登録・保存したデータを、自己の責任と費用負担において、必要に応じダウンロードして取得するものとします。なお、サービス利用契約が終了した後においては、解約前に当社サービス環境に登録・保存したデータを、参照・閲覧・操作・取得等することができないものとします。
              </Box>
              <h4 className={`title__h4`}>第14条（禁止事項）</h4>
              <Box className={`mb-20`}>
                1.      
                契約ユーザーは、SaaSサービスの利用において以下の行為を行わないものとします。
                <br />
                (ア)当社もしくは第三者の著作権、商標権等の知的財産権を侵害する行為、または、侵害するおそれのある行為
                <br />
                (イ)当社もしくは第三者の財産、プライバシーもしくは肖像権を侵害する行為、または、侵害するおそれのある行為
                <br />
                (ウ)当社もしくは第三者を差別もしくは誹謗中傷・侮辱し、第三者への差別を助長し、または、当社もしくは第三者の名誉もしくは信用を毀損する行為
                <br />
                (エ)詐欺、規制薬物の濫用、児童売買春、預貯金口座および携帯電話の違法な売買等の犯罪に結びつく行為、または結びつくおそれの高い行為
                <br />
                (オ)わいせつ、児童ポルノまたは児童虐待に相当する画像、文書等を送信もしくは掲載する行為、またはこれらを収録した媒体を販売する行為、または、その送信、掲載、販売を想起させる広告を表示または送信する行為
                <br />
                (カ)ストーカー行為等の規制等に関する法律に違反する行為
                <br />
                (キ)無限連鎖講（ネズミ講）を開設し、または、これを勧誘する行為
                <br />
                (ク)違法に賭博・ギャンブルを行い、または、これを勧誘する行為
                <br />
                (ケ)違法行為（けん銃等の譲渡、児童ポルノの提供、公文書偽造、殺人、脅迫等）を直接的かつ明示的に請負し、仲介しまたは誘引（他人に依頼することを含む）する行為
                <br />
                (コ)人の殺害現場等の残虐な情報、動物を虐待する画像等の情報、その他社会通念上第三者に著しく嫌悪感を抱かせる情報を掲載し、または、不特定多数の者にあてて送信する行為
                <br />
                (サ)人を自殺に誘引または勧誘する行為
                <br />
                (シ)インターネット異性紹介事業を利用して児童を誘引する行為の規制等に関する法律に基づく、当該事業の提供者に対する規制および当該事業を利用した不正勧誘行為の禁止に違反する行為
                <br />
                (ス)選挙の事前運動、選挙運動またはこれらに類似する行為および公職選挙法に抵触する行為
                <br />
                (セ)当社もしくは第三者に対し、無断で広告・宣伝・勧誘等の E-mail
                を送信する行為、嫌悪感を抱くもしくはそのおそれがある
                E-mail（嫌がらせメール）を送信する行為、当社もしくは第三者の E-mail
                受信を妨害する行為、または連鎖的な E-mail
                転送を依頼する行為および当該依頼に応じて転送する行為
                <br />
                (ソ)第三者の保有するコンピュータに対して多数回の接続行為を繰り返し行い、もって当該コンピュータを利用困難な状態におく行為
                <br />
                (タ)本人の同意を得ることなく、または、詐欺的な手段により第三者の個人情報を収集する行為
                <br />
                (チ)SaaSサービスの利用により利用しうる情報を改ざんまたは消去する行為
                <br />
                (ツ)当社または第三者になりすまして SaaSサービスを利用する行為
                <br />
                (テ)当社もしくは第三者の設備等の利用、運営に支障を与える行為、または、与えるおそれのある行為
                <br />
                (ト)有害なコンピュータプログラム等を送信もしくは掲載し、または、第三者が受信可能な状態におく行為
                <br />
                (ナ)法令に基づき監督官庁等への届出、許認可の取得等の手続が義務づけられている場合に、当該手続を履行せず、その他当該法令に違反する行為
                <br />
                (ニ)上記各号の他、法令もしくは公序良俗に違反（暴力、残虐等）する行為、当社の信用を毀損し、もしくは、当社の財産を侵害する行為、または、第三者に不利益を与える行為
                <br />
                (ヌ)上記各号のいずれかに該当する行為（当該行為を第三者が行っている場合を含む）が見られるデータ等へリンクを張る行為
                <br />
                (ネ)第三者に、前各号までのいずれかに該当する行為をなさしめ、または、当該第三者の当該行為が存在することを知りながら適切な措置を講じることなく放置する行為
              </Box>
              <h4 className={`title__h4`}>第15条（当事者間解決の原則）</h4>
              <Box className={`mb-20`}>
                1.    
                契約ユーザーは、第三者の行為につき、前条各号のいずれかに該当すると判断した場合は、当該第三者に対し、直接要望等を通知するものとします。
                <br />
                2.    
                契約ユーザーは、自己の行為につき、前条各号のいずれかに該当するとして当社または第三者から何らかのクレームが通知された場合、自己の責任と費用負担において当該クレームを処理解決するものとします。
              </Box>
              <h4 className={`title__h4`}>第16条（トラブル処理）</h4>
              <Box className={`mb-20`}>
                1.    
                当社は、契約ユーザーの行為が第14条各号のいずれかに該当すると判断した場合、または前条第2項のクレームに関するトラブルが生じたことを知った場合は、契約ユーザーへの事前の通知なしに、契約ユーザーが送信または表示する情報の一部もしくは全部の削除または不表示、あるいは第5条に基づく契約の解除等、当社が適当と判断する措置を講ずることができるものとします。
              </Box>
              <h4 className={`title__h4`}>第17条（自己責任の原則）</h4>
              <Box className={`mb-20`}>
                1.     契約ユーザーは、SaaSサービスを利用するための
                ID等の使用および管理について責任を持つものとし、これらが第三者に使用されたことにより契約ユーザーに生じた損害については、当社は何ら責任を負わないものとします。また、これらの第三者の使用により発生した利用料金についても、すべて契約ユーザーの負担とします。
                <br />
                2.    
                契約ユーザーは、SaaSサービスの利用に伴い、自己の責に帰すべき事由で第三者に対して損害を与えた場合、または第三者からクレーム等の請求がなされた場合、自己の責任と費用をもって処理、解決するものとします。契約ユーザーが
                SaaSサービスの利用に伴い、第三者から損害を被った場合、または第三者に対してクレーム等の請求を行う場合においても同様とします。
                <br />
                3.    
                SaaSサービスを利用して契約ユーザーが提供または伝送する情報（コンテンツ）については、契約ユーザーの責任で提供されるものであり、当社はその内容等についていかなる保証も行わず、また、それに起因する損害についてもいかなる責任も負わないものとします。
              </Box>
              <h4 className={`title__h4`}>第18条（セキュリティの確保）</h4>
              <Box className={`mb-20`}>
                1.    
                当社は、当社サービス環境の安全を確保するために、当社サービス環境に当社所定のセキュリティ防護措置を講じるものとします。なお、当社は、当社サービス環境への不正なアクセスまたは
                SaaSサービスの不正な利用を完全に防止することを何ら保証するものではありません。
                <br />
                2.    
                当社は、SaaSサービスの提供のために設置する当社設備等に対してまたはこれを利用して不正侵入を試みる通信、当社設備等の破壊を試みる通信、およびSaaSサービスの利用不能等を試みる通信等（以下総称して「攻撃的通信」という）を検知するため、当社設備に侵入検知システム等（以下「IDS」という）を設置する場合があります。当社は、IDSにより、当社設備等に対してまたはこれを利用してなされる通信が、攻撃的通信であるか否かを判断するため、SaaSサービスと外部との通信の内容を確認することがあります。契約ユーザーは、IDSにより、当社が当該通信の内容を確認されることがあることを、あらかじめ了解するものとします。当社は、IDSにより得られた攻撃的通信の記録の集計・分析を行い、統計資料を作成し、SaaSサービスの安全性向上等のために利用、処理します。また、契約ユーザーは、当社が作成した統計資料が、コンピュータセキュリティの研究、開発、改善、啓蒙その他の目的のために公表されることがあることを、了解するものとします。
              </Box>
              <h4 className={`title__h4`}>第19条（契約ユーザー固有情報）</h4>
              <Box className={`mb-20`}>
                1.     当社は、契約ユーザーが
                SaaSサービスに自ら登録・入力した、契約ユーザー固有の情報であってアクセス制御機能が施されているもの（以下「契約ユーザー固有情報」という）を、契約ユーザーの同意なく参照、閲覧等して利用しません。
                <br />
                2.    
                前項の定めにかかわらず、当社は、次の各号のいずれかに該当する場合には、契約ユーザー固有情報を、正当な範囲で参照、閲覧（当該各号において定める場合には第三者に開示することを含む）することがあるものとします。なお、当社は、次の各号のいずれかに該当することにより参照・閲覧された契約ユーザー固有情報を、当該各号の定めに基づく参照・閲覧の目的以外の目的に利用しないものとします。
                <br />
                (ア)裁判所の命令もしくは法令に基づく強制的な処分が行われた場合において、当該処分の範囲で開示する場合
                <br />
                (イ)特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律に基づく開示請求の要件が充足された場合において、当該開示請求の範囲で開示する場合
                <br />
                (ウ)生命、身体または財産の保護のために必要があると当社が判断した場合において、当該保護のために必要な範囲で利用、開示する場合
                <br />
                (エ)当社が
                SaaSサービスを運営するために必要な範囲（利用料金の算定、設備の維持等）において契約ユーザー固有情報を参照する場合
              </Box>
              <h4 className={`title__h4`}>第20条（秘密情報の取り扱い）</h4>
              <Box className={`mb-20`}>
                1.     本規約において、秘密情報とは、以下の情報をいうものとします。
                <br />
                (ア)秘密である旨の表示をした書面（電子的形式を含む）で開示された相手方固有の業務上、技術上、販売上の情報
                <br />
                (イ)秘密である旨明示して口頭またはデモンストレーション等により開示された相手方固有の業務上、技術上、販売上の情報であって、開示後10日以内に相手方に書面（電子的形式を含む）で提示された情報
                <br />
                (ウ)サービス利用契約の内容（ただし、本規約およびサービス公開ページに掲載されている内容を除く）
                <br />
                2.     
                前項にかかわらず、次の各号のいずれかに該当する情報は、前項における秘密情報から除くものとします。
                <br />
                (ア) 
                開示の時点で既に公知のもの、または開示後秘密情報を受領した当事者（以下「受領者」という）の責によらずして公知となったもの
                <br />
                (イ)  受領者が第三者から秘密保持義務を負うことなく正当に入手したもの
                <br />
                (ウ)  開示の時点で受領者が既に保有しているもの
                <br />
                (エ)  開示された秘密情報によらずして、独自に受領者が開発したもの
                <br />
                3.    
                契約ユーザーおよび当社は、それぞれ相手方から開示された秘密情報の秘密を保持し、SaaSサービスの利用のために（また当社においてはSaaSサービスの運営、開発等のために）知る必要のある自己の役員および従業員以外に開示、漏洩してはならないものとします。また、契約ユーザーおよび当社は、秘密情報の開示のために相手方から受領した資料（E-mail等、ネットワークを介して受信した秘密情報を有形的に固定したものを含み、以下「秘密資料」という）を善良な管理者の注意をもって保管管理するとともに、第三者に譲渡、提供せず、また当該役員、従業員以外の者に閲覧等させないものとします。
                <br />
                4.    
                前項にかかわらず、次のいずれかに該当する場合、契約ユーザーおよび当社は、相手方の秘密情報および秘密資料を当該第三者に開示、提供することができるものとします。
                <br />
                (ア) 
                法令により第三者への開示を強制された場合。ただし、この場合、受領者は事前に相手方に通知するよう努めるものとし、当該法令の範囲内で秘密を保持するための措置をとることを当該第三者に要求するものとします。
                <br />
                (イ) 
                弁護士、公認会計士等法令上守秘義務を負う者に、当該者の業務上必要とされる範囲内で提供する場合
                <br />
                (ウ) 
                契約ユーザーおよび当社が、本条に定める秘密保持義務と同様の秘密保持義務を書面で課して、SaaSサービスおよびSaaSサービスに関連するソフトウェア開発等に関する作業の全部または一部を当該第三者に委託する場合
                <br />
                2.    
                契約ユーザーおよび当社は、相手方から開示された秘密情報を、SaaSサービスのためにのみ利用するものとし、その他の目的に利用しないものとします。
                <br />
                3.    
                契約ユーザーおよび当社は、SaaSサービスの利用のために必要な範囲で秘密資料を複製することができるものとします。なお、秘密資料の複製物（以下本条において「複製物」という）についても本条の定めが適用されるものとします。
                <br />
                4.    
                契約ユーザーおよび当社は、相手方から要求があった場合、または、サービス利用契約が終了した場合、遅滞なく秘密資料（複製物がある場合はこれらを含む）を相手方に返却、または、破棄もしくは消去するものとします。なお、秘密資料を返却、破棄もしくは消去した後も、本条に定める秘密保持義務は有効に存続するものとします。
                <br />
                5.    
                契約ユーザーおよび当社は、相手方の秘密情報を知ることになる自己の役員および従業員に本条の内容を遵守させるものとします。
                <br />
                6.    
                契約ユーザーが保有する個人情報（「個人情報の保護に関する法律」に定めるものをいう）でその旨明示のうえ開示された情報および当該個人情報の開示のために契約ユーザーから受領した資料（第3項の資料と同種のものをいう）についてはそれぞれ、本条における秘密情報および秘密資料と同じ取扱いを行うものとします。ただし、第2項第(ア)号から第(ウ)号の個人情報には適用されないものとします。
                <br />
                7.    
                本条の規定は、サービス利用契約が終了してからも期間の定めなく、有効に存続するものとします。
              </Box>
              <h4 className={`title__h4`}>第21条（SaaSサービスに対する責任）</h4>
              <Box className={`mb-20`}>
                1.    
                当社の責に帰すべき事由により、サービス利用契約に基づく個々のSaaSサービスが全く利用できない（当社が当該SaaSサービスを全く提供しない場合もしくは当該
                SaaSサービスの支障が著しく、その支障が全く利用できない程度の場合をいい、以下「利用不能」という）ために契約ユーザーに損害が発生した場合、契約ユーザーがSaaSサービスを利用不能となったことを当社が知った時刻から起算して48時間以上利用不能の状態が継続したときに限り、当社は、契約ユーザーの月額利用額の３か月分を限度として、賠償責任を負うものとします。ただし、当社の責に帰することができない事由から生じた損害、当社が予見すべきであったか否かを問わず特別の事情から生じた損害、逸失利益については、当社は賠償責任を負わないものとします。SaaSサービスの利用不能に関して当社が負う法律上の責任は、本項に定める範囲に限られるものとします。
                <br />
                2.    
                契約ユーザーは、サービス利用契約に基づく債務を履行しないこと、または、第5条第2項第(ア)号から第(ク)号のいずれかに該当するなどしたことにより、当社に損害が発生した場合、当社に生じた損害について賠償責任を負うものとします。
              </Box>
              <h4 className={`title__h4`}>第22条（料金月）</h4>
              <Box className={`mb-20`}>
                1.     SaaSサービスの料金月は、当月1日から当月末日までとします。
              </Box>
              <h4 className={`title__h4`}>第23条（利用料金）</h4>
              <Box className={`mb-20`}>
                1.    
                SaaSサービスの利用料金は、サービス個別資料にて個別にお知らせするものとします。
                <br />
                2.     SaaSサービスの利用料金の発生は、それぞれ以下のとおりとします。
                <br />
                (ア) 
                サービス利用契約において料金種別が「初期費用」とされているものについては、SaaSサービスの開始に伴い、契約時に発生するものとします。
                <br />
                (イ)  サービス利用契約において料金種別が「月額」とされているものについては、当該
                SaaSサービスに関する各料金月の初日にその金額が発生するものとします。
                <br />
                (ウ) 
                サービス利用契約において料金種別が「従量」とされているものについては、当該SaaSサービスの利用量に応じて発生するものとします。
                <br />
                (エ) 
                サービス利用契約において料金種別が「アカウント維持費」とされているものについては、当該SaaSサービスを契約するアカウント数に応じて発生するものとします。
                <br />
                3.    
                当社は、料金種別が「従量」とされているものについては、各料金月の初日から末日まで（当該料金月の途中においてサービス利用契約が開始または終了するときには、当該開始の日から、または当該終了の日まで）の利用料金を計算するものとします。
                <br />
                4.    
                利用料金のうち、料金種別が「初期費用」とされているものについては、この初期費用の請求は、別段定めがある場合を除き、最初の利用料金の請求と合わせて行われます。初期費用は、サービス実施開始日またはサービス実施終了日が料金月の途中であっても、日割り計算しません。
                <br />
                5.    
                利用料金のうち、料金種別が「月額」とされているものについては、サービス実施開始日またはサービス実施終了日が料金月の途中の場合、日割りで請求を行います。
                <br />
                6.    
                利用料金のうち、料金種別が「アカウント維持費」とされているものについては、サービス実施開始日またはサービス実施終了日が料金月の途中であっても、日割り計算しません。
                <br />
                7.    
                SaaSサービスの利用料金にかかる消費税および地方消費税（以下「消費税等」という）相当額は、前項に基づき算出される、サービス利用契約全体で合算された利用料金に対して算定されるものとします。なお、消費税等相当額の算定の際の税率は、当該算定時に税法上有効な税率とします。
                <br />
                8.    
                SaaSサービスの利用料金および消費税等相当額の算定に関して、1円未満の端数が生じた場合、当該端数は切り捨てるものとします。
                <br />
                9.    
                前各項にかかわらず、SaaSサービスの利用について無料期間が設定されているときは、サービス利用契約において料金種別「月額」「アカウント契約数」とされているものについての無料期間中の利用料金は無料となります。なお、「初期費用」とされるものについては、無料期間が終了する時点で発生するものとします。
              </Box>
              <h4 className={`title__h4`}>第24条（支払義務）</h4>
              <Box className={`mb-20`}>
                1.    
                契約ユーザーは、前条に定められた各料金月のSaaSサービスの利用料金および消費税等相当額を、当社から送る請求書をもって、翌月末までに銀行振込にて支払うものとします。なお、支払期日が金融機関の休業日にあたる場合は、当該支払期日は前営業日とします。
              </Box>
              <h4 className={`title__h4`}>第25条（支払条件）</h4>
              <Box className={`mb-20`}>
                1.    
                前条の支払時における金融機関に対する振込手数料等は、契約ユーザーの負担とします。
                <br />
                2.    
                契約ユーザーがサービス利用契約により生ずる金銭債務（手形債務を含み、以下同じ）の弁済を怠ったときは、当社に対し支払期日の翌日から完済の日まで年利14.6%の割合（365日日割計算）による遅延損害金を支払うものとします。
                <br />
                3.    
                契約ユーザーが利用料金および消費税等相当額を支払期日までに支払わない場合、当社は契約ユーザーに催告のうえ、SaaSサービスの提供を停止することがあるものとします。
              </Box>
              <h4 className={`title__h4`}>第26条（権利譲渡等の禁止）</h4>
              <Box className={`mb-20`}>
                1.    
                契約ユーザーは、サービス利用契約に基づく権利および義務を、第三者に譲渡、貸与等しないものとします。
              </Box>
              <h4 className={`title__h4`}>第27条（転売の禁止等）</h4>
              <Box className={`mb-20`}>
                1.    
                契約ユーザーは、本規約に別段の定めのない限り、または当社の事前の承諾のない限り、第三者に対してSaaSサービスの全部または一部の機能に直接アクセスする形態での転売・再販売・サブライセンス等をしないものとします。
                <br />
                2.    
                本規約は、当社との間でサービス利用契約を締結した申込者に適用されるものであり、申込者が、当社特約店、代理店等の第三者（以下「販売会社」という）との間で
                SaaSサービスの提供に関する契約を締結している場合には、本規約は適用されず、SaaSサービスの提供に関する条件は、当該販売会社と申込者との間で締結される契約に基づくものとします。この場合においては、当社は、当該申込者による
                SaaSサービスの利用に関し、当該申込者に対し直接に責任を負うものではありません。
              </Box>
              <h4 className={`title__h4`}>第28条（サービスの改廃）</h4>
              <Box className={`mb-20`}>
                1.    
                当社は、SaaSサービスの提供を廃止することがあります。その場合、当社は、3か月の予告期間をおいて契約ユーザーにその旨を通知するものとします。
                <br />
                2.    
                当社は、SaaSサービスの改善等の目的のため、当社の判断により、SaaSサービスの内容の追加、変更、改廃等を行うことがあります。当該追加、変更、改廃等の内容は、登録に使用されているメールアドレス宛に通知します。当社は、SaaSサービスの内容の追加、変更、改廃等を行うときには、7日以上の予告期間をもって、変更後の
                SaaSサービスの内容を、登録に使用されているメールアドレス宛に通知、またはサービス公開ページに掲載します。
              </Box>
              <h4 className={`title__h4`}>第29条（反社会的勢力等の排除）</h4>
              <Box className={`mb-20`}>
                1.    
                契約ユーザーおよび当社は、サービス利用契約の締結にあたり、自らまたはその役員（名称の如何を問わず、相談役、顧問、業務を執行する社員その他の事実上経営に参加していると認められる者）および従業員（事業の利益に重大な影響を及ぼす業務について権限を有するかまたはそれを代行する者）が、次の各号に記載する者（以下「反社会的勢力等」という）に該当せず今後も該当しないこと、また、反社会的勢力等との関係を持っておらず今後も持たないことを表明し、保証します。
                <br />
                (ア) 
                警察庁「組織犯罪対策要綱」記載の「暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ、特殊知能暴力集団等」その他これらに準ずる者
                <br />
                (イ) 
                資金や便宜を供与したり、不正の利益を図る目的で利用したりするなど、前号に記載する者と人的・資本的・経済的に深い関係にある者
                <br />
                2.    
                契約ユーザーおよび当社は、自らまたは第三者を利用して、次の各号に記載する行為を行わないことを相手方に対して確約します。
                <br />
                (ア)  詐術、暴力的行為または脅迫的言辞を用いる行為
                <br />
                (イ)  違法行為や不当要求行為
                <br />
                (ウ)  業務を妨害する行為
                <br />
                (エ)  名誉や信用等を既存する行為
                <br />
                (オ)  前各号に準ずる行為
              </Box>
              <h4 className={`title__h4`}>第30条（免責）</h4>
              <Box className={`mb-20`}>
                1.    
                次に掲げる事項（ただしこれらに限られないものとする）について、当社は、当該事項に起因して契約ユーザーに生じた損害については、いかなる法律上の責任も負わないものとします。
                <br />
                (ア)  計画メンテナンスの実施
                <br />
                (イ) 
                火災、爆発、停電、地震、洪水、暴風雨、ストライキ、禁輸措置、労働争議、政府または軍事機関による行動、戦争、テロリズム、原材料その他必要なリソースの不足または調達不能、天災、感染症の蔓延
                <br />
                (ウ) 
                規制機関または政府機関の作為または不作為（オンラインサービスの提供に影響する法規制の制定またはその他の政府の行為を含む）
                <br />
                (エ)  クライアント環境の不具合
                <br />
                (オ) 
                SaaSサービスに接続するためのネットワーク回線の不具合（インターネット通信業者の作為または不作為を含む）
                <br />
                (カ)  契約ユーザーの不正な操作
                <br />
                (キ)  第三者からの不正アクセス、クラッキングによるシステム破壊・改ざん、情報窃取
                <br />
                2.    
                契約ユーザーは、SaaSサービスが、一般事務用、パーソナル用、家庭用、通常の産業用等の一般的用途を想定して実施されているものであり、原子力施設における核反応制御、航空機自動飛行制御、航空交通管制、大量輸送システムにおける運行制御、生命維持のための医療用機器、兵器システムにおけるミサイル発射制御など、極めて高度な安全性が要求され、仮に当該安全性が確保されない場合、直接生命・身体に対する重大な危険性を伴う用途（以下「ハイセイフティ用途」という）に使用されるよう実施されているものではないことを確認します。契約ユーザーは、当該ハイセイフティ用途に要する安全性を確保する措置を施すことなく、SaaSサービスをハイセイフティ用途に使用しないものとします。また、契約ユーザーがハイセイフティ用途に
                SaaSサービスを使用したことにより発生する、契約ユーザーまたは第三者からのいかなる請求または損害賠償に対しても当社は責任を負わないものとします。
                <br />
                3.    
                契約ユーザーは、SaaSサービスに保存されまたは、SaaSサービスにおいて取り扱われる自己のデータについて、自己のみの責任でバックアップを取り、保存するものとします。契約ユーザーは、当該データの毀損または滅失およびそれにより生じた契約ユーザーまたは第三者の損害等について、当社が一切の責任を負わない旨を了承するものとします。
                <br />
                4.    
                サービス利用契約に関し相手方から受領した情報を保存・管理するために第三者のサービス（以下「外部データ保存サービス」という）を利用することに契約ユーザーおよび当社が合意した場合、契約ユーザーおよび当社のうち外部データ保存サービスを準備する当事者（以下「準備者」という）から提示された当該第三者または準備者が定める外部データ保存サービスの利用条件（当該サービスにかかるセキュリティ上の免責規定等を含む）に、相手方はあらかじめ同意するものとし、準備者の責任は当該利用条件の免責規定等の範囲に限られるものとします。なお、当該利用条件の定めとサービス利用契約の定めが異なる範囲において、当該利用条件の定めがサービス利用契約に優先して適用されるものとします。
                <br />
                5.    
                SaaSサービスはAPIによって連携された生成AI等のサービスを使用しているものであり、以下の事項について、当社はいかなる責任も負いません。
                <br />
                (ア) 
                SaaSサービスによって生成された内容に、事実と異なる内容が含まれていた場合。また、事実と異なる内容が含まれていたことが原因で、契約ユーザーに損害が発生した場合。
              </Box>
              <h4 className={`title__h4`}>第31条（合意管轄）</h4>
              <Box className={`mb-20`}>
                1.    
                本規約およびサービス利用契約に関する訴訟については、東京地方裁判所をもって第一審の専属的合意管轄裁判所とします。
              </Box>
              <h4 className={`title__h4`}>第32条（準拠法）</h4>
              <Box className={`mb-20`}>
                1.     本規約およびサービス利用契約に関する準拠法は、日本法とします。
              </Box>
            </Box>
            <Box className={`policy__contact`}>
              <Box className={`cRight`}>
                <img className={`img-fluid `} src={finchLogo} alt='' />
              </Box>
              <Box className={`cLeft`}>
                <Box className={`cHead`}>個人情報に関するお問い合わせ</Box>
                <Box component={'ul'} className={`cList__txt`}>
                  <Box component={'li'}>住所：東京都渋谷区恵比寿西1-33-6　JPnoie恵比寿西 1F/2F</Box>
                  <Box component={'li'}>社名：株式会社フィンチジャパン</Box>
                  <Box component={'li'}>TEL：03-4590-5964</Box>
                </Box>
              </Box>
              
            </Box>

            {/*  */}
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default index
