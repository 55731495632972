import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'

import styles from './layout.module.css'

function FormLayout({
  title,
  width = '44.6875rem',
  children,
  cancelLabel = '',
  paddingTop = '9.375rem',
  onClick = () => {},
  disableButton = true,
  isProcessing = false,
  height = '100%',
  saveLable = '保存',
}) {
  return (
    <Box
      className={styles.formContainer}
      sx={{ width: `${width}`, paddingTop: `${paddingTop}`, height: height }}
    >
      <h3 className={`${styles.formTitle} color-theme`}>{title}</h3>
      {children}
      <Box className={styles.buttonSubmit}>
        {cancelLabel && (
          <Button className={styles.cancelButton} variant='contained'>
            {cancelLabel}
          </Button>
        )}
        <Button
          onClick={onClick}
          className={`background-theme background-theme-hover`}
          variant='contained'
          disabled={disableButton || isProcessing}
        >
          {isProcessing ? (
            <CircularProgress
              variant='indeterminate'
              disableShrink
              sx={{
                color: '#fff',
                animationDuration: '550ms',
                position: 'absolute',
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
              }}
              size={30}
              thickness={4}
            />
          ) : (
            saveLable
          )}
        </Button>
      </Box>
    </Box>
  )
}

export default FormLayout
