import normalizeUrl from 'normalize-url'

export const compareUrls = (firstUrl, secondUrl) => {
  if (firstUrl === secondUrl) {
    return true
  }

  const options = {
    defaultProtocol: 'https',
    removeQueryParameters: true,
  }
  return normalizeUrl(firstUrl, options) === normalizeUrl(secondUrl, options)
}

export const isValidUrl = (urlString) => {
  // https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
  // var regex = new RegExp(
  //   '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
  // )
  var regex = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // validate fragment locator

  return !!regex.test(urlString)
}

export const splitArray = (array, size) => {
  let result = []
  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size)
    result.push(chunk)
  }
  return result
}

export const getDomain = (url) => {
  try {
    const { host } = new URL(url)
    return host
  } catch (error) {
    return url
  }
}

export const range = (start, end) => {
  var ans = []
  for (let i = start; i <= end; i++) {
    ans.push(i)
  }
  return ans
}

export const scrollTop = () => {
  window.scrollTo(0, 0)
}
