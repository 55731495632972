import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from '@/redux/store'

import { configureSentry } from './sentry'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/primereact.css'
import './index.css'
import './themes.css'
import App from './pages'
import { Toaster } from 'react-hot-toast'

import reportWebVitals from './reportWebVitals'

configureSentry() // Initialize Sentry

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
    <Toaster />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
