import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'

function index({ open, onClose, text = '', title = '', onAgree = () => {}, isProcessing = false }) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        className='theme'
        sx={{ '& .MuiPaper-root': { borderRadius: '15px' } }}
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{text}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: '15px' }}>
          <Button
            className='ouline color-theme'
            sx={{ border: '1px solid', height: '40px', lineHeight: '40px' }}
            onClick={onClose}
          >
            いいえ
          </Button>
          <Button
            onClick={onAgree}
            className={`background-theme background-theme-hover`}
            variant='contained'
            sx={{ height: '40px', lineHeight: '40px' }}
            disabled={isProcessing}
          >
            {isProcessing ? (
              <CircularProgress
                variant='indeterminate'
                disableShrink
                sx={{
                  color: '#fff',
                  animationDuration: '550ms',
                  position: 'absolute',
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                  },
                }}
                size={25}
                thickness={4}
              />
            ) : (
              'はい'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default index
