import { setThemeColor } from '@/utils/localData'
import {
  PURPLE,
  GREEN,
  BLUE_THEME,
  GREEN_THEME,
  PURPLE_THEME,
  BENCH_MARK_BUSINESS,
} from '@/utils/constant'
import pptxgen from 'pptxgenjs'
import { getThemeColor } from '@/utils/localData'
import { compareUrls, isValidUrl, splitArray } from './helper'
import { transformErrors } from '@/utils/errorHandling'
import toast from 'react-hot-toast'

export const setTheme = (theme) => {
  if (theme === GREEN) {
    document.documentElement.style.setProperty('--theme', GREEN_THEME['color'])
    document.documentElement.style.setProperty('--light-theme', GREEN_THEME['lightColor'])
    document.documentElement.style.setProperty('--bg-login-theme', `url(${GREEN_THEME['bgLogin']})`)
    document.documentElement.style.setProperty(
      '--bg-unauthen-theme',
      `url(${GREEN_THEME['bgUnauthen']})`
    )
    document.documentElement.style.setProperty(
      '--search-icon-theme',
      `url(${GREEN_THEME['searchIcon']})`
    )
  } else if (theme === PURPLE) {
    document.documentElement.style.setProperty('--theme', PURPLE_THEME['color'])
    document.documentElement.style.setProperty('--light-theme', PURPLE_THEME['lightColor'])
    document.documentElement.style.setProperty(
      '--bg-login-theme',
      `url(${PURPLE_THEME['bgLogin']})`
    )
    document.documentElement.style.setProperty(
      '--bg-unauthen-theme',
      `url(${PURPLE_THEME['bgUnauthen']})`
    )
    document.documentElement.style.setProperty(
      '--search-icon-theme',
      `url(${PURPLE_THEME['searchIcon']})`
    )
  } else {
    document.documentElement.style.setProperty('--theme', BLUE_THEME['color'])
    document.documentElement.style.setProperty('--light-theme', BLUE_THEME['lightColor'])
    document.documentElement.style.setProperty('--bg-login-theme', `url(${BLUE_THEME['bgLogin']})`)
    document.documentElement.style.setProperty(
      '--bg-unauthen-theme',
      `url(${BLUE_THEME['bgUnauthen']})`
    )
    document.documentElement.style.setProperty(
      '--search-icon-theme',
      `url(${BLUE_THEME['searchIcon']})`
    )
  }
}

export const changeTheme = (color) => {
  setThemeColor(color)
  setTheme(color)
}

export const replaceAllLink = (str) => {
  let div = document.createElement('div')

  div.innerHTML = str.replace('<div>', '').replace('</div>', '')

  let links = Array.prototype.map.call(div.querySelectorAll('a'), (link) => {
    return {
      link: link.getAttribute('href'),
      text: link.innerHTML,
    }
  })

  for (let link of links) {
    let span = document.createElement('div')
    span.innerHTML = link.link
    div.replaceChild(span, div.querySelector('a'))
  }

  return {
    links: links,
    textReplaced: div.outerHTML.toString(),
  }
}
export const onDownloadTopic = (data) => {
  const theme = getThemeColor()
  let linkColor = BLUE_THEME['lightColor']
  let titleColor = BLUE_THEME['color']
  if (theme === GREEN) {
    titleColor = GREEN_THEME['color']
    linkColor = GREEN_THEME['lightColor']
  } else if (theme === PURPLE) {
    titleColor = PURPLE_THEME['color']
    linkColor = PURPLE_THEME['lightColor']
  }
  const pptx = new pptxgen()
  const opts = {
    x: 0, // inches 1inches=96px
    y: 0,
    h: '100%',
    w: '100%',
    indentLevel: 1,
    lang: 'ja-JP',
    valign: 'top',
    fontSize: 10,
    autoFit: true,
    breakLine: true,
    fit: 'shrink',
    wrap: true,
    margin: [20, 10, 20, 10],
  }

  const optsLabel = {
    color: '#414141',
    bold: true,
    fontSize: 12,
    paraSpaceAfter: 10,
    paraSpaceBefore: 10,
  }

  data.messages.forEach((dt) => {
    const answer = dt.answer || '<div></div>'
    const { textReplaced, links } = replaceAllLink(answer)
    const arrayText = textReplaced
      .replaceAll('<div>', '<$>')
      .replaceAll('</div>', '<$>')
      .split('<$>')

    let newText = []

    arrayText.map((text) => {
      let found = null
      if (isValidUrl(text)) {
        if (links)
          found = links.find((link) => {
            return compareUrls(link.link, text)
          })
      }

      if (found) {
        newText.push({
          text: `[${found.text}]`,
          options: {
            color: linkColor,
            hyperlink: {
              url: found.link,
              tooltip: 'Visit Homepage',
            },
          },
        })
      } else {
        if (text !== '') {
          const txt = text.replaceAll('\n', '-_\n-_').split('-_')
          txt.map((t) => {
            if (t === '' && newText.length) {
              if (newText.slice(-1)[0].text !== '\n') newText.push({ text: '\n' })
            } else newText.push({ text: t })
          })
        }
      }
    })

    let allText = [
      {
        text: dt.question,
        options: {
          ...opts,
          fontSize: 14,
          bold: true,
          color: titleColor,
        },
      },
      {
        text: 'リソース一覧',
        options: { ...opts, ...optsLabel },
      },
    ]

    if (dt.links)
      dt.links.forEach((link, index) => {
        allText = [
          ...allText,
          {
            text: `[${index + 1}] ${link.title}`,
            options: {
              ...opts,
              hyperlink: {
                url: link.link,
                tooltip: 'Visit Homepage',
              },
            },
          },
        ]
      })

    allText = [
      ...allText,
      {
        text: '要約',
        options: { ...opts, ...optsLabel },
      },
      ...newText,
    ]
    const chunks = splitArray(allText, 30)

    for (let i = 0; i < chunks.length; i++) {
      const slide = pptx.addSlide()
      const element = chunks[i]
      slide.addText([...element], { ...opts })
    }
  })

  pptx.writeFile({ fileName: 'infolio-ai-topic.pptx' })
}

export const containsOnlySpaces = (str) => {
  return str.match(/^\s*$/) !== null
}

export const pushError = (errors) => {
  const errs = transformErrors(errors)
  errs.forEach((er) => {
    if (er.fieldName !== 'status') toast.error(er.message)
  })
}

export const onDownloadBenchmark = (data) => {
  const theme = getThemeColor()
  let linkColor = BLUE_THEME['lightColor']
  let titleColor = BLUE_THEME['color']
  if (theme === GREEN) {
    titleColor = GREEN_THEME['color']
    linkColor = GREEN_THEME['lightColor']
  } else if (theme === PURPLE) {
    titleColor = PURPLE_THEME['color']
    linkColor = PURPLE_THEME['lightColor']
  }
  const pptx = new pptxgen()
  const opts = {
    x: 0, // inches 1inches=96px
    y: 0,
    h: '100%',
    w: '100%',
    indentLevel: 1,
    lang: 'ja-JP',
    valign: 'top',
    fontSize: 10,
    autoFit: true,
    breakLine: true,
    fit: 'shrink',
    wrap: true,
    margin: [20, 10, 20, 10],
  }

  const optsLabel = {
    fontSize: 12,
    color: titleColor,
    bold: true,
  }

  const optsTable = {
    x: 0.5,
    y: 1,
    colW: [2, 7],
    border: { pt: '1', color: '999999' },
    fontSize: 10,
  }

  const { title, links, from_date, to_date } = data

  for (let i = 0; i < links.length; i++) {
    const slide = pptx.addSlide()
    const element = links[i]
    let allText = [
      {
        text: title,
        options: {
          ...opts,
          ...optsLabel,
        },
      },
      {
        text: `期間 ${from_date} - ${to_date}`,
        options: {
          ...opts,
          ...optsLabel,
        },
      },
    ]

    slide.addText(allText, { ...opts })
    let rows = []

    rows.push(
      [{ text: '記事投稿日' }, { text: element.publish_date }],
      [{ text: '記事タイトル' }, { text: element.title }],
      [
        { text: '調査対象サイト' },
        {
          text: element.link,
          options: {
            color: linkColor,
            hyperlink: {
              url: element.link,
              tooltip: 'Visit Homepage',
            },
          },
        },
      ],
      [
        { text: Number(data?.pattern) === BENCH_MARK_BUSINESS ? '企業名' : 'キーワード' },
        { text: element.search_keywords.toString() },
      ],
      [{ text: '要約' }, { text: element.summary }]
    )

    slide.addTable(rows, { ...optsTable })
  }

  pptx.writeFile({ fileName: data.title.trim() || 'infolio-ai-benchmark.pptx' })
}
