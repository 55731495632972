import React, { useEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Notification } from '@/components'
import Box from '@mui/material/Box'
import { getUser } from '@/utils/localData'
import { changeTheme } from '@/utils/function'
import { useSelector } from 'react-redux'
import { userSelector } from '@/redux/modules/user'
import { BLUE, GREEN, PURPLE, GREEN_NUM, PURPLE_NUM } from '@/utils/constant'
import { requestPermission } from '@/firebase'
import routers from '@/routers'
import * as Sentry from '@sentry/react'

const router = createBrowserRouter(routers)

function FallbackComponent() {
  return <div>An error has occurred</div>
}

const myFallback = <FallbackComponent />

function index() {
  const currentUser = getUser()
  const { theme } = useSelector(userSelector)

  useEffect(() => {
    const currentTheme = theme || currentUser?.theme
    let themeColor = BLUE
    if (currentTheme == GREEN_NUM) {
      themeColor = GREEN
    } else if (currentTheme == PURPLE_NUM) {
      themeColor = PURPLE
    }
    changeTheme(themeColor)
  }, [theme, currentUser])

  requestPermission()
  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <Box className={`app-container theme`}>
        <RouterProvider router={router} />
        <Notification />
      </Box>
    </Sentry.ErrorBoundary>
  )
}

export default index
