import React, { useState, useEffect } from 'react'
import useTheme from '@/hooks/useTheme'
import Box from '@mui/material/Box'
import Input from '@mui/material/Input'
import { fileSearchIcon } from '@/utils/images'
import styles from './styles.module.css'
import InputAdornment from '@mui/material/InputAdornment'
import HistoryDataTable from './HitoryDataTable'
import { NOT_FOUND } from '@/utils/message'
import { Loading } from '@/components'
import { getAllTopic, chatSelector, clearState } from '@/redux/modules/chat'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { ReactSVG } from 'react-svg'
import { pushError } from '@/utils/function'
import { SELF } from '@/utils/constant'
const filter = {
  title: '',
  question_contents: [],
  created_last_name: '',
  created_first_name: '',
}

const TopicList = ({ data, title, ...props }) => {
  const [filterText, setFilterText] = useState('')

  const searchContents = (item) => {
    const keys = item?.question_contents.map((v) => v.toLowerCase())
    if (filterText) {
      return keys.toString().includes(filterText.trim().toLowerCase())
    }
    return item
  }

  const filteredItems = data.filter(searchContents).reverse()

  const filteredItemsWithCreator = data
    .filter((item) => {
      const searchText = filterText.trim().toLowerCase()
      for (const key in filter) {
        if (key === 'created_last_name' || key === 'created_first_name') {
          const fullName = `${item.created_last_name}${item.created_first_name}`
          if (fullName.toLowerCase().includes(searchText)) return true
        } else if (key === 'question_contents') {
          const keys = item?.question_contents.map((v) => v.toLowerCase())
          if (keys.toString().includes(filterText.trim().toLowerCase())) return true
        } else {
          if (item[key].toLowerCase().includes(searchText)) return true
        }
      }
      return false
    })
    .reverse()

  if (!data.length) return null
  return (
    <>
      <div className={styles.historyHeader}>
        <img src={title === SELF ? useTheme().user1Icon : useTheme().users} />
        <label className='color-theme'>
          {title === SELF ? '自分の検索履歴一覧' : '法人内の検索履歴一覧'}
        </label>
        <Box className={styles.boxSearch}>
          <Input
            placeholder='履歴内の検索'
            onChange={(e) => setFilterText(e.target.value)}
            startAdornment={
              <InputAdornment position='start'>
                <ReactSVG src={fileSearchIcon} />
              </InputAdornment>
            }
            sx={{
              '&.MuiInputBase-root': {
                '&.MuiInput-root:before': { borderBottom: '1px solid #414141' },
              },
            }}
          />
        </Box>
      </div>
      <div className={styles.group}>
        {title === SELF ? (
          filteredItems.length ? (
            <HistoryDataTable data={filteredItems} title={title} {...props} />
          ) : (
            <p className={styles.notFoundText}>{NOT_FOUND}</p>
          )
        ) : filteredItemsWithCreator.length ? (
          <HistoryDataTable
            data={filteredItemsWithCreator}
            title={title}
            {...props}
            showCreator={true}
          />
        ) : (
          <p className={styles.notFoundText}>{NOT_FOUND}</p>
        )}
      </div>
    </>
  )
}

function Histories() {
  const dispatch = useDispatch()
  const { isFetching, allTopic, alertMessage, isError, errors } = useSelector(chatSelector)

  useEffect(() => {
    if (isError) {
      pushError(errors)
      dispatch(clearState())
    }
    if (alertMessage) {
      toast.success(alertMessage)
      dispatch(clearState())
    }
  }, [alertMessage, isError])

  useEffect(() => {
    let isApiSubscribed = true
    const controller = new AbortController()
    const signal = controller.signal

    const fetchTopics = () => {
      if (isApiSubscribed) {
        dispatch(getAllTopic({ signal: signal }))
        isApiSubscribed = false
      }
    }

    fetchTopics()
    return () => {
      controller.abort()
      isApiSubscribed = false
    }
  }, [])

  if (isFetching) return <Loading />
  if (!allTopic) return null
  return (
    <div className={styles.historiesContainer}>
      {Object.keys(allTopic)
        .reverse()
        .map((key) => {
          return <TopicList key={`panel-${key}`} data={allTopic[key]} title={key} />
        })}
    </div>
  )
}

export default Histories
