import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging'
import { setFirebaseToken } from '@/utils/localData'

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)

const messaging = getMessaging(app)

export const requestPermission = async () => {
  const isSupportedBrowser = await isSupported()
  if (!isSupportedBrowser) {
    console.log('Firebase not supported this browser')
    return
  }
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY,
      })
        .then((currentToken) => {
          if (currentToken) {
            setFirebaseToken(currentToken)
          } else {
            console.log('Can not get token')
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
        })
    } else {
      console.log('Do not have permission!')
    }
  })
}

requestPermission()

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
