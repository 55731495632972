import React, { useState, useEffect } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'
import useTheme from '@/hooks/useTheme'
import styles from './styles.module.css'

function index({ isActivate, onChange, disabled }) {
  const [valueActive, setValueActive] = useState(false)
  const [labelActive, setLabelActive] = useState('ON')

  useEffect(() => {
    setValueActive(!!isActivate)
  }, [isActivate])

  useEffect(() => {
    const label = valueActive ? 'ON' : 'OFF'
    setLabelActive(label)
  }, [valueActive])

  return (
    <>
      <FormControlLabel
        control={
          <CustomSwitch
            sx={{ mr: 1 }}
            theme={{ colorChecked: useTheme().color }}
            checked={valueActive}
            onChange={onChange}
            disabled={disabled}
          />
        }
        label={labelActive}
        className={styles.labelSwicth}
      />
    </>
  )
}

export default index

const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 60,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '200ms',
    '&.Mui-checked': {
      transform: 'translateX(28px)',
      '& .MuiSwitch-thumb': {
        color: theme.colorChecked,
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#E7E7E7',
        opacity: 1,
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 1,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 28,
    height: 28,
    color: '#535353',
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    backgroundColor: '#E7E7E7',
    opacity: 1,
  },
}))
