import React, { useEffect, useState, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Box from '@mui/material/Box'
import styles from './benchmark.module.css'
import {
  DEFAULT_ITEMS_PER_PAGE_BENCHMARK,
  PERMISSION_VIEW,
  FORMAT_DATE_CAPITAL,
  SELF,
} from '@/utils/constant'
import { useNavigate } from 'react-router-dom'
import { ConfirmDialog, ReadMore } from '@/components'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory'
import { trashIcon } from '@/utils/images'
import { ReactSVG } from 'react-svg'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { benchmarkSelector, deleteBenchmark, setPages } from '@/redux/modules/benchmark'
import useTheme from '@/hooks/useTheme'

function BenchmarkTable({ data, title }) {
  const tableRef = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isProcessing, isDeleted, pages } = useSelector(benchmarkSelector)

  const [openDialog, setOpenDialog] = useState(false)
  const [benchmarkSelected, setBenchmarkSelected] = useState()

  const [first1, setFirst1] = useState(0)
  const [rows1, setRows1] = useState(DEFAULT_ITEMS_PER_PAGE_BENCHMARK)

  const handleDelete = () => {
    dispatch(deleteBenchmark({ benchmarkId: benchmarkSelected.benchmark_id, key: title }))
  }

  const openDialogConfirm = (val) => {
    setOpenDialog(true)
    setBenchmarkSelected(val)
  }

  const handlePageChange = (event) => {
    const { page, rows } = event
    dispatch(setPages({ title: title, currentPage: page + 1, rows: rows }))
  }

  useEffect(() => {
    if (isDeleted) setOpenDialog(false)
  }, [isDeleted])

  useEffect(() => {
    if (pages.length) {
      pages.map((page) => {
        if (page.title === title) {
          const first = page.currentPage ? page.rows * (page.currentPage - 1) : 0
          setFirst1(first)
          setRows1(page.rows)
        }
      })
    }
  }, [pages])

  if (!data.length) return null
  return (
    <div style={{ paddingBottom: '50px' }}>
      <img src={title === SELF ? useTheme().user1Icon : useTheme().users} />
      <label className={`color-theme ${styles.title}`}>
        {title === SELF ? '自分の調査' : '法人内の調査'}
      </label>
      <div className={styles.group}>
        <DataTable
          ref={tableRef}
          value={data}
          paginator={data.length > DEFAULT_ITEMS_PER_PAGE_BENCHMARK}
          rowClassName={`${styles.row} row-table space-between background-theme-hover`}
          tableClassName={styles.benchmarkTable}
          rowsPerPageOptions={[5, 10, 20, 30]}
          onPage={handlePageChange}
          first={first1}
          rows={rows1}
          sortField='created_time'
          sortOrder={-1}
        >
          <Column
            header='調査日'
            sortable
            style={{ width: 150 }}
            field='created_time'
            body={(val) => {
              return (
                <span>
                  {val.created_time ? moment(val.created_time).format(FORMAT_DATE_CAPITAL) : ''}
                </span>
              )
            }}
          ></Column>
          <Column
            field='title'
            sortable
            header='タイトル'
            body={({ title }) => {
              return <ReadMore>{title}</ReadMore>
            }}
          ></Column>
          <Column
            header='期間'
            body={(val) => {
              return (
                <span>
                  {val.from_date ? moment(val.from_date).format(FORMAT_DATE_CAPITAL) : ''}~
                  {val.from_date ? moment(val.to_date).format(FORMAT_DATE_CAPITAL) : ''}
                </span>
              )
            }}
            className={styles.colDates}
          ></Column>
          <Column
            header=''
            body={(val) => {
              return (
                <Box className={'actions'}>
                  <ul className={`bg-theme-hover-opacity justify-content-end ${styles.actions}`}>
                    <li onClick={() => navigate(`${val.benchmark_id}`)} className={styles.action}>
                      <Box className={`${styles.boxAction} ${styles.view}`}>
                        <ChangeHistoryIcon />
                        <p>詳細</p>
                      </Box>
                    </li>
                    <li
                      onClick={() => navigate(`add-new?copy=${val.benchmark_id}`)}
                      className={styles.action}
                    >
                      <Box className={`${styles.boxAction} ${styles.copy}`}>
                        <ContentCopyIcon />
                        <p>コピー</p>
                      </Box>
                    </li>
                    {title === SELF || val.share_permission != PERMISSION_VIEW ? (
                      <li onClick={() => openDialogConfirm(val)} className={styles.action}>
                        <Box className={`${styles.boxAction} ${styles.delete}`}>
                          <ReactSVG src={trashIcon} />
                          <p>削除</p>
                        </Box>
                      </li>
                    ) : null}
                  </ul>
                </Box>
              )
            }}
            className={styles.colActions}
          ></Column>
        </DataTable>
      </div>

      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        text='このベンチマーク調査を削除してもよろしいでしょうか？'
        onAgree={handleDelete}
        title='確認'
        isProcessing={isProcessing}
      />
    </div>
  )
}

export default BenchmarkTable
