import { USER_TOKEN, THEME_COLOR, BLUE, REFRESH_TOKEN, USER, FIREBASE_TOKEN } from './constant'

export const setUser = (value) => {
  localStorage.setItem(USER, JSON.stringify(value))
}

export const getUser = () => {
  const value = localStorage.getItem(USER)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setUserToken = (value) => {
  localStorage.setItem(USER_TOKEN, JSON.stringify(value))
}

export const getUserToken = () => {
  const value = localStorage.getItem(USER_TOKEN)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setThemeColor = (value) => {
  localStorage.setItem(THEME_COLOR, value)
}

export const getThemeColor = () => {
  return localStorage.getItem(THEME_COLOR) || BLUE
}

export const setRefreshToken = (value) => {
  localStorage.setItem(REFRESH_TOKEN, JSON.stringify(value))
}

export const getRefreshToken = () => {
  const value = localStorage.getItem(REFRESH_TOKEN)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setFirebaseToken = (value) => {
  localStorage.setItem(FIREBASE_TOKEN, JSON.stringify(value))
}

export const getFirebaseToken = () => {
  const value = localStorage.getItem(FIREBASE_TOKEN)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}
