import React, { useState, useEffect } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { ConfirmDialog } from '@/components'
import { ADMIN, REGISTERED, FORMAT_DATE_CAPITAL } from '@/utils/constant'
import { useDispatch, useSelector } from 'react-redux'
import { deleteMember, membersSelector } from '@/redux/modules/members'
import styles from './members.module.css'
import { getUser } from '@/utils/localData'
import { CONFIRM_DEACTIVATE_ACCOUNT } from '@/utils/message'
import moment from 'moment/moment'
import Button from '@mui/material/Button'
import { ReactSVG } from 'react-svg'
import { trashIcon } from '@/utils/images'
import CircularProgress from '@mui/material/CircularProgress'

function MemberRow({ data }) {
  const dispatch = useDispatch()
  const currentUser = getUser()
  const { isError, isSuccessfully } = useSelector(membersSelector)

  const [user, setUser] = useState()
  const [openDialog, setOpenDialog] = useState(false)
  const [processing, setProcessing] = useState(false)

  const onHandleDelete = () => {
    setProcessing(true)
    dispatch(
      deleteMember({
        userId: user.userId,
      })
    )
    setOpenDialog(false)
  }

  useEffect(() => {
    if (data) setUser(data)
  }, [data])

  useEffect(() => {
    setProcessing(false)
  }, [isError, isSuccessfully])

  if (!user) return null
  return (
    <>
      <TableRow hover role='checkbox' tabIndex={-1}>
        <TableCell
          className='truncate'
          align='center'
          sx={{ maxWidth: '130px' }}
        >{`${user.lastName}${user.firstName}`}</TableCell>
        <TableCell align='center'>{user.roleId === ADMIN ? '管理者' : '一般ユーザー'}</TableCell>
        <TableCell align='center'>
          {user.latestLoginTime ? moment(user.latestLoginTime).format(FORMAT_DATE_CAPITAL) : ''}
        </TableCell>
        <TableCell align='center' className='truncate' sx={{ maxWidth: '190px' }}>
          {user.email}
        </TableCell>
        <TableCell
          align='center'
          className={`${styles.status} ${
            user.status === REGISTERED ? 'color-theme' : styles.banned
          }`}
        >
          {Number(user.status) === REGISTERED ? '登録済み' : '登録待ち'}
        </TableCell>
        <TableCell align='center'>
          {currentUser.email !== user.email && (
            <Button
              variant='outlined'
              color='error'
              startIcon={
                !processing ? (
                  <ReactSVG src={trashIcon} />
                ) : (
                  <CircularProgress
                    variant='indeterminate'
                    disableShrink
                    size={20}
                    sx={{ color: '#e14646' }}
                  />
                )
              }
              className={styles.btnRemove}
              onClick={() => setOpenDialog(true)}
              disabled={processing}
              sx={{
                '&:disabled': {
                  color: '#e14646',
                  borderColor: '#e14646',
                },
              }}
            >
              削除
            </Button>
          )}
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        text={CONFIRM_DEACTIVATE_ACCOUNT}
        onAgree={onHandleDelete}
        title='アカウント削除の確認'
      />
    </>
  )
}

export default MemberRow
