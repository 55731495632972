import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import styles from './sharing.module.css'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import useTheme from '@/hooks/useTheme'
import { makeStyles } from '@mui/styles'
import { DELETE_PERMISSION, EDITOR, VIEWER, SHARE, SELECT_MEMBER } from '@/utils/message'
import { shareTopic, shareSelector, clearState } from '@/redux/modules/share'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { transformErrors } from '@/utils/errorHandling'
import { PERMISSION_OWNER } from '@/utils/constant'
import Skeleton from '@mui/material/Skeleton'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { matchSorter } from 'match-sorter'

const filterOptions = (options, { inputValue }) => {
  return matchSorter(options, inputValue, { keys: ['email', 'firstName', 'lastName'] })
}

function index({ show, handleClose, topicId, title = '' }) {
  const useStyles = makeStyles({
    option: {
      '&:hover': {
        backgroundColor: `${useTheme().color} !important`,
        color: 'white',
      },
      // Selected
      '&[aria-selected="true"]': {
        backgroundColor: `${useTheme().color} !important`,
        color: 'white',
      },
    },
    input: {
      padding: '0px 20px !important',
    },
  })

  const classes = useStyles()

  const StyledMenuPermission = {
    sx: {
      '& .MuiMenu-paper': {
        border: '1px solid #cecece',
        borderRadius: 0,
        overflow: 'auto',
        padding: '0px',
        boxShadow: 'none',
        '& .MuiList-root': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      '& .MuiMenuItem-root': {
        '&:first-of-type': {
          backgroundColor: 'transparent',
        },
        fontSize: '0.875rem',
        padding: '5px 10px',
        margin: '5px 0px',
      },
      '& .MuiMenuItem-root:hover': {
        backgroundColor: useTheme().color,
        color: '#fff',
      },
      '& .MuiMenuItem-root.Mui-selected': {
        backgroundColor: useTheme().color,
        color: '#fff',
        '&:hover': {
          backgroundColor: useTheme().color,
          color: '#fff',
        },
      },
    },
  }

  const dispatch = useDispatch()
  const {
    isSuccessfully,
    isError,
    errors,
    isProcessing,
    successMessage,
    sharedMembers,
    members,
    isFetchingMembers,
    isFetchingSharedMembers,
  } = useSelector(shareSelector)

  const [permission, setPermission] = useState(0)
  const [member, setMember] = useState('')

  const handleChange = (e) => {
    setPermission(e.target.value)
  }

  const onShare = () => {
    if (member.userId) {
      const data = {
        topic_id: topicId,
        share_permission: permission,
        member: member,
      }
      dispatch(shareTopic(data))
    } else {
      toast.error(SELECT_MEMBER)
    }
  }

  useEffect(() => {
    if (isError) {
      const errs = transformErrors(errors)
      errs.forEach((er) => toast.error(er.message))
      dispatch(clearState())
    }

    if (isSuccessfully) {
      toast.success(successMessage)
      dispatch(clearState())
      setMember('')
      setPermission(0)
    }
  }, [isError, isSuccessfully])

  return (
    <Dialog
      fullWidth
      open={show}
      onClose={handleClose}
      className={`${styles.sharingDialog} theme`}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            borderRadius: '15px',
            padding: '2.5rem',
          },
        },
      }}
    >
      <Box className={styles.boxInvite}>
        <DialogTitle className={`${styles.title} color-theme`}>
          {`「${title}」を共有する`}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={styles.dialogContent}>
          <Box className={styles.boxInviteInput}>
            <Box className={styles.boxAutocomplete}>
              <Autocomplete
                freeSolo
                options={members}
                classes={{
                  option: classes.option,
                  input: classes.input,
                }}
                disabled={isProcessing}
                getOptionLabel={(option) => {
                  return option.email || option
                }}
                renderOption={(props, option) => {
                  const { lastName, firstName } = option
                  return <span {...props}>{`${lastName}${firstName}`}</span>
                }}
                filterOptions={filterOptions}
                value={member}
                onChange={(e, val) => setMember(val)}
                loading={isFetchingMembers}
                renderInput={(params) => {
                  return (
                    <TextField
                      autoFocus
                      className={styles.inpInvite}
                      variant='standard'
                      fullWidth
                      placeholder='ユーザーを検索'
                      {...params}
                    />
                  )
                }}
              />
            </Box>
            <Box className={`${styles.boxSelect}`}>
              <FormControl variant='standard'>
                <Select
                  displayEmpty={true}
                  disabled={isProcessing}
                  value={permission}
                  onChange={handleChange}
                  IconComponent={(props) => (
                    <img {...props} src={useTheme().arrowDown} style={{ width: '15px' }} />
                  )}
                  input={<StyledInput />}
                  MenuProps={StyledMenuPermission}
                >
                  <MenuItem value={0} sx={{ borderBottom: 'none' }}>
                    {VIEWER}
                  </MenuItem>
                  <MenuItem value={1} sx={{ borderBottom: 'none' }}>
                    {EDITOR}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Button
              onClick={onShare}
              disabled={isProcessing || !member}
              className={`${styles.btnInvite} background-theme background-theme-hover`}
              variant='contained'
            >
              {SHARE}
            </Button>
          </Box>
        </DialogContent>
      </Box>
      <Box className={styles.boxSharingList}>
        <Box className={styles.boxTitle}>
          <DialogTitle className={`${styles.title} color-theme`}>
            現在共有されているユーザー：
          </DialogTitle>
        </Box>
        <DialogContent className={styles.boxSharingContent}>
          <List
            sx={{
              width: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
              maxHeight: '205px',
              paddingRight: '10px',
            }}
          >
            {!isFetchingSharedMembers ? (
              <>
                {sharedMembers
                  .filter((mem) => mem.sharePermission == PERMISSION_OWNER)
                  .map((member) => {
                    return (
                      <MemberShared
                        key={`member-shared-${member.userId}`}
                        member={member}
                        topicId={topicId}
                        styled={StyledMenuPermission}
                      />
                    )
                  })}
                {sharedMembers
                  .filter((mem) => mem.sharePermission != PERMISSION_OWNER)
                  .map((member) => {
                    return (
                      <MemberShared
                        key={`member-shared-${member.userId}`}
                        member={member}
                        topicId={topicId}
                        styled={StyledMenuPermission}
                      />
                    )
                  })}
              </>
            ) : (
              <>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
              </>
            )}
          </List>
        </DialogContent>
      </Box>
    </Dialog>
  )
}

const MemberShared = ({ member, topicId, styled }) => {
  const dispatch = useDispatch()
  const { lastName, firstName, email, sharePermission } = member

  const handleChange = (e) => {
    const data = {
      topic_id: topicId,
      share_permission: e.target.value,
      member: member,
    }
    dispatch(shareTopic(data))
  }

  return (
    <Box className={styles.boxMember}>
      <ListItem alignItems='flex-start'>
        <ListItemText className={styles.member}>{`${lastName}${firstName}`}</ListItemText>
        <ListItemText className={styles.member}>{`(${email})`}</ListItemText>
      </ListItem>
      <Box className={styles.empty}>
        {sharePermission != PERMISSION_OWNER ? (
          <Select
            displayEmpty={true}
            value={sharePermission}
            onChange={handleChange}
            IconComponent={(props) => (
              <img {...props} src={useTheme().arrowDown} style={{ width: '15px', top: '50%' }} />
            )}
            input={<StyledInput1 />}
            MenuProps={styled}
          >
            <MenuItem value={0} sx={{ borderBottom: 'none' }}>
              {VIEWER}
            </MenuItem>
            <MenuItem value={1} sx={{ borderBottom: 'none' }}>
              {EDITOR}
            </MenuItem>
            <MenuItem
              value={-1}
              sx={{ borderBottom: 'none', borderTop: '1px solid #cecece', color: '#e14646' }}
            >
              {DELETE_PERMISSION}
            </MenuItem>
          </Select>
        ) : (
          <label>オーナー</label>
        )}
      </Box>
    </Box>
  )
}

export default React.memo(index)

const StyledInput = styled(InputBase)(() => ({
  '&.MuiInputBase-root': {
    borderBottom: '1px solid #cecece',
    height: '65px',
    '& img': {
      top: '50%',
    },
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    fontSize: 16,
    padding: '10px 26px 0px 12px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}))

const StyledInput1 = styled(InputBase)(() => ({
  '&.MuiInputBase-root': {
    borderBottom: '1px solid #cecece',
    height: '100%',
    width: '100%',
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    fontSize: '1rem',
    padding: '10px 26px 0px 12px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}))
