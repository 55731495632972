import { SOMETHING_WENT_WRONG, REQUEST_TIMEOUT } from '@/utils/message'

export const catchError = (error, thunkAPI, signal) => {
  if (!error) {
    if (!signal?.aborted)
      return thunkAPI.rejectWithValue({ errors: { message: SOMETHING_WENT_WRONG } })
  }
  if (error?.errors?.errors) {
    return thunkAPI.rejectWithValue({ errors: e.errors.errors })
  }
  if (error?.code === 'ECONNABORTED') {
    return thunkAPI.rejectWithValue({ errors: { message: REQUEST_TIMEOUT } })
  }
  return thunkAPI.rejectWithValue({ errors: error.errors ? error.errors : error })
}

export const transformErrors = (errs) => {
  let errors = []
  for (const [key, value] of Object.entries(errs)) {
    if (value != 403) errors.push({ message: value, fieldName: key !== 'message' ? key : null })
  }
  return errors
}
