import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SearchBar } from '@/components'
import { useSelector, useDispatch } from 'react-redux'
import { chatQuestion, chatSelector, clearState } from '@/redux/modules/chat'
import toast from 'react-hot-toast'
import { containsOnlySpaces } from '@/utils/function'
import { QUESTION_EMPTY } from '@/utils/message'

function CreateQuestion() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { topic, isCreatingQuestion, createdQuestion } = useSelector(chatSelector)

  const [questionContent, setQuestionContent] = useState('')

  const onCreateQuestion = () => {
    if (!containsOnlySpaces(questionContent)) {
      dispatch(chatQuestion({ question: questionContent }))
    } else {
      toast.error(QUESTION_EMPTY)
    }
  }

  const keyPress = (e) => {
    if (e.keyCode == 13) onCreateQuestion()
  }

  useEffect(() => {
    dispatch(clearState())
  }, [])

  useEffect(() => {
    if (topic.topicId && createdQuestion) {
      navigate(`lib/${topic.topicId}`, {
        state: { questionTitle: questionContent, animation: true },
      })
    }
  }, [topic, createdQuestion])

  return (
    <SearchBar
      value={questionContent}
      onChange={(e) => setQuestionContent(e.target.value)}
      onKeyDown={keyPress}
      placeholder='何について知りたいですか？'
      isLoading={isCreatingQuestion}
      onClickButton={onCreateQuestion}
    />
  )
}

export default CreateQuestion
