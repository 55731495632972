export const TILE_CONFIRM_DISABLE_ACCOUNT = 'アカウント無効化・有効化の確認'
export const CONFIRM_DEACTIVATE_ACCOUNT = '指定したユーザーのアカウントを削除してもよろしいですか？'
export const CONFIRM_ACTIVE_ACCOUNT = '指定したユーザーのアカウントを有効化してもよろしいですか？'
export const DELETE_PERMISSION = 'アクセス削除'
export const EDITOR = '編集者'
export const VIEWER = '閲覧者'
export const SHARE = '共有'
export const SELECT_MEMBER = 'リストからユーザーを選択してください。'
export const NOT_FOUND = '検索結果が見つかりませんでした。'
export const ANSWER_NOT_FOUND =
  '質問が明確ではないようです。 知りたい特定の質問やトピックがある場合は、お気軽に聞いてください。喜んでお手伝いいたします。'
export const RESOURCE_NOT_FOUND = 'リソースが見つかりませんでした。'
export const TOPIC_NOT_EXIST = 'このトピックIDは存在していません。'
export const SOMETHING_WENT_WRONG = '何らかのエラーが発生しました。もう一度お試しください。'
export const QUESTION_EMPTY = '質問を入力してください。'
export const REQUEST_TIMEOUT = 'リクエストがタイムアウトになりました。'
export const BENCHMARK_NOT_EXIT = 'このベンチマークIDは存在していません。'
