import axiosClient from './axiosClient'

const benchmarkApi = {
  getAllBenchmark: (data) => {
    const url = '/benchmark/all'
    return axiosClient.get(url, { signal: data.signal })
  },
  getBenchmark: (data) => {
    const url = `/benchmark/${data.benchmarkId}`
    return axiosClient.get(url, { signal: data.signal })
  },
  getBenchmarkWithLinks: (data) => {
    const url = `/benchmark/link/${data.benchmarkId}`
    return axiosClient.get(url, { signal: data.signal })
  },
  deleteBenchmark: (data) => {
    const url = `/benchmark/${data.benchmarkId}`
    return axiosClient.delete(url)
  },
  createBenchmark: async (data) => {
    const url = '/benchmark'
    return axiosClient.post(url, data)
  },
  editBenchmark: async (data) => {
    const url = '/benchmark'
    return axiosClient.post(url, data)
  },
  getBenchmarkNextLink: async (data) => {
    const url = `/benchmark/get_next_benchmark_link/${data.benchmarkId}/${data.benchmark_link_id}`
    return axiosClient.get(url, { signal: data.signal })
  },
  shareBenchmark: (data) => {
    const url = '/benchmark/share'
    return axiosClient.post(url, data)
  },
  getMembersToShare: async (data) => {
    const url = '/user/to-share-benchmark'
    return axiosClient.get(url, { params: data })
  },
  getMembersShared: async (data) => {
    const url = '/user/shared-benchmark'
    return axiosClient.get(url, { params: data })
  },
  getSharedBenchmarks: async (data) => {
    const url = '/benchmark/list_shared_benchmarks'
    return axiosClient.get(url, { signal: data.signal })
  },
  stopBenchmark: (data) => {
    const url = '/benchmark/stop_generate'
    return axiosClient.post(url, data)
  },
  resumeBenchmark: (data) => {
    const url = '/benchmark/resume_generate'
    return axiosClient.post(url, data)
  },
  refreshBenchmark: (data) => {
    const url = '/benchmark/regenerate'
    return axiosClient.post(url, data)
  },
}

export default benchmarkApi
