import { radioButton } from '@/utils/images'
import { GREEN_ICONS, PURPLE_ICONS, BLUE_ICONS } from '@/utils/themeIcons'
import { BENCH_MARK_BUSINESS, BENCH_MARK_KEYWORDS } from '@/utils/constant'

export const notifications = (checkedIcon) => {
  return [
    {
      id: 1,
      label: '受け取る',
      value: 1,
      icon: radioButton,
      checkedIcon: checkedIcon,
    },
    {
      id: 2,
      label: '受け取らない',
      value: 0,
      icon: radioButton,
      checkedIcon: checkedIcon,
    },
  ]
}

export const themes = () => {
  return [
    {
      id: 4,
      label: 'BLUE',
      value: 0,
      icon: radioButton,
      checkedIcon: BLUE_ICONS.radioChecked,
    },
    {
      id: 5,
      label: 'GREEN',
      value: 1,
      icon: radioButton,
      checkedIcon: GREEN_ICONS.radioChecked,
    },
    {
      id: 6,
      label: 'PURPLE',
      value: 2,
      icon: radioButton,
      checkedIcon: PURPLE_ICONS.radioChecked,
    },
  ]
}

export const typesBenchmark = (checkedIcon) => {
  return [
    {
      id: 1,
      label: '企業調査',
      value: BENCH_MARK_BUSINESS,
      icon: radioButton,
      checkedIcon: checkedIcon,
    },
    {
      id: 2,
      label: 'キーワード調査',
      value: BENCH_MARK_KEYWORDS,
      icon: radioButton,
      checkedIcon: checkedIcon,
    },
  ]
}
