import React from 'react'
import styles from './home.module.css'
import { ReactSVG } from 'react-svg'
import { logo3 } from '@/utils/images'
function Greeting() {
  return (
    <div className={styles.greeting}>
      <ReactSVG className={styles.logo} src={logo3} />
    </div>
  )
}

export default React.memo(Greeting)
