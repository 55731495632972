import { configureStore } from '@reduxjs/toolkit'
import { userSlice } from '@/redux/modules/user'
import { membersSlice } from '@/redux/modules/members'
import { chatSlice } from '@/redux/modules/chat'
import { shareSlice } from '@/redux/modules/share'
import { benchmarkSlice } from '@/redux/modules/benchmark'

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    members: membersSlice.reducer,
    chat: chatSlice.reducer,
    share: shareSlice.reducer,
    benchmark: benchmarkSlice.reducer,
  },
})
