import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import {
  ACTIONS,
  PERMISSION_VIEW,
  FORMAT_DATE_CAPITAL,
  BENCH_MARK_BUSINESS,
} from '@/utils/constant'
import { ConfirmDialog, Loading } from '@/components'
import { onDownloadBenchmark } from '@/utils/function'
import { ReactSVG } from 'react-svg'
import { searchAlt2Icon } from '@/utils/images'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import {
  getBenchmarkWithNextLink,
  clearBenchmark,
  benchmarkSelector,
  getMembersToShare,
  getSharedMembers,
  getBenchmarkWithLinks,
  deleteBenchmark,
  clearState,
  resumeBenchmark,
  refreshBenchmark,
  stopBenchmark,
} from '@/redux/modules/benchmark'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment/moment'
import CircularProgress from '@mui/material/CircularProgress'
import { NOT_FOUND } from '@/utils/message'
import styles from './benchmark.module.css'
import Sharing from './Sharing'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { transformErrors } from '@/utils/errorHandling'
import { BENCHMARK_NOT_EXIT } from '@/utils/message'
import toast from 'react-hot-toast'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CachedIcon from '@mui/icons-material/Cached'
import Backdrop from '@mui/material/Backdrop'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import ReactRouterPrompt from 'react-router-prompt'

const Actions = ({ data }) => {
  const dispatch = useDispatch()
  const { isProcessing } = useSelector(benchmarkSelector)

  const [showSharingDialog, setShowSharingDialog] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const onToggleSharingDialog = (e) => {
    setShowSharingDialog(!showSharingDialog)
  }

  const handleCloseSharingDialog = (e) => {
    e.stopPropagation()
    setShowSharingDialog(false)
  }

  const handleDelete = () => {
    dispatch(deleteBenchmark({ benchmarkId: data.benchmark_id, key: '' }))
  }

  useEffect(() => {
    if (showSharingDialog) {
      dispatch(getMembersToShare({ benchmark_id: data.benchmark_id }))
      dispatch(getSharedMembers({ benchmark_id: data.benchmark_id }))
    }
  }, [showSharingDialog])

  return (
    <Box className='box-actions'>
      <ul className='bg-theme-hover-opacity'>
        {data.share_permission != PERMISSION_VIEW && (
          <li onClick={onToggleSharingDialog} className={`action`}>
            <Box className={`box-action`}>
              <img src={ACTIONS[0].icon} />
              <p>共有</p>
            </Box>
          </li>
        )}
        <li onClick={() => onDownloadBenchmark(data)} className={`action`}>
          <Box className={`box-action`}>
            <img src={ACTIONS[1].icon} />
            <p>出力</p>
          </Box>
        </li>
        {data.share_permission != PERMISSION_VIEW && (
          <li onClick={() => setOpenDialog(true)} className={`action`}>
            <Box className={`box-action`}>
              <img src={ACTIONS[2].icon} />
              <p>削除</p>
            </Box>
          </li>
        )}
      </ul>
      <Sharing
        show={showSharingDialog}
        handleClose={handleCloseSharingDialog}
        id={data.benchmark_id}
        title={data.title}
      />
      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        text='このベンチマーク調査を削除してもよろしいでしょうか？'
        onAgree={handleDelete}
        title='確認'
        isProcessing={isProcessing}
      />
    </Box>
  )
}

function BenchmarkDetail() {
  const controller = new AbortController()
  const signal = controller.signal

  const { benchmarkId } = useParams()
  const navigate = useNavigate()
  const { state, pathname } = useLocation()
  const [stopGenerate, setStopGenerate] = useState(false)

  const dispatch = useDispatch()
  const {
    benchmark,
    isError,
    errors,
    isSuccessfully,
    successMessage,
    isDeleted,
    isFetchingWithLink,
    isProcessing,
    errorStop,
  } = useSelector(benchmarkSelector)

  const onRefreshBenchmark = () => {
    dispatch(refreshBenchmark({ benchmark_id: benchmarkId }))
  }

  const onStopBenchmark = () => {
    const lastBenchmark = benchmark.links[benchmark.links.length - 1]
    if (lastBenchmark)
      dispatch(stopBenchmark({ benchmark_link_id: lastBenchmark.benchmark_link_id }))
  }

  const onResumeBenchmark = () => {
    dispatch(resumeBenchmark({ benchmark_id: benchmarkId }))
    setStopGenerate(false)
  }

  const onBack = () => {
    if (pathname.includes('shared')) {
      navigate('/shared')
    } else {
      navigate('/benchmark')
    }
  }

  useEffect(() => {
    if (!state) {
      dispatch(getBenchmarkWithLinks({ benchmarkId: benchmarkId, signal: signal }))
    } else {
      window.history.replaceState({}, '')
    }

    return () => {
      controller.abort()
      dispatch(clearBenchmark())
    }
  }, [])

  useEffect(() => {
    if (!benchmark.finished && benchmark?.benchmark_id && !stopGenerate) {
      const lastBenchmark = benchmark.links[benchmark.links.length - 1]
      dispatch(
        getBenchmarkWithNextLink({
          benchmarkId: benchmark.benchmark_id,
          benchmark_link_id: lastBenchmark ? lastBenchmark.benchmark_link_id : 0,
          signal: signal,
        })
      )
    }

    if (stopGenerate) {
      controller.abort()
      onStopBenchmark()
    }
  }, [benchmark, stopGenerate])

  useEffect(() => {
    if (isError) {
      const errs = transformErrors(errors)
      errs.forEach((er) => {
        if (er.message === BENCHMARK_NOT_EXIT) {
          onBack()
        }
        if (er.fieldName !== 'status') toast.error(er.message)
      })
    }
    if (isSuccessfully && successMessage) {
      toast.success(successMessage)
      if (isDeleted) onBack()
    }
    dispatch(clearState())
  }, [isError, isSuccessfully, isDeleted])

  useEffect(() => {
    if (errorStop) {
      setStopGenerate(false)
    }
  }, [errorStop])

  if (isFetchingWithLink) return <Loading />
  return (
    <Box className={`${styles.wrapper}`}>
      <ReactRouterPrompt when={stopGenerate}>
        {({ isActive, onConfirm, onCancel }) => (
          <ConfirmDialog
            open={isActive}
            title='確認'
            text='戻ることができなくなりますが、本当に終了しますか？'
            onAgree={onConfirm}
            onClose={onCancel}
          />
        )}
      </ReactRouterPrompt>
      <Button
        onClick={onBack}
        variant='outlined'
        startIcon={<ArrowBackIcon />}
        className={`${styles.backButton} border-bottom-theme color-theme`}
      >
        <span>戻る</span>
      </Button>
      {benchmark?.benchmark_id && <Actions data={benchmark} />}
      <Box className={styles.benchmarkContainer}>
        <Box className={`${styles.titleContainer} border-bottom-theme-2`}>
          <ReactSVG src={searchAlt2Icon} />
          <p className={`${styles.titleContent} ${'color-theme'}`}>{benchmark.title}</p>
        </Box>
        <Box className={styles.dateRangeContainer}>
          <label>期間：</label>
          <label>{`${
            benchmark.from_date ? moment(benchmark.from_date).format(FORMAT_DATE_CAPITAL) : 'N/A'
          }~${
            benchmark.to_date ? moment(benchmark.to_date).format(FORMAT_DATE_CAPITAL) : 'N/A'
          }`}</label>
        </Box>
        <Box>
          <DataTable
            value={benchmark.links}
            tableStyle={{ minWidth: '50rem' }}
            rowClassName={`${styles.row} ${styles.noHover} row-table no-hover space-between`}
            tableClassName={styles.benchmarkTable}
            emptyMessage={NOT_FOUND}
          >
            <Column
              field='publish_date'
              header='記事投稿日'
              className={styles.colDate}
              body={(val) => {
                return (
                  <p className='mb-0 '>
                    {val?.publish_date ? moment(val?.publish_date).format(FORMAT_DATE_CAPITAL) : ''}
                  </p>
                )
              }}
            ></Column>
            <Column
              header='記事タイトル'
              className={styles.colTitle}
              body={(val) => {
                return (
                  <Stack className={styles.colTitle}>
                    <p className='mb-0 truncate'>{val?.title}</p>
                    <a
                      href={val?.link}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='mb-0 truncate color-theme'
                    >
                      {val?.link}
                    </a>
                  </Stack>
                )
              }}
            ></Column>
            <Column
              header={Number(benchmark?.pattern) === BENCH_MARK_BUSINESS ? '企業名' : 'キーワード'}
              className={styles.colKeywords}
              body={(val) => {
                return (
                  <Stack className={styles.colKeywords}>
                    {val?.search_keywords.map((word, index) => {
                      return (
                        <span key={`w-${index}`} className={`${styles.keyword}`}>
                          {word}
                        </span>
                      )
                    })}
                  </Stack>
                )
              }}
            ></Column>
            <Column field='summary' header='要約' className={styles.colSummary}></Column>
          </DataTable>
          {benchmark.finished ? (
            benchmark.share_permission !== PERMISSION_VIEW && (
              <Box className={styles.bottomButtons}>
                <Button
                  className={`${styles.btnAddBenchmark} background-theme background-theme-hover`}
                  variant='contained'
                  onClick={() => navigate('edit')}
                >
                  再調査
                </Button>
                <Button
                  className={`${styles.btnAddBenchmark} border-bottom-theme color-theme`}
                  variant='outlined'
                  startIcon={<CachedIcon />}
                  disabled={isProcessing}
                  onClick={onRefreshBenchmark}
                >
                  再生成します
                </Button>
              </Box>
            )
          ) : (
            <Box className={`${styles.bottomButtons} flex-column`}>
              {stopGenerate ? (
                <Stack className={styles.stackStopResponsing}>
                  <Button
                    className={`${styles.btnAddBenchmark} ${styles.stopButton}`}
                    variant='contained'
                    disabled={isProcessing}
                    startIcon={<RestartAltIcon size={23} />}
                    onClick={onResumeBenchmark}
                  >
                    生成を続ける
                  </Button>
                </Stack>
              ) : (
                <Stack className={styles.stackStop}>
                  <CircularProgress color='inherit' />
                  <Stack className={styles.stackStopResponsing}>
                    <Button
                      className={`${styles.btnAddBenchmark} ${styles.stopButton}`}
                      variant='contained'
                      disabled={isProcessing}
                      startIcon={<StopCircleIcon />}
                      onClick={() => setStopGenerate(true)}
                    >
                      停止します
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {isProcessing && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </Box>
  )
}

export default BenchmarkDetail
