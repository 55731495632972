import React from 'react'
import { Link } from 'react-router-dom'
import { notFound404 } from '@/utils/images'

function NotFound() {
  return (
    <div className='not-found'>
      <img src={notFound404} alt='not-found' />
      <Link to='/' className='link-home'>
        ホームに戻る
      </Link>
    </div>
  )
}

export default NotFound
