import axiosClient from './axiosClient'

const authApi = {
  login: (data) => {
    const url = '/auth/login'
    return axiosClient.post(url, data)
  },
  logout: () => {
    const url = '/auth/logout'
    return axiosClient.post(url)
  },
  forgotPassword: (data) => {
    const url = '/auth/forgot-password'
    return axiosClient.post(url, data)
  },
  checkPassToken: (data) => {
    const url = '/auth/check-pass-token'
    return axiosClient.get(url, { params: data })
  },
  resetPassword: (data) => {
    const url = '/auth/reset-password'
    return axiosClient.put(url, data)
  },
}

export default authApi
