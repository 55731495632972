import { init } from '@sentry/react'
import * as Sentry from '@sentry/browser'

export function configureSentry() {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
  })
}
