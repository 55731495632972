import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { requestPermission, onMessageListener } from '@/firebase'
import { useDispatch } from 'react-redux'
import { getUnreadTopic } from '@/redux/modules/share'

const Notification = () => {
  const dispatch = useDispatch()
  const [notification, setNotification] = useState({ title: '', body: '' })
  const notify = () => toast(<ToastDisplay />)

  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    )
  }

  useEffect(() => {
    const channel = new BroadcastChannel('notifications')
    channel.addEventListener('message', (event) => {
      dispatch(getUnreadTopic())
    })
  }, [])

  useEffect(() => {
    if (notification?.title) {
      notify()
    }
  }, [notification])

  requestPermission()

  onMessageListener()
    .then((payload) => {
      dispatch(getUnreadTopic())
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body })
    })
    .catch((err) => console.log('failed: ', err))

  return <Toaster />
}

export default Notification
