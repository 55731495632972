import React, { useRef, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Divider } from '@mui/material'
import { getUser } from '@/utils/localData'
import {
  logo2,
  plusLargeIcon,
  filesIcon,
  circleQuestionIcon,
  userSquareIcon,
  menuDotsIcon,
  userIcon,
  keyIcon,
  codeForkIcon,
  logoutIcon,
  inboxAltIcon,
  benchmarkMenuIcon,
} from '@/utils/images'
import { styled, alpha } from '@mui/material/styles'
import useTheme from '@/hooks/useTheme'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser, userSelector } from '@/redux/modules/user'
import { ADMIN } from '@/utils/constant'
import { ReactSVG } from 'react-svg'
import MenuIcon from '@mui/icons-material/Menu'
import Button from '@mui/material/Button'
import { useWindowSize } from '@uidotdev/usehooks'
import { shareSelector } from '@/redux/modules/share'
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import styles from './sidebar.module.css'
import CloseIcon from '@mui/icons-material/Close'
import useWindowDimensions from '@/hooks/useWindowDimensions'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 249,
    borderRadius: 0,
    backgroundColor: '#fafafa',
    '& .MuiList-root': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      padding: '6px 17px',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

function index() {
  const size = useWindowSize()
  const sidebarRef = useRef()
  const currentUser = getUser()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const { isProcessing } = useSelector(userSelector)
  const { unreadCountTopic } = useSelector(shareSelector)

  const [anchorEl, setAnchorEl] = useState(null)
  const [openSidebar, setOpenSidebar] = useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(logoutUser())
  }

  const handleClickOutside = (event) => {
    if (size.width <= 1100) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebar(false)
      }
    }
  }

  const closeSidebar = () => {
    if (size.width <= 1100) setOpenSidebar(false)
  }

  useEffect(() => {
    if (size.width <= 1100) {
      setOpenSidebar(false)
    } else {
      setOpenSidebar(true)
    }
  }, [size])

  useEffect(() => {
    if (openSidebar) {
      sidebarRef.current.style.left = '0'
    } else {
      sidebarRef.current.style.left = '-100%'
    }
  }, [openSidebar])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [sidebarRef, size])

  return (
    <>
      <Button onClick={() => setOpenSidebar(!openSidebar)} className={styles.hamburger}>
        <MenuIcon />
      </Button>
      <div
        ref={sidebarRef}
        className={`${styles.sideBar} ${styles.left__sideBar} background-theme `}
      >
        <div className={styles.menuWrapper}>
          <Link to='/top' onClick={closeSidebar} className={styles.logo}>
            <img src={logo2} />
            {width <= 1100 && (
              <CloseIcon
                sx={{ position: 'absolute', top: '10px', right: '10px', fontSize: '30px' }}
                onClick={() => setOpenSidebar(false)}
              />
            )}
          </Link>
          <ul className={styles.menu}>
            <li className={pathname.includes('/top') ? styles.active : ''}>
              <Link to='/top' onClick={closeSidebar}>
                <ReactSVG src={plusLargeIcon} />
                <span>検索TOP</span>
              </Link>
            </li>
            <li
              className={
                pathname === '/library' || pathname.includes('search') ? styles.active : ''
              }
            >
              <Link to='/library' onClick={closeSidebar}>
                <ReactSVG src={filesIcon} />
                <span>ライブラリ</span>
              </Link>
            </li>
            <li className={pathname.includes('benchmark') ? styles.active : ''}>
              <Link to='/benchmark' onClick={closeSidebar}>
                <ReactSVG
                  src={benchmarkMenuIcon}
                  beforeInjection={(svg) => {
                    svg.classList.add(styles.customIcon)
                    svg.setAttribute('style', '')
                  }}
                />
                <span>ベンチマーク調査</span>
              </Link>
            </li>
            <li className={pathname.includes('/shared') ? styles.active : ''}>
              <Link to='/shared' onClick={closeSidebar}>
                {unreadCountTopic > 0 && <div className={styles.badge} />}
                <ReactSVG src={inboxAltIcon} />
                <span>共有リンク</span>
              </Link>
            </li>

            <li>
              <a
                href='https://trail-coneflower-3b0.notion.site/InFolio-FAQ-80da477d21d44ff687be27025faa7a8e'
                target='_blank'
                rel='noreferrer'
                className={styles.link}
              >
                <ReactSVG src={circleQuestionIcon} />
                <span>FAQ (よくある質問)</span>
              </a>
            </li>
          </ul>
        </div>

        <div
          onClick={handleClick}
          className={`${styles.settingAccount} ${open ? styles.menuActive : ''}`}
        >
          <img src={userSquareIcon} />
          <label className={`${styles.fullName} truncate`}>{`${
            currentUser.lastName && currentUser.firstName
              ? `${currentUser.lastName}${currentUser.firstName}`
              : ''
          }`}</label>
          <img src={menuDotsIcon} />
        </div>
        <StyledMenu
          id='sidebar-positioned-menu'
          aria-labelledby='sidebar-positioned-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className={`${styles.customMenu} theme`}
        >
          <Option
            text='プロフィール設定'
            path='/setting'
            icon={pathname === '/setting' ? useTheme().user1Icon : userIcon}
            classes={pathname === '/setting' ? 'color-theme' : styles.menuItem}
            onClose={handleClose}
          />
          <Option
            text='パスワード変更'
            path='/change-password'
            icon={pathname === '/change-password' ? useTheme().keyIcon : keyIcon}
            classes={pathname === '/change-password' ? 'color-theme' : styles.menuItem}
            onClose={handleClose}
          />
          {currentUser && currentUser?.roleId == ADMIN && (
            <Option
              text='ユーザー管理設定'
              path='/members'
              icon={pathname === '/members' ? useTheme().codeForkIcon : codeForkIcon}
              classes={pathname === '/members' ? 'color-theme' : styles.menuItem}
              onClose={handleClose}
            />
          )}
          <OutOption
            path='https://docs.google.com/forms/d/e/1FAIpQLScTYGwt2mHgFulOPHD0sPkXafX0Gzqs6LamOsblgFQA-ukNeA/viewform'
            icon={<BugReportOutlinedIcon />}
            text='バグ報告フォーム'
          />
          <OutOption
            path='https://docs.google.com/forms/d/e/1FAIpQLSeGG7Cqe9WIeegkDEfLyWup0vw-M5QgKb3SkuOV4REARTzUnw/viewform'
            icon={<FlagOutlinedIcon />}
            text='機能要望フォーム'
          />
          <Divider className={styles.line} />
          <MenuItem disabled={isProcessing} onClick={handleLogout}>
            <span className={`${styles.linkMenu} ${styles.menuItem}`} style={{ color: '#e14646' }}>
              <img src={logoutIcon} />
              ログアウト
            </span>
          </MenuItem>
        </StyledMenu>
      </div>
    </>
  )
}

const Option = ({ onClose, path = '/', text, icon, classes }) => {
  return (
    <MenuItem onClick={onClose}>
      <Link to={path} className={`${styles.linkMenu} ${classes}`}>
        <img src={icon} />
        {text}
      </Link>
    </MenuItem>
  )
}

const OutOption = ({ path = '', text, icon }) => {
  return (
    <MenuItem>
      <a
        href={path}
        target='_blank'
        rel='noreferrer'
        className={`${styles.linkMenu} ${styles.outLink}`}
      >
        {icon}
        {text}
      </a>
    </MenuItem>
  )
}

export default React.memo(index)
