import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Sidebar } from '@/components'
import styles from './layout.module.css'
import { useDispatch } from 'react-redux'
import { getUnreadTopic } from '@/redux/modules/share'

function AppLayout() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUnreadTopic())
  }, [])

  return (
    <Box className='theme'>
      <Sidebar />
      <Box className={styles.appLayout}>
        <Outlet />
      </Box>
    </Box>
  )
}

export default AppLayout
