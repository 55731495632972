import React from 'react'
import { Link } from 'react-router-dom'
import Container from '@mui/material/Container'
import { finchLogo, logoFooter } from '@/utils/images'
import styles from './footer.module.css'

function index() {
  return (
    <footer className={styles.footer}>
      <Container maxWidth='false' className={`wrapper w-100 ${styles.container}`}>
        <div className={styles.footerLogo}>
          <img src={logoFooter} />
        </div>
        <div className={styles.footerNav}>
          <div>
            <ul>
              <li className='scroll--what'>
                <Link to='/'>What’s InFolio?</Link>
              </li>
              <li className='scroll--news-detail'>
                <Link to='/'>News</Link>
              </li>
              <li>
                <Link
                  target='_blank'
                  to='https://trail-coneflower-3b0.notion.site/InFolio-FAQ-80da477d21d44ff687be27025faa7a8e'
                >
                  FAQ
                </Link>
              </li>
              {/* <li className='scroll--contact'>
                <Link to='/'>Contact Us</Link>
              </li> */}
              <li>
                <Link target='_blank' to='https://company.finchjapan.co.jp/'>
                  運営会社（フィンチジャパン）
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <Link to='/login'>利用者ページ（サインイン）</Link>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <Link to='/law'>特定商取引に関する法律</Link>
              </li>
              <li>
                <Link to='/privacy-policy'>プライバシーポリシー</Link>
              </li>
              <li>
                <Link to='/terms-of-service'>サービス利用規約</Link>
              </li>
            </ul>
          </div>
          <div>
            <img className={styles.logoFinch} src={finchLogo} />
            <p className={styles.copyright}>©Finchjapan, 2024. All rights reserved.</p>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default React.memo(index)
