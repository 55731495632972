import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import chatApi from '@/api/chat'
import { getUser } from '@/utils/localData'
import { catchError } from '@/utils/errorHandling'

export const chatQuestion = createAsyncThunk('chat/question', async (data, thunkAPI) => {
  try {
    const res = await chatApi.chatQuestion(data)
    if (!res.errors) {
      return res
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const getAnswer = createAsyncThunk('chat/answer', async (data, thunkAPI) => {
  try {
    let res = await chatApi.chatAnswer(data)
    if (!res.errors) {
      return { ...res, ...data }
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const getAllTopic = createAsyncThunk('chat/all-topic', async (data, thunkAPI) => {
  try {
    let res = await chatApi.getAllTopic(data)
    if (!res.errors) {
      return res
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const getTopic = createAsyncThunk('chat/topic', async (data, thunkAPI) => {
  try {
    let res = await chatApi.getTopic(data)
    if (!res.errors) {
      return res
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const deleteTopic = createAsyncThunk('chat/topic-delete', async (data, thunkAPI) => {
  try {
    let res = await chatApi.deleteTopic({ topicId: data.topicId })
    if (!res.errors) {
      return { ...res, topicId: data.topicId, key: data.key }
    }
  } catch (e) {
    return catchError(e, thunkAPI, data.signal)
  }
})

export const setPages = createAsyncThunk('chat/chage-page', async (data) => {
  return data
})

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    allTopic: null,
    topic: { messages: [] },
    isError: false,
    errors: {},
    isFetching: false,
    isFetchingSources: false,
    isFetchingAnswer: false,
    isCreatingQuestion: false,
    isProcessing: false,
    isSuccessfully: false,
    alertMessage: '',
    currentQuestion: null,
    createdQuestion: false,
    pages: [],
  },
  reducers: {
    clearState: (state) => {
      state.isError = false
      state.errors = {}
      state.isFetching = false
      state.isCreatingQuestion = false
      state.isProcessing = false
      state.isSuccessfully = false
      state.alertMessage = ''
      state.createdQuestion = false
      return state
    },
    clearCurrentQuestion: (state) => {
      state.currentQuestion = null
      return state
    },
    clearTopic: (state) => {
      state.topic = { messages: [] }
      return state
    },
  },
  extraReducers: {
    [chatQuestion.fulfilled]: (state, { payload }) => {
      const currentUser = getUser()
      state.topic.messages.forEach((element) => {
        if (element.question_id !== payload.question_id) delete element.isCurrent
      })
      const { topic_id, share_permission, created_by, created_time, messages } = payload
      const newMessage = {
        ...messages[0],
        created_first_name: currentUser.firstName,
        created_last_name: currentUser.lastName,
        isCurrent: true,
        finished: false,
        index: 0,
      }
      state.topic = {
        ...state.topic,
        topicId: topic_id,
        share_permission: share_permission,
        created_by: created_by,
        created_time: created_time,
        messages: [...state.topic.messages, { ...newMessage }],
      }
      state.isFetchingSources = false
      state.currentQuestion = { ...newMessage }
      state.createdQuestion = true
      return state
    },
    [chatQuestion.rejected]: (state, { payload }) => {
      state.topic.messages.forEach((element) => {
        if (element.question_id !== payload.question_id) delete element.isCurrent
      })
      state.isError = true
      state.isFetchingSources = false
      state.isCreatingQuestion = false
      state.errors = payload.errors
    },
    [chatQuestion.pending]: (state) => {
      state.isFetchingSources = true
      state.isCreatingQuestion = true
    },
    [getAnswer.fulfilled]: (state, { payload }) => {
      const { messages } = state.topic
      const index = messages.findIndex((mess) => mess.question_id === payload.question_id)
      messages[index] = {
        ...messages[index],
        answer: messages[index].answer
          ? `${messages[index].answer}${payload.answer}`
          : payload.answer,
      }
      state.currentQuestion = {
        ...messages[index],
        answer: payload.answer,
        finished: payload.finished,
        index: payload.idx + 1,
      }
      state.topic = { ...state.topic, message: messages }
      state.isFetchingAnswer = false
      state.isCreatingQuestion = false
      state.createdQuestion = false
      return state
    },
    [getAnswer.rejected]: (state, { payload }) => {
      state.isError = true
      state.isFetchingAnswer = false
      state.isCreatingQuestion = false
      state.errors = payload.errors
      state.createdQuestion = false
    },
    [getAnswer.pending]: (state) => {
      state.isFetchingAnswer = true
      state.createdQuestion = false
    },
    [getAllTopic.fulfilled]: (state, { payload }) => {
      state.allTopic = payload
      state.isFetching = false
      return state
    },
    [getAllTopic.rejected]: (state, { payload }) => {
      if (payload) {
        state.isError = true
        state.errors = payload.errors
      }
      state.isFetching = false
    },
    [getAllTopic.pending]: (state) => {
      state.isFetching = true
    },
    [getTopic.fulfilled]: (state, { payload }) => {
      state.topic = payload
      state.isFetching = false
      return state
    },
    [getTopic.rejected]: (state, { payload }) => {
      if (payload) {
        state.isError = true
        state.errors = payload.errors
      }
      state.isFetching = false
    },
    [getTopic.pending]: (state) => {
      state.isFetching = true
    },
    [deleteTopic.fulfilled]: (state, { payload }) => {
      if (payload.key) {
        const { key, topicId } = payload
        let allTopic = state.allTopic[key].filter((topic) => topic.topic_id !== topicId)
        state.allTopic[key] = allTopic
      }
      state.isProcessing = false
      state.isSuccessfully = true
      state.alertMessage = payload.messages
      return state
    },
    [deleteTopic.rejected]: (state, { payload }) => {
      state.isError = true
      state.errors = payload.errors
    },
    [deleteTopic.pending]: (state) => {
      state.isProcessing = true
    },
    [setPages.fulfilled]: (state, { payload }) => {
      const index = state.pages.findIndex((page) => page.title === payload.title)
      if (index > -1) {
        state.pages[index] = payload
      } else {
        state.pages.push(payload)
      }
      return state
    },
  },
})

export const { clearState, clearCurrentQuestion, clearTopic } = chatSlice.actions

export const chatSelector = (state) => state.chat
