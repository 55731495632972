import React, { useState, useRef, useEffect } from 'react'
import {
  FormLayout,
  DatePickerWithMonthYearDropdown,
  CustomInput,
  InputWithLabel,
  Label,
  RadioGroupButton,
} from '@/components'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import styles from './benchmark.module.css'
import InputAdornment from '@mui/material/InputAdornment'
import { getDomain, isValidUrl } from '@/utils/helper'
import { BENCH_MARK_BUSINESS } from '@/utils/constant'
import { plusLargeIcon } from '@/utils/images'
import { ReactSVG } from 'react-svg'
import { useSearchParams, useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  getBenchmark,
  createBenchmark,
  clearState,
  benchmarkSelector,
  clearBenchmarkCopy,
  editBenchmark,
} from '@/redux/modules/benchmark'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment/moment'
import toast from 'react-hot-toast'
import { typesBenchmark } from '@/utils/generateOptions'
import useTheme from '@/hooks/useTheme'

function AddEdit() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const benchmarkCopy = searchParams.get('copy')
  const { benchmarkId } = useParams()

  const dispatch = useDispatch()
  const { isSuccessfully, isProcessing, benchmark, errors, benchmarkToCopy, gettingCopy, isError } =
    useSelector(benchmarkSelector)

  const [error, setError] = useState(null)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const [site, setSite] = useState('')
  const [sites, setSites] = useState([])
  const [keyword, setKeyword] = useState('')
  const [keywords, setKeywords] = useState([])
  const [title, setTitle] = useState('')
  const [pattern, setPattern] = useState(BENCH_MARK_BUSINESS)

  const titleRef = useRef(null)
  const dateRangeRef = useRef(null)
  const siteRef = useRef(null)
  const keywordRef = useRef(null)

  const optionsBenchmark = typesBenchmark(useTheme().radioChecked)

  const onAddNewSite = () => {
    if (!isValidUrl(site.trim())) {
      siteRef?.current?.focus()
      setError((prevstate) => ({ ...prevstate, sites: 'URLが不正です。' }))
    } else {
      setSites((prevState) => [getDomain(site.trim()), ...prevState])
      setSite('')
      setError((prevstate) => ({ ...prevstate, sites: '' }))
    }
  }

  const onAddNewKeyWord = () => {
    if (!keyword.trim()) {
      keywordRef?.current?.focus()
      setError((prevstate) => ({ ...prevstate, keywords: '必須項目です。' }))
    } else {
      setKeywords((prevState) => [keyword, ...prevState])
      setKeyword('')
      setError((prevstate) => ({ ...prevstate, keywords: '' }))
    }
  }

  const onSaveBenchmark = () => {
    setError(null)
    let newData = {
      title: title.trim(),
      sites: sites,
      keywords: keywords,
      from_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      to_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      pattern: Number(pattern),
    }

    if (benchmarkId) {
      dispatch(editBenchmark({ ...newData, benchmark_id: benchmarkId }))
    } else {
      dispatch(createBenchmark(newData))
    }
  }

  const onChangeTitle = (e) => {
    setTitle(e.target.value)
  }

  useEffect(() => {
    const controller = new AbortController()

    if (benchmarkCopy || benchmarkId) {
      const signal = controller.signal
      const searchId = benchmarkCopy || benchmarkId
      dispatch(getBenchmark({ benchmarkId: searchId, signal: signal }))
    }

    return () => {
      controller.abort()
      dispatch(clearBenchmarkCopy())
    }
  }, [])

  useEffect(() => {
    if (isSuccessfully && benchmark?.benchmark_id) {
      dispatch(clearState())
      if (pathname.includes('shared')) {
        navigate(`/shared/ben/${benchmark.benchmark_id}`, { state: { from: 'add-new' } })
      } else {
        navigate(`/benchmark/${benchmark.benchmark_id}`, { state: { from: 'add-new' } })
      }
    }
  }, [isSuccessfully, benchmark])

  useEffect(() => {
    if (isError) {
      if (errors.message) toast.error(errors.message)
      else setError(errors)
      dispatch(clearState())
    }
  }, [errors, isError])

  useEffect(() => {
    if (benchmarkToCopy) {
      setTitle(benchmarkToCopy?.title)
      setSites(benchmarkToCopy?.sites)
      setKeywords(benchmarkToCopy?.keywords)
      setDateRange([new Date(benchmarkToCopy.from_date), new Date(benchmarkToCopy.to_date)])
      setPattern(Number(benchmarkToCopy?.pattern))
    }
  }, [benchmarkToCopy])

  return (
    <FormLayout
      title='ベンチマーク調査設定'
      onClick={onSaveBenchmark}
      disableButton={isProcessing}
      isProcessing={isProcessing}
      saveLable='調査開始'
      width='85%'
    >
      <Box className={styles.form}>
        {!gettingCopy ? (
          <>
            <Box className={`${styles.boxContainer}`}>
              <Label label={'調査パターン'} required={true} />
              <RadioGroupButton
                value={pattern}
                options={optionsBenchmark}
                className={[styles.radios]}
                name='pattern'
                disabled={benchmarkId ? true : false}
                onChange={(e) => {
                  setPattern(e.target.value)
                }}
              />
            </Box>
            <InputWithLabel
              label='調査名'
              required
              ref={titleRef}
              placeholder='調査名'
              value={title}
              onChange={onChangeTitle}
              error={error && !!error['title']}
              errorText={(error && error['title']) || ''}
              disabled={isProcessing}
            />
            <InputWithLabel
              label='調査対象サイト'
              required
              ref={siteRef}
              placeholder='調査対象サイト'
              value={site}
              onChange={(e) => setSite(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) onAddNewSite()
              }}
              error={error && !!error['sites']}
              errorText={(error && error['sites']) || ''}
              disabled={isProcessing}
              endAdornment={
                <InputAdornment position='end' onClick={onAddNewSite} className={styles.addMore}>
                  <ReactSVG src={plusLargeIcon} className={styles.iconAddMore} />
                </InputAdornment>
              }
            >
              <Box className={styles.siteList}>
                {sites.map((val, index) => {
                  return (
                    <Box key={`site-${val}-${index}`} className={styles.item}>
                      <p className='mb-0'>{val}</p>
                      <CloseIcon
                        className={`${styles.icon} ${isProcessing && 'd-none'}`}
                        onClick={() => {
                          setSites((prevState) => prevState.filter((_, i) => i !== index))
                        }}
                      />
                    </Box>
                  )
                })}
              </Box>
            </InputWithLabel>
            <InputWithLabel
              label={Number(pattern) === BENCH_MARK_BUSINESS ? '企業名' : 'キーワード'}
              required
              ref={keywordRef}
              placeholder={Number(pattern) === BENCH_MARK_BUSINESS ? '企業名' : 'キーワード'}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) onAddNewKeyWord()
              }}
              error={error && !!error['keywords']}
              errorText={(error && error['keywords']) || ''}
              disabled={isProcessing}
              endAdornment={
                <InputAdornment position='end' onClick={onAddNewKeyWord} className={styles.addMore}>
                  <ReactSVG src={plusLargeIcon} className={styles.iconAddMore} />
                </InputAdornment>
              }
            >
              <Box className={styles.keywordList}>
                {keywords.map((val, index) => {
                  return (
                    <Box key={`word-${val}-${index}`} className={styles.item}>
                      <p className='mb-0'>{val}</p>
                      <CloseIcon
                        className={`${styles.icon} ${isProcessing && 'd-none'}`}
                        onClick={() => {
                          setKeywords((prevState) => prevState.filter((_, i) => i !== index))
                        }}
                      />
                    </Box>
                  )
                })}
              </Box>
            </InputWithLabel>
            <Box className={`${styles.rowForm} ${styles.inputGroup}`}>
              <Label label='期間' required />
              <Stack className={styles.inpContainer} style={{ flex: 5 }}>
                <DatePickerWithMonthYearDropdown
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update)
                  }}
                  maxDate={new Date()}
                  isClearable={true}
                  placeholderText='期間'
                  customInput={
                    <CustomInput
                      ref={dateRangeRef}
                      error={error && (!!error['from_date'] || !!error['to_date'])}
                      errorText={(error && (error['from_date'] || error['to_date'])) || ''}
                    />
                  }
                  disabled={isProcessing}
                />
              </Stack>
            </Box>
          </>
        ) : (
          <>
            {Array(4)
              .fill(null)
              .map((_, i) => {
                return <Skeleton key={`ske-${i}`} variant='rounded' height={65} />
              })}
          </>
        )}
      </Box>
    </FormLayout>
  )
}

export default AddEdit
