import axiosClient from './axiosClient'

const shareApi = {
  shareTopic: async (data) => {
    const url = '/chat/share'
    return axiosClient.post(url, data)
  },
  getMembersToShare: async (data) => {
    const url = '/user/to-share-topic'
    return axiosClient.get(url, { params: data })
  },
  getMembersShared: async (data) => {
    const url = '/user/shared-topic'
    return axiosClient.get(url, { params: data })
  },
  getSharedTopic: async () => {
    const url = '/chat/topic/list_share_topics'
    return axiosClient.get(url)
  },
  markReadAll: () => {
    const url = '/chat/topic/mark-read-all'
    return axiosClient.put(url)
  },
  getUnreadTopic: () => {
    const url = '/chat/topic/count-unread'
    return axiosClient.get(url)
  },
}

export default shareApi
