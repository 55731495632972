import React from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import Button from '@mui/material/Button'
import { searchAlt2Icon, arrowSmRightIcon } from '@/utils/images'
import styles from './searchBar.module.css'
import TextField from '@mui/material/TextField'
import { ReactSVG } from 'react-svg'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'

function index({
  containerClasses = [],
  buttonClasses = [],
  inputContainerClasse = [],
  onClickButton = () => {},
  isLoading = false,
  ...props
}) {
  const conClasses = containerClasses.join(' ')
  const btnClass = buttonClasses.join(' ')
  const inpContainerClasses = inputContainerClasse.join(' ')
  return (
    <div className={`${styles.searchContainer} ${conClasses}`}>
      <TextField
        className={inpContainerClasses}
        disabled={isLoading}
        sx={{
          height: '100px',
          '& .MuiOutlinedInput-root': {
            height: '100%',
            borderRadius: '25px',
            padding: '1rem 2rem 1rem 1.5rem',
            backgroundColor: '#fff',
            '& .MuiOutlinedInput-notchedOutline': {
              top: 0,
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position='start'
              sx={(theme) => ({
                width: '24px',
                height: '24px',
                [theme.breakpoints.not('xs')]: {
                  marginLeft: '2.5rem',
                  marginRight: '1.875rem',
                },
              })}
            >
              <img src={searchAlt2Icon} />
            </InputAdornment>
          ),
          endAdornment: (
            <Button
              onClick={onClickButton}
              disabled={isLoading}
              className={`${styles.btnSearch} ${btnClass} background-theme background-theme-hover`}
            >
              {isLoading ? (
                <CircularProgress
                  variant='indeterminate'
                  disableShrink
                  sx={{
                    color: '#fff',
                    animationDuration: '550ms',
                    position: 'absolute',
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: 'round',
                    },
                  }}
                  size={30}
                  thickness={4}
                />
              ) : (
                <ReactSVG src={arrowSmRightIcon} />
              )}
            </Button>
          ),
        }}
        {...props}
      />
    </div>
  )
}

export default React.memo(index)
