import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import MemberTable from './MemberTable'
import InviteMember from './InviteMember'
import styles from './members.module.css'
import { useSelector, useDispatch } from 'react-redux'
import { membersSelector, clearState } from '@/redux/modules/members'
import toast from 'react-hot-toast'
import { transformErrors } from '@/utils/errorHandling'
import { useNavigate } from 'react-router-dom'

function index() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isError, errors, isSuccessfully, successMessage } = useSelector(membersSelector)

  useEffect(() => {
    if (isSuccessfully) {
      if (successMessage)
        toast.success(successMessage, {
          className: 'custom-toast',
        })
      dispatch(clearState())
    }
    if (isError) {
      if (errors?.status === 403) {
        navigate('/top')
      } else {
        const errs = transformErrors(errors)
        errs.forEach((er) => toast.error(er.message))
        dispatch(clearState())
      }
    }
  }, [isSuccessfully, isError])

  return (
    <Box className={styles.formContainer}>
      <h3 className={`${styles.formTitle} color-theme`}>ユーザー管理設定</h3>
      <MemberTable />
      <InviteMember />
    </Box>
  )
}

export default index
