import React from 'react'
import Box from '@mui/material/Box'
import { bgLogin } from '@/utils/images'
import styles from './styles.module.css'

function RetrieveLayout({ children }) {
  return (
    <Box className={`${styles.loginContainer} background-image-theme-1`}>
      <img src={bgLogin} style={{ visibility: 'hidden', marginLeft: '-113px' }} />
      {children}
    </Box>
  )
}

export default RetrieveLayout
