import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import { checkedBoxWhite } from '@/utils/images'

function ContactUs() {
  return (
    <Box className={`container`}>
      <Box className={`scroll-contact`}>
        <Box className={`form--contact`}>
          <Box className={`form--group`}>
            <label>
              <span>＊</span>氏名
            </label>
            <Box className={`flex--block`}>
              <Box className={`cel-6`}>
                <span>姓</span>
                <input
                  type='text'
                  className={`form-control mw-180 placeholder-color`}
                  placeholder='山田'
                />
              </Box>
              <Box className={`cel-6 centent--end`}>
                <span>名</span>
                <input
                  type='text'
                  className={`form-control mw-180 placeholder-color`}
                  placeholder='太郎'
                />
              </Box>
            </Box>
          </Box>
          <Box className={`form--group`}>
            <label>
              <span>＊</span>氏名 (フリガナ)
            </label>
            <Box className={`flex--block`}>
              <Box className={`cel-6`}>
                <span>姓</span>
                <input
                  type='text'
                  className={`form-control mw-180 placeholder-color`}
                  placeholder='ヤマダ'
                />
              </Box>
              <Box className={`cel-6 centent--end`}>
                <span>名</span>
                <input
                  type='text'
                  className={`form-control mw-180 placeholder-color`}
                  placeholder='タロウ'
                />
              </Box>
            </Box>
          </Box>
          <Box className={`form--group`}>
            <label>
              <span>＊</span>メールアドレス
            </label>
            <input
              type='text'
              className={`form-control placeholder-color`}
              placeholder='メールアドレスを入力'
            />
          </Box>
          <Box className='form--group'>
            <label>
              <span>＊</span>会社名
            </label>
            <input
              type='text'
              className={`form-control placeholder-color`}
              placeholder='会社名を入力'
            />
          </Box>
          <Box className='form--group'>
            <label>
              <span></span>部署
            </label>
            <input
              type='text'
              className={`form-control placeholder-color`}
              placeholder='部署名を入力'
            />
          </Box>
          <Box className={`form--group`}>
            <label>
              <span>＊</span>お問い合わせの種別
            </label>
            <select name='cars' className={`form-control`}>
              <option value=''>サービス内容、利用方法について</option>
              <option value=''>ご契約・ご請求内容の確認</option>
              <option value=''>ご契約アカウント数の変更</option>
              <option value=''>管理者アカウントの再発行</option>
              <option value=''>サブスクリプションの一時停止・再開</option>
              <option value=''>解約</option>
              <option value=''>その他</option>
            </select>
          </Box>
          <Box className={`form--group`}>
            <label>
              <span></span>電話番号
            </label>
            <input
              type='text'
              className={`form-control placeholder-color`}
              placeholder='電話番号を入力'
            />
          </Box>
          <Box className={`form--group`}>
            <label>
              <span>＊</span>お問い合わせ内容
            </label>
            <textarea
              className={`form-control placeholder-color`}
              id=''
              rows='5'
              placeholder='自由記述...'
            ></textarea>
          </Box>
          <Box className={`bottom-form`}>
            <hr />
            <Box className={`boxAccept`}>
              <Box className={`MuiCheckbox`}>
                <input type='checkbox' className={`form-control checkbox-id`} name='' id='' />
                <img src={checkedBoxWhite} alt='' />
                <span className={`MuiTouchRipple`}></span>
              </Box>

              <Box className={`text-bt`}>
                <Link to='/'>プライバシーポリシー、</Link>
                <Link to='/'>利用規約</Link> を読み、同意します。
              </Box>
            </Box>
            <Link to='/' className={`bottom--form-ct`}>
              送信
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ContactUs
