import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Input from '@mui/material/Input'
import { fileSearchIcon, inboxAltIcon } from '@/utils/images'
import styles from './styles.module.css'
import InputAdornment from '@mui/material/InputAdornment'
import SharedDataTable from './SharedDataTable'
import { NOT_FOUND } from '@/utils/message'
import { Loading } from '@/components'
import { clearState, shareSelector } from '@/redux/modules/share'
import { useSelector, useDispatch } from 'react-redux'
import { ReactSVG } from 'react-svg'
import { pushError } from '@/utils/function'

const TopicList = ({ data = [], ...props }) => {
  const [filterText, setFilterText] = useState('')
  const filteredItems = data
    .filter((item) => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()))
    .reverse()

  if (!data.length) return null
  return (
    <>
      <div className={styles.historyHeader}>
        <ReactSVG className={styles.icon} src={inboxAltIcon} />
        <label className='color-theme'>{props.title}</label>
        <Box className={styles.boxSearch}>
          <Input
            placeholder='履歴内の検索'
            onChange={(e) => setFilterText(e.target.value)}
            startAdornment={
              <InputAdornment position='start'>
                <ReactSVG src={fileSearchIcon} />
              </InputAdornment>
            }
            sx={{
              '&.MuiInputBase-root': {
                '&.MuiInput-root:before': { borderBottom: '1px solid #414141' },
              },
            }}
          />
        </Box>
      </div>
      <div className={styles.group}>
        {filteredItems.length ? (
          <SharedDataTable data={filteredItems} {...props} />
        ) : (
          <p className={styles.notFoundText}>{NOT_FOUND}</p>
        )}
      </div>
    </>
  )
}

function ListShared({ data, loading, ...props }) {
  const dispatch = useDispatch()
  const { isError, errors } = useSelector(shareSelector)

  useEffect(() => {
    if (isError) {
      pushError(errors)
      dispatch(clearState())
    }
  }, [isError])

  if (loading) return <Loading />
  return (
    <div className={styles.historiesContainer}>
      <TopicList data={data} {...props} />
    </div>
  )
}

export default ListShared
