import axiosClient from './axiosClient'
import { TIMEOUT } from '@/utils/constant'

const chatApi = {
  chatQuestion: async (data) => {
    const url = '/chat/question'
    return axiosClient.post(url, data, { timeout: TIMEOUT })
  },
  chatAnswer: async (data) => {
    const url = '/chat/answer'
    return axiosClient.post(url, data, { timeout: TIMEOUT })
  },
  getAllTopic: (data) => {
    const url = '/chat/topic/all'
    return axiosClient.get(url, { signal: data.signal })
  },
  getTopic: (data) => {
    const url = `/chat/topic/${data.topicId}`
    return axiosClient.get(url, { signal: data.signal })
  },
  deleteTopic: (data) => {
    const url = `/chat/topic/${data.topicId}`
    return axiosClient.delete(url)
  },
}

export default chatApi
