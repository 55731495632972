import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

const Countdown = () => {
  const [counter, setCounter] = useState(10)

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
  }, [counter])

  if (counter <= 0) return <Navigate to='/login' />
  return <span style={{ fontSize: '1.5rem' }}>{counter}s</span>
}

export default Countdown
