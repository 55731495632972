import React, { useState, useEffect, useRef, useCallback } from 'react'
import { FormLayout, InputWithLabel } from '@/components'
import Box from '@mui/material/Box'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { transformErrors } from '@/utils/errorHandling'
import authApi from '@/api/auth'
import { Countdown } from '@/components'
import styles from './styles.module.css'
import toast from 'react-hot-toast'
import { scrollTop } from '@/utils/helper'

function index() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmed, setNewPasswordConfirmed] = useState('')
  const [email, setEmail] = useState('')
  const [resetPassCode, setResetPassCode] = useState('')
  const [error, setError] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [alert, setAlert] = useState('')

  const newPasswordRef = useRef(null)
  const newPasswordConfirmedRef = useRef(null)

  const elementsRef = useRef({
    newPasswordRef,
    newPasswordConfirmedRef,
  })

  const keyPress = (e) => {
    if (e.keyCode == 13) onResetPassword()
  }

  const validateEmpty = () => {
    let isHaveEmpty = false
    if (!newPassword) {
      elementsRef.current.newPasswordRef.current.focus()
      setError((prevstate) => ({ ...prevstate, newPassword: '新しいパスワードは必須項目です。' }))
      isHaveEmpty = true
    }
    if (!newPasswordConfirmed) {
      elementsRef.current.newPasswordConfirmedRef.current.focus()
      setError((prevstate) => ({
        ...prevstate,
        newPasswordConfirmed: '新しいパスワード（確認用）は必須項目です。',
      }))
      isHaveEmpty = true
    }
    return isHaveEmpty
  }

  const onResetPassword = async () => {
    setIsProcessing(true)
    setError(null)
    if (validateEmpty()) {
      setIsProcessing(false)
      return
    }
    authApi
      .resetPassword({
        email: email,
        newPassword: newPassword,
        newPasswordConfirmed: newPasswordConfirmed,
        resetPassCode: resetPassCode,
      })
      .then((res) => {
        setAlert(res.message)
        setIsProcessing(false)
      })
      .catch((e) => {
        const errs = transformErrors(e.errors)
        errs.forEach((er) => toast.error(er.message))
        setIsProcessing(false)
      })
  }

  const getEmailByToken = useCallback(async () => {
    const token = searchParams.get('token')
    if (token) {
      const { email, resetPassCode } = await authApi.checkPassToken({ token: token })
      setEmail(email)
      setResetPassCode(resetPassCode)
    } else {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    getEmailByToken().catch((e) => {
      setAlert(e.errors.message)
    })
  }, [getEmailByToken])

  useEffect(() => {
    scrollTop()
  }, [])

  if (alert)
    return (
      <Box sx={{ padding: '150px 0px', textAlign: 'center', color: '#535353' }}>
        <h3>{alert}</h3>
        {email && <Countdown />}
      </Box>
    )
  return (
    <FormLayout
      title='パスワード変更'
      onClick={onResetPassword}
      disableButton={isProcessing}
      isProcessing={isProcessing}
      height='auto'
      paddingTop='100px'
    >
      <Box className={styles.form}>
        <Box className={styles.row}>
          <InputWithLabel
            type='password'
            inputRef={elementsRef.current.newPasswordRef}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            label='新しいパスワード'
            placeholder='パスワードを入力'
            error={error && !!error['newPassword']}
            errorText={(error && error['newPassword']) || ''}
            strengBar
            onKeyDown={keyPress}
            disabled={isProcessing}
          />
        </Box>
        <Box className={styles.row}>
          <InputWithLabel
            type='password'
            inputRef={elementsRef.current.newPasswordConfirmedRef}
            value={newPasswordConfirmed}
            onChange={(e) => setNewPasswordConfirmed(e.target.value)}
            label='新しいパスワード (確認用）'
            placeholder='パスワードを入力'
            error={error && !!error['newPasswordConfirmed']}
            errorText={(error && error['newPasswordConfirmed']) || ''}
            onKeyDown={keyPress}
            disabled={isProcessing}
          />
        </Box>
      </Box>
    </FormLayout>
  )
}

export default index
