import React, { useEffect, useState, useRef } from 'react'
import { Navigate, Link, useNavigate, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import { useSelector, useDispatch } from 'react-redux'
import { bgLogin, emailIcon, keyIcon } from '@/utils/images'
import toast from 'react-hot-toast'
import FormHelperText from '@mui/material/FormHelperText'
import { getUserToken, getUser } from '@/utils/localData'
import { loginUser, userSelector, clearState } from '@/redux/modules/user'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'
import { INVITED } from '@/utils/constant'
import styles from './signin.module.css'
import { scrollTop } from '@/utils/helper'

function index() {
  const userToken = getUserToken()
  const user = getUser()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [error, setError] = useState(null)

  const emailRef = useRef(null)
  const passwordRef = useRef(null)

  const { isSigning, isSuccessfully, isError, errors } = useSelector(userSelector)

  const validateEmpty = () => {
    let isHaveEmpty = false
    if (!email) {
      emailRef.current.focus()
      setError((prevstate) => ({ ...prevstate, email: 'メールアドレスは必須項目です。' }))
      isHaveEmpty = true
    }
    if (!password) {
      passwordRef.current.focus()
      setError((prevstate) => ({ ...prevstate, password: 'パスワードは必須項目です。' }))
      isHaveEmpty = true
    }
    return isHaveEmpty
  }

  const onHandleLogin = () => {
    setError(null)
    if (validateEmpty()) return
    dispatch(loginUser({ email: email, password: password }))
  }

  const keyPress = (e) => {
    if (e.keyCode == 13) onHandleLogin()
  }

  useEffect(() => {
    scrollTop()
    return () => {
      dispatch(clearState())
    }
  }, [])

  useEffect(() => {
    if (isError) {
      if (errors.message) toast.error(errors.message)
      else setError(errors)
      dispatch(clearState())
    }

    if (isSuccessfully) {
      dispatch(clearState())
      const from = location.state?.from || '/'
      navigate(from)
    }
  }, [isError, isSuccessfully])

  if (userToken && user.status != INVITED) return <Navigate to='/' />
  return (
    <Box component={'form'} className={`${styles.loginContainer} background-image-theme`}>
      <Box className={styles.bgFormLogin}>
        <img className={styles.imgRespon} src={bgLogin} style={{ visibility: 'hidden' }} />
      </Box>
      <Box className={styles.formLogin}>
        <h3 className={`${styles.title} color-theme`}>Sign In</h3>
        <Box className={styles.inputGroup}>
          <label className={`${styles.label}`}>メールアドレス</label>
          <Input
            inputRef={emailRef}
            type='email'
            placeholder='メールアドレス'
            startAdornment={
              <InputAdornment position='start' sx={{ marginLeft: '15px' }}>
                <img src={emailIcon} />
              </InputAdornment>
            }
            inputProps={{ className: `${styles.input}` }}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={keyPress}
            error={error && !!error['email']}
          />
          {error && (
            <FormHelperText error sx={{ height: '20px' }}>
              {error.email || ''}
            </FormHelperText>
          )}
        </Box>
        <Box className={styles.inputGroup}>
          <label className={`${styles.label}`}>パスワード</label>
          <Input
            inputRef={passwordRef}
            type='password'
            placeholder='パスワード'
            startAdornment={
              <InputAdornment position='start' sx={{ marginLeft: '15px' }}>
                <img src={keyIcon} />
              </InputAdornment>
            }
            inputProps={{ className: `${styles.input}` }}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={keyPress}
            error={error && !!error['password']}
          />
          {error && (
            <FormHelperText error sx={{ height: '20px' }}>
              {error.password || ''}
            </FormHelperText>
          )}
        </Box>

        <Button
          className={`${styles.btnSubmit} background-theme background-theme-hover`}
          variant='contained'
          onClick={onHandleLogin}
          disabled={isSigning}
        >
          {isSigning ? (
            <CircularProgress
              variant='indeterminate'
              disableShrink
              sx={{
                color: '#fff',
                animationDuration: '550ms',
                position: 'absolute',
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
              }}
              size={30}
              thickness={4}
            />
          ) : (
            'ログイン'
          )}
        </Button>
        <Link to='/auth/forgot-password' className={`${styles.forgotPassword} color-theme`}>
          ログインできない場合
        </Link>
      </Box>
    </Box>
  )
}

export default index
