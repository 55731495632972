import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { finchLogo } from '@/utils/images'
import { Navbar, Footer } from '@/components'
import { scrollTop } from '@/utils/helper'

import './styles.css'

function index() {
  useEffect(() => {
    scrollTop()
  }, [])

  return (
    <Box className={`body-wrapper`}>
      <Navbar />
      <Box className={`wrapper--policy`}>
        <Box className={`bg--banner`}>
          <h1 className={`title_h1--banner`}>PRIVACY POLICY</h1>
        </Box>
        <Box className={`main--policy`}>
          <Box className={`container container__1230`}>
            <Box className={`content--txt`}>
              株式会社フィンチジャパン（以下、「当社」といいます。）は、本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）におけるプライバシー情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
              <br />
              <br />
              <h4 className={`title__h4`}>第1条（プライバシー情報）</h4>
              <Box component={'ul'} className={`list__stt`}>
                <Box component={'li'}>
                  プライバシー情報のうち「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報を指します。
                </Box>

                <Box component={'li'}>
                  プライバシー情報のうち「履歴情報および特性情報」とは、上記に定める「個人情報」以外のものをいい、ご利用いただいたサービスやご購入いただいた商品、ご覧になったページや広告の履歴、ユーザーが検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、郵便番号や性別、職業、年齢、ユーザーのIPアドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。
                </Box>
              </Box>
              <br />
              <br />
              <h4 className={`title__h4`}>第2条（プライバシー情報の収集方法）</h4>
              <Box component={'ul'} className={`list__stt`}>
                <Box component={'li'}>
                  当社は、ユーザーが利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレスなどの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や、決済に関する情報を当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などから収集することがあります。
                </Box>

                <Box component={'li'}>
                  当社は、ユーザーについて、利用したサービスやソフトウエア、購入した商品、閲覧したページや広告の履歴、検索した検索キーワード、利用日時、利用方法、利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態、利用に際しての各種設定情報なども含みます）、IPアドレス、クッキー情報、位置情報、端末の個体識別情報などの履歴情報および特性情報を、ユーザーが当社や提携先のサービスを利用しまたはページを閲覧する際に収集します。
                </Box>
              </Box>
              <br />
              <br />
              <h4 className={`title__h4`}>第3条（個人情報を収集・利用する目的）</h4>
              当社が個人情報を収集・利用する目的は、以下のとおりです。
              <br />
              （1）ユーザーに自分の登録情報の閲覧や修正、利用状況の閲覧を行っていただくために、氏名、住所、連絡先、支払方法などの登録情報、利用されたサービス、およびそれらの代金などに関する情報を表示する目的 
              <br />
              （2）ユーザーにお知らせや連絡をするためにメールアドレスを利用する場合や必要に応じて連絡したりするため、氏名や住所などの連絡先情報を利用する目的 
              <br />
              （3）ユーザーのユーザー確認を行うために、氏名、生年月日、住所、電話番号などの情報を利用する目的 
              <br />
              （4）ユーザーが簡便にデータを入力できるようにするために、当社に登録されている情報を入力画面に表示させたり、ユーザーのご指示に基づいて他のサービスなど（提携先が提供するものも含みます）に転送したりする目的 
              <br />
              （6）代金の支払を遅滞したり第三者に損害を発生させたりするなど、本サービスの利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの利用をお断りするために、利用態様、氏名や住所など個人を特定するための情報を利用する目的 
              <br />
              （7）ユーザーからのお問い合わせに対応するために、お問い合わせ内容や代金の請求に関する情報、サービスのご案内、セミナー・イベントのご案内、アンケートの実施など当社がユーザーに対してサービスを提供するにあたって必要となる情報や、ユーザーのサービス利用状況、連絡先情報などを利用する目的
              <br />
              <br />
              （8）上記の利用目的に付随する目的
              <br />
              第４条（個人情報の第三者提供）
              <br />
              <Box component={'ul'} className={`list__number`}>
                <Box component={'li'}>
                  <span>1.</span>
                  当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
                </Box>
              </Box>
              （1）法令に基づく場合 
              <br />
              （2）人の生命、身体または財産の保護のために必要がある場合であって、ユーザーの同意を得ることが困難であるとき 
              <br />
              （3）公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ユーザーの同意を得ることが困難であるとき 
              <br />
              （4）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき 
              <br />
              （5）予め次の事項を告知あるいは公表をしている場合
              <br />
                               i.         利用目的に第三者への提供を含むこと
              <br />
                              ii.         第三者に提供されるデータの項目
              <br />
                             iii.         第三者への提供の手段または方法
              <br />
                             iv.        
              ユーザーの求めに応じて個人情報の第三者への提供を停止すること
              <br />
              (6)製品・サービスの代金または利用料金に関する請求・決済代行サービスの利用に際して、ユーザーの契約者情報を当該サービスの提供元に提供する場合
              <br />
              <Box component={'ul'} className={`list__number`}>
                <Box component={'li'}>
                  <span>2.</span>
                  前項の定めにかかわらず、次に掲げる場合は第三者には該当しないものとします。
                </Box>
              </Box>
              （1）当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
              <br />
              （2）合併その他の事由による事業の承継に伴って個人情報が提供される場合
              <br />
              （3）個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめユーザーに通知し、またはユーザーが容易に知り得る状態に置いているとき
              <br />
              <br />
              <h4 className={`title__h4`}>第5条（安全管理措置）</h4>
              <Box component={'ul'} className={`list__number`}>
                <Box component={'li'}>
                  <span>1.</span>
                  個人情報の漏洩、滅失または毀損等を防止するために、個人情報を安全に管理し、セキュリティの確保・向上に努めるとともに、これらのリスクに対する合理的な是正措置を講じます。
                </Box>
              </Box>
              <br />
              <br />
              <h4 className={`title__h4`}>第6条（個人情報の開示）</h4>
              <Box component={'ul'} className={`list__number`}>
                <Box component={'li'}>
                  <span>1.</span>
                  当社は、ユーザーから個人情報の開示を求められたときは、ユーザーに対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
                </Box>
              </Box>
              （1）ユーザーまたは第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
              <br />
              （2）当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
              <br />
              （3）その他法令に違反することとなる場合
              <br />
              <Box component={'ul'} className={`list__number`}>
                <Box component={'li'}>
                  <span>2.</span>
                  前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
                </Box>
              </Box>
              <br />
              <br />
              <h4 className={`title__h4`}>第7条（個人情報の訂正および削除）</h4>
              <Box component={'ul'} className={`list__stt`}>
                <Box component={'li'}>
                  ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正または削除を請求することができます。
                </Box>

                <Box component={'li'}>
                  当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正または削除を行い、これをユーザーに通知します。
                </Box>
              </Box>
              <br />
              <br />
              <h4 className={`title__h4`}>第8条（個人情報の利用停止等）</h4>
              当社は、ユーザーから、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨ユーザーに通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。
              <br />
              <br />
              <h4 className={`title__h4`}>第9条（プライバシーポリシーの変更）</h4>
              <Box component={'ul'} className={`list__stt`}>
                <Box component={'li'}>
                  本ポリシーの内容は、ユーザーに通知することなく、変更することができるものとします。
                </Box>

                <Box component={'li'}>
                  当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
                </Box>
              </Box>
              <br />
              <br />
              <h4 className={`title__h4`}>第10条（お問い合わせ窓口）</h4>
              本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。
            </Box>
            <Box className={`policy__contact`}>
              <Box className={`cRight`}>
                <img className={`img-fluid `} src={finchLogo} alt='' />
              </Box>
              <Box className={`cLeft`}>
                <Box className={`cHead`}>個人情報に関するお問い合わせ</Box>
                <Box component={'ul'} className={`cList__txt`}>
                  <Box component={'li'}>住所：東京都渋谷区恵比寿西1-33-6　JPnoie恵比寿西 1F/2F</Box>
                  <Box component={'li'}>社名：株式会社フィンチジャパン</Box>
                  <Box component={'li'}>TEL：03-4590-5964</Box>
                </Box>
              </Box>
              
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default index
