import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import Stack from '@mui/material/Stack'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { range } from '@/utils/helper'
import { FORMAT_DATE } from '@/utils/constant'
import ja from 'date-fns/locale/ja'

import 'react-datepicker/dist/react-datepicker.css'
import '@/styles/datetime-picker.css'

registerLocale('ja', ja)

export const CustomDatePicker = (props) => {
  return (
    <DatePicker customInputRef='ref' dateFormat={FORMAT_DATE} locale='ja' fixedHeight {...props} />
  )
}

export const DatePickerWithMonthYearDropdown = ({
  startDate,
  endDate,
  onChangeDate = () => {},
  ...props
}) => {
  const years = range(1990, new Date().getFullYear())
  const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

  return (
    <CustomDatePicker
      startDate={startDate}
      endDate={endDate}
      onChange={onChangeDate}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <Stack className='custom-header'>
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <ChevronLeftIcon />
          </button>

          <Stack className='custom-header-dropdown'>
            <select
              value={months[new Date(date).getMonth()]}
              onChange={({ target: { value } }) => {
                changeMonth(months.indexOf(value))
              }}
              style={{ marginRight: '15px' }}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}月
                </option>
              ))}
            </select>
            <select
              value={date.getFullYear()}
              onChange={({ target: { value } }) => {
                changeYear(value)
              }}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </Stack>
          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <ChevronRightIcon />
          </button>
        </Stack>
      )}
      {...props}
    />
  )
}
