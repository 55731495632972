import React from 'react'
import { Outlet } from 'react-router-dom'
import { Navbar, Footer } from '@/components'

function LoginLayout() {
  return (
    <>
      <Navbar whiteTheme />
      <Outlet />
      <Footer />
    </>
  )
}

export default LoginLayout
