import React, { useState, useRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import Input from '@mui/material/Input'
import Button from '@mui/material/Button'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'
import { inviteMember, membersSelector } from '@/redux/modules/members'
import { useSelector, useDispatch } from 'react-redux'
import styles from './members.module.css'

function InviteMember() {
  const dispatch = useDispatch()
  const { isProcessing, isSuccessfully } = useSelector(membersSelector)

  const [email, setEmail] = useState('')
  const [error, setError] = useState()
  const emailRef = useRef(null)

  const keyPress = (e) => {
    if (e.keyCode == 13) onHandleInvite()
  }

  const onHandleInvite = () => {
    setError(null)
    if (!email) {
      emailRef.current.focus()
      setError({ email: 'メールアドレスは必須項目です。' })
      return
    }
    dispatch(inviteMember({ email: email }))
  }

  useEffect(() => {
    if (isSuccessfully) setEmail('')
  }, [isSuccessfully])

  return (
    <Box className={styles.inviteMember}>
      <h3 className={`${styles.formTitle} color-theme`}>新しいユーザーにアカウントを付与する</h3>
      <Box className={styles.inputGroup}>
        <label className={`${styles.label}`}>メールアドレス</label>
        <Box className={styles.inpContainer}>
          <Input
            inputRef={emailRef}
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='メールアドレスを入力'
            inputProps={{ className: `${styles.input}` }}
            onKeyDown={keyPress}
            error={error && !!error['email']}
            className='w-100'
            disabled={isProcessing}
          />
          <FormHelperText error sx={{ height: '20px' }}>
            {error && error.email ? error.email : ''}
          </FormHelperText>
        </Box>
        <Button
          onClick={onHandleInvite}
          className={`background-theme background-theme-hover`}
          variant='contained'
          disabled={isProcessing}
          sx={{ fontWeight: 700 }}
        >
          {isProcessing ? (
            <CircularProgress
              variant='indeterminate'
              disableShrink
              sx={{
                color: '#fff',
                animationDuration: '550ms',
                position: 'absolute',
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
              }}
              size={30}
              thickness={4}
            />
          ) : (
            '送信'
          )}
        </Button>
      </Box>
    </Box>
  )
}

export default InviteMember
