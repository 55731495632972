import React from 'react'
import Stack from '@mui/material/Stack'
import Input from '@mui/material/Input'
import FormHelperText from '@mui/material/FormHelperText'
import Box from '@mui/material/Box'
import PasswordStrengthBar from 'react-password-strength-bar'
import useTheme from '@/hooks/useTheme'
import Label from '../Label'
import styles from './styles.module.css'

const CustomInput = React.forwardRef(
  (
    {
      placeholder,
      type = 'text',
      errorText = '',
      addon = '',
      styleContainer,
      strengBar,
      value = '',
      ...props
    },
    ref
  ) => {
    return (
      <Stack className={styles.inpContainer} sx={styleContainer}>
        <Input
          type={type}
          value={value}
          placeholder={placeholder}
          inputProps={{ className: `${styles.input}` }}
          ref={ref}
          {...props}
        />
        <FormHelperText error sx={{ height: '20px' }}>
          {errorText}
        </FormHelperText>
        {props.children}
        {strengBar && (
          <PasswordStrengthBar
            password={value}
            className={styles.strengPasswordBar}
            shortScoreWord='パスワードの強度'
            scoreWords={[
              'パスワードの強度',
              'パスワードの強度',
              'パスワードの強度',
              'パスワードの強度',
              'パスワードの強度',
            ]}
            barColors={[
              '#fff',
              useTheme().color,
              useTheme().color,
              useTheme().color,
              useTheme().color,
            ]}
            scoreWordStyle={{ width: '115px', margin: 0, textAlign: 'center' }}
          />
        )}
      </Stack>
    )
  }
)

const InputWithLabel = React.forwardRef(({ label = '', required = false, ...props }, ref) => {
  return (
    <Box className={`${styles.rowForm} ${styles.inputGroup}`}>
      <Label label={label} required={required} />
      <CustomInput {...props} ref={ref} />
    </Box>
  )
})

export { CustomInput, InputWithLabel }
